import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "help-line" }
const _hoisted_2 = { class: "help-line__operators" }
const _hoisted_3 = { class: "help-line__operator-code" }
const _hoisted_4 = { class: "help-line__right" }
const _hoisted_5 = { class: "help-line__phone" }
const _hoisted_6 = { class: "help-line__text" }

import { computed } from 'vue';
import { InfoSetting } from '@/types/Info';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';

interface Operator {
  code: string;
  icon: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpLine',
  setup(__props) {

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

const settings = computed((): InfoSetting => info.value?.settings);
const phones = computed((): string[] => settings.value?.telephone ?? []);

const operators = computed((): Operator[] => {
  const operators: Operator[] = [];
  phones.value.map(phone => {
    const code = phone.slice(0, 2);
    const operator = { code: `0${ code }`, icon: '' };
    if (['39', '67', '68', '96', '97', '98'].includes(code)) {
      operator.icon = 'kyivstar';
    } else if (['63', '73', '93'].includes(code)) {
      operator.icon = 'life';
    } else {
      operator.icon = 'vodafone';
    }
    operators.push(operator);
  });
  return operators;
});

const getFormattedPhone = (phone: string): string => {
  if (phone?.length > 8) {
    phone = phone.slice(2);
    return [
      phone.slice(0, 3),
      phone.slice(3, 5),
      phone.slice(5)
    ].join(' - ');
  } else {
    return '';
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "help-line__icon" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(operators.value, (operator) => {
        return (_openBlock(), _createElementBlock("div", {
          key: operator.code,
          class: "help-line__operator"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["help-line__operator-icon", operator.icon])
          }, null, 2),
          _createElementVNode("p", _hoisted_3, "(" + _toDisplayString(operator.code) + ")", 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(getFormattedPhone(phones.value[0])), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('helpline')) + _toDisplayString(phones.value?.length ? _ctx.$t('helpline_2') : ''), 1)
    ])
  ]))
}
}

})