import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "result-item" }
const _hoisted_2 = { class: "result-item__name" }
const _hoisted_3 = { class: "result-item__value" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ResultItem',
  props: { itemData: Object },
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.itemData.label) + ":", 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(__props.itemData.value), 1),
    (__props.itemData.handler)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "result-item__edit-btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.itemData.handler()))
        }, _toDisplayString(_ctx.$t('button.edit')), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})