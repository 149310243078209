<template>
  <inner-loader :isLoading="isLoading" />
  <div v-show="!isLoading" :class="$attrs.class">
    <breadcrumbs-component />
    <div class="result-container">
      <result-items :items="resultItems ?? []" />
      <default-button :text="$t('button.add_queue')" @click="addQueue" />
    </div>
  </div>
  <message-popup v-if="result || isConfirmError || errorText" :message="message" :additional="additional"
                 @close="closePopup" />
  <queue-confirm-popup v-if="isConfirmWaiting" @close="isConfirmWaiting = false" @confirm="confirmQueue" />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { EnteredValue, EnteredValues, InputValue } from '@/types/Keyboard';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import BreadcrumbsComponent from '@/components/Text/BreadcrumbsComponent.vue';
import ResultItems from '@/components/Results/ResultItems.vue';
import { SelectedItem } from '@/types/Results';
import { useDateTime } from '@/composable/useDatetime';
import { useRouter } from 'vue-router';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import { useI18n } from 'vue-i18n';
import QueueConfirmPopup from '@/components/Popups/QueueConfirmPopup.vue';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const emits = defineEmits(['set-footer-buttons']);

const router = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { enteredValues, isEditMode, currentStep, path } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, queue, error, fields, selected, addResult } = storeToRefs(terminalStore);
const { getDateMonth, getHourMinute } = useDateTime();

const isLoading = ref(true);
const isConfirmWaiting = ref(false);
const isConfirmError = ref(false);
const result = ref('');
const errorText = ref('');

const message = computed((): string => {
  return result.value === 'success' ? i18n.t('message.success') : i18n.t('message.fail');
});

const additional = computed((): string => {
  if (errorText.value) {
    return errorText.value;
  }
  if (isConfirmError.value) {
    return i18n.t('errors.time_exceeded');
  } else if (result.value === 'success' && addResult.value.ticket.num) {
    return i18n.t('result_ticket_num', { num: addResult.value.ticket.num });
  } else if (error.value && result.value === 'fail') {
    return `${ i18n.t('error') }: ${ i18n.t(error.value) }`;
  }
  return '';
});

const resultItems = computed(() => {
  const items: { label: string; key: string; value: string | number; handler?: () => void | null }[] = [];
  const values: EnteredValue[] = Object.values(enteredValues.value);
  const keys = Object.keys(enteredValues.value);
  for (let i = 0; i < values.length; i++) {
    if (values[i]?.value && fields?.value?.find(item => item.dataFieldId === +keys[i])) {
      items.push({
        label: values[i].label,
        key: keys[i]?.toString() ?? '',
        value: values[i].value.map(item => item.value).join(''),
        handler: () => {
          isEditMode.value = true;
          currentStep.value = i;
          appStore.setLastPathItem('queue-form');
          router.push('queue-form');
        }
      });
    }
  }

  for (const [key, value] of Object.entries((queue.value?.timePeriod ? queue.value : selected.value) ?? {})) {
    let valueStr = value;

    if (['availableCurrent'].includes(key)) {
      continue;
    } else if (key === 'region' && items.find(item => item.key === 'regionId')) {
      continue;
    } else if (key === 'organization' && items.find(item => item.key === 'organizationId')) {
      continue;
    } else if (key === 'service' && items.find(item => item.key === 'serviceId')) {
      continue;
    }


    if (key === 'datePeriod') {
      valueStr = getDateMonth(value as string);
    } else if (key === 'timePeriod') {
      valueStr = getHourMinute(value as string);
    } else if (['regionId', 'organizationId', 'serviceId', 'servicePackageId'].includes(key)) {
      const selectedValue = (selected.value?.[key.slice(0, -2)] as SelectedItem)?.name;
      if (selectedValue) {
        valueStr = selectedValue;
      }
    } else if (key === 'current') {
      valueStr = i18n.t('queue.current');
    }

    let handler;
    if (key === 'datePeriod' || key === 'timePeriod') {
      handler = () => {
        appStore.setLastPathItem('queue-period');
        void router.push('queue-period');
      };
    }

    if (key === 'serviceId' || key === 'servicePackageId') {
      handler = () => {
        appStore.setLastPathItem('queue-tree');
        void router.push('queue-tree');
      };
    }

    if (key === 'region' && (valueStr as { name?: string; })?.name) {
      valueStr = (valueStr as { name: string; }).name;
    }

    items.push({ label: i18n.t(`result.${ key }`), value: valueStr as string, key, handler });
  }

  return items;
});

const getValue = (type: string, value: InputValue[]): string => {
  if (type === 'ph') {
    const countryCode = info.value?.settings?.countryCode ?? '';
    const codeSymbols = `+ ${ countryCode } `;
    return countryCode + value.slice(codeSymbols.length).filter(item => /[0-9]/.test(item.value)).map(item => item.value).join('');
  } else {
    return value.map(item => item.value).join('');
  }
};

const addQueue = async () => {
  isLoading.value = true;

  const items: { dataFieldId: number; value: string; }[] = [];
  for (const [key, value] of Object.entries(enteredValues.value)) {
    if (value?.value) {
      items.push({ dataFieldId: +key, value: getValue(value.type, value.value) });
    }
  }

  await terminalStore.addQueue(items);
  if (addResult.value) {
    errorText.value = '';
    const { success, confirm } = addResult.value;
    if (success && !confirm) {
      result.value = addResult.value ? 'success' : 'fail';
    } else if (confirm) {
      isConfirmWaiting.value = true;
    } else {
      result.value = 'fail';
    }
  } else {
    errorText.value = error.value;
  }

  isLoading.value = false;
};

const closePopup = () => {
  if (result.value === 'success' || isConfirmError.value) {
    enteredValues.value = {} as EnteredValues;
    currentStep.value = 0;
    router.push('/');
  } else {
    error.value = '';
  }

  result.value = '';
  errorText.value = '';
};

const onBackClick = async () => {
  const previousPath = path.value[path.value.length - 2];
  appStore.removeLastPathItem();
  await router.push(previousPath);
};

const confirmQueue = (isConfirmed: boolean) => {
  isConfirmWaiting.value = false;

  if (isConfirmed) {
    result.value = 'success';
  } else {
    isConfirmError.value = true;
  }
};

onMounted(async () => {
  emits('set-footer-buttons', [
    { type: 'back', handler: onBackClick },
    { type: 'main' }
  ]);

  appStore.addPathItem('queue-results');
  await terminalStore.getTQueue();

  if (!fields.value?.length && Object.values(enteredValues.value)?.length) {
    await terminalStore.getTQueueForm();
  }

  isLoading.value = false;
});

</script>

<style lang="scss" scoped>
@import "src/assets/styles/utils/sizes";

.main > .result-items {
  margin: auto;
}

.default-button {
  margin-bottom: 0.5rem;
}

.result-container {
  @extend .flex-col;
  height: calc(100% - #{$footer});
  justify-content: space-between;
}
</style>
