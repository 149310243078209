import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StepperPoint',
  props: { isPassed: Boolean },
  setup(__props) {

const props = __props;

const color = computed((): string => props.isPassed ? '#00B2E0' : '#CDCDCD');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("circle", {
      cx: "7",
      cy: "7",
      r: "7",
      fill: color.value
    }, null, 8, _hoisted_2)
  ]))
}
}

})