import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "loader__circles" }


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickLoader',
  props: {
  animationDelay: {
    type: Number,
    default: 0.25,
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
    _createElementVNode("div", {
      class: "loader",
      style: _normalizeStyle(`animation-delay: ${__props.animationDelay}s;`)
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (n) => {
          return _createElementVNode("div", {
            key: n,
            class: "loader__circles-item"
          })
        }), 64))
      ])
    ], 4)
  ]))
}
}

})