import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "info" }
const _hoisted_2 = { class: "info__organization-name" }
const _hoisted_3 = { class: "info__organization-address" }
const _hoisted_4 = {
  key: 1,
  class: "info__service-name"
}
const _hoisted_5 = { class: "info__ticket-text" }
const _hoisted_6 = { class: "info__ticket-num" }
const _hoisted_7 = { class: "info__ticket-time" }

import { onMounted, ref } from 'vue';
import PopupContainer from '@/components/Popups/PopupContainer.vue';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoResultPopup',
  setup(__props) {

const router = useRouter();
const terminalStore = useTerminalStore();
const { addResult } = storeToRefs(terminalStore);

const timeout = ref(0);

const onCloseClick = () => {
  clearTimeout(timeout.value);
  router.push('/');
};

onMounted(() => timeout.value = setTimeout(onCloseClick, 10000));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PopupContainer, {
    class: "message",
    message: _ctx.$t('message.success'),
    onClose: onCloseClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_unref(addResult)?.print?.organization)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(addResult).print.organization.description.name), 1),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(addResult).print.organization.description.address), 1)
            ], 64))
          : _createCommentVNode("", true),
        (_unref(addResult)?.print?.service)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(addResult).print.service.description.name), 1))
          : _createCommentVNode("", true),
        (_unref(addResult)?.print?.ticket)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("ticket_num")) + ":", 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(addResult).print.ticket.num), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(addResult).print.ticket.plannedTime), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _createVNode(DefaultButton, {
        text: _ctx.$t('button.ok'),
        onClick: onCloseClick
      }, null, 8, ["text"])
    ]),
    _: 1
  }, 8, ["message"]))
}
}

})