<template>
  <data-input class="search" :has-hints="false" :has-buttons="false" :keyboard="'ua'" :initial="value"
              :has-toggle-button="false" @set-value="onValueChange" @set-keyboard-state="onKeyboardStateChange" />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import DataInput from '@/components/Input/DataInput.vue';
import { InputValue } from '@/types/Keyboard';

const emits = defineEmits(['set-value', 'set-state']);

const value = ref<InputValue[]>([]);

const onValueChange = (v: InputValue[]) => emits('set-value', v.map(item => item.value).join(''));

const onKeyboardStateChange = (v: boolean) => {
  emits('set-state', v);
  if (!v) {
    value.value = [];
  }
};
</script>

<style lang="scss">
$size: calc(min(5rem, 8vh));

.gotovo .search {
  .input {
    @extend .flex-row;
    align-items: flex-end;
    width: calc(100vw - 7.5rem);
    margin: 0 auto 2vh;

    &-box {
      @extend .flex-col;
      flex: 1;
      position: relative;
    }

    &-box, &__erase, &__keyboard {
      height: $size;
    }

    &__erase {
      top: 2px;
      width: 5rem;
    }

    &__field {
      position: relative;
      height: calc(#{$size} + 4px);
      padding-right: 5rem;
      padding-left: 5rem;
      border: 2px solid #9999997F !important;
      border-radius: 8px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 5rem;
        height: 5rem;
        @include svgBg($search, 2rem);
        filter: invert(0.25);
      }

      &-text__symbol {
        font-size: 2vw;
      }
    }
  }

  .keyboard {
    margin-bottom: 2vh;

    &__key-text {
      font-size: 2vw !important;
      text-transform: uppercase;
    }

    &__key {
      background-size: 2vw !important;

      &--backspace, &--numbers {
        background-size: 2.5vw !important;
      }

      &--language {
        background-size: 5vw !important;

        & > .keyboard__key-text {
          font-size: 1.5vw !important;
        }
      }
    }
  }
}

@media (min-height: 1000px) and (min-width: 1400px) {
  .gotovo .search {
    .input, .keyboard {
      margin-bottom: 2rem;
    }

    .input {
      width: calc(100vw - 39.5rem);
    }
  }
}

</style>
