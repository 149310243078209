import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__rest" }
const _hoisted_2 = { class: "header__rest-title" }
const _hoisted_3 = { class: "header__rest-value" }

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderRest',
  setup(__props) {

const rest = ref(0);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('account')) + ":", 1),
    _createElementVNode("p", _hoisted_3, [
      _createTextVNode(_toDisplayString(rest.value.toFixed(2)) + " ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('uah')), 1)
    ])
  ]))
}
}

})