import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form__top" }
const _hoisted_2 = { class: "form__top-title" }
const _hoisted_3 = { class: "p form__top-value" }
const _hoisted_4 = {
  key: 0,
  class: "form__label"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "footer__button-text" }
const _hoisted_7 = { class: "footer__button-text" }
const _hoisted_8 = { class: "footer__button-text" }

import EquickBreadcrumbs from "@/components/Equick/EquickBreadcrumbs.vue";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import { DataField } from "@/types/Form";
import EquickQueueFormStepper from "@/components/Equick/QueueForm/EquickQueueFormStepper.vue";
import EquickFieldInput from "@/components/Equick/Input/EquickFieldInput.vue";
import { InputValue } from "@/types/Keyboard";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueueFormView',
  setup(__props) {

const { push } = useRouter();
const appStore = useAppStore();
const { isEditMode, enteredValues, currentStep, keyboardType, path, isAgree } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, fields, error } = storeToRefs(terminalStore);

const isLoading = ref(true);

const currentField = computed((): DataField => fields.value[currentStep.value]);
const currentKeyboardType = computed((): string => {
  if (currentField.value?.type === 'ph') {
    return 'numbers';
  } else if (currentField.value?.type === 'e') {
    return 'en';
  } else {
    return keyboardType.value ? keyboardType.value : 'ua';
  }
});
const mask = computed((): string => {
  if (currentField.value?.type === 'ph' && currentField.value?.inputMask) {
    const countryCode = info.value?.settings?.countryCode ?? '380';
    return currentField.value.inputMask.replace('{countryCode}', countryCode);
  } else {
    return currentField.value?.inputMask ?? '';
  }
});

const onBackClick = () => {
  if (currentStep.value === 0) {
    push('/equick/queue-period');
    appStore.removeLastPathItem();
  } else {
    currentStep.value = currentStep.value - 1;
  }
};

const onMainClick = () => {
  currentStep.value = 0;
  path.value = [];
  push('/equick/queue-tree');
};

const onNextClick = () => {
  if (isEditMode.value) {
    currentStep.value = fields.value.length - 1;
    isEditMode.value = false;
    push('/equick/queue-results');
  } else if (currentStep.value + 1 < fields.value.length) {
    currentStep.value = currentStep.value + 1;
  } else {
    push('/equick/queue-results');
  }
};

const setEnteredValue = (newValue: InputValue[]) => {
  appStore.setEnteredValue(currentField.value.dataFieldId, {
    value: newValue,
    label: currentField.value.format,
    type: currentField.value.type
  });
}

const onPopupClose = () => {
  error.value = '';
  push('/');
};

onMounted(async () => {
  await terminalStore.getTQueueForm();
  if (fields.value.length) {
    path.value = ['queue-tree', 'queue-period', 'queue-form'];
  } else {
    await push('/equick/queue-results');
  }

  isLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", null, [
      _createVNode(EquickBreadcrumbs),
      (!isLoading.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('equick.form_title')), 1),
              _createElementVNode("div", _hoisted_3, _toDisplayString(currentField.value.name), 1),
              _createVNode(EquickQueueFormStepper)
            ]),
            (currentField.value.label)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(currentField.value.label), 1))
              : _createCommentVNode("", true),
            (_openBlock(), _createBlock(EquickFieldInput, {
              key: _unref(currentStep),
              field: currentField.value,
              mask: mask.value,
              initial: _unref(enteredValues)[currentField.value.dataFieldId]?.value ?? [],
              "initial-keyboard-type": currentKeyboardType.value,
              onBack: onBackClick,
              onNext: onNextClick,
              "onUpdate:modelValue": setEnteredValue
            }, null, 8, ["field", "mask", "initial", "initial-keyboard-type"]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_5, [
          _createElementVNode("div", {
            class: "footer__button footer__button--back",
            onClick: onBackClick
          }, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('footer.button_back')), 1)
          ]),
          _createElementVNode("div", {
            class: "footer__button footer__button--home",
            onClick: onMainClick
          }, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('footer.button_main')), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["footer__button footer__button--next", {'disabled': currentField.value.hint === 'firstName' && !_unref(isAgree)}]),
            onClick: onNextClick
          }, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('footer.button_continue')), 1)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(EquickLoader, { key: 1 }))
      : _createCommentVNode("", true),
    (_unref(error))
      ? (_openBlock(), _createBlock(EquickMessagePopup, {
          key: 2,
          message: _unref(error),
          onClose: onPopupClose
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})