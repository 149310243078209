<template>
  <div class="queue__live" :class="{'queue__live--submit': isSubmitScreen}">
    <div class="queue__live-block">
      <p v-if="isSubmitScreen" class="queue__live-title">{{ $t("confirmation") }}</p>
      <div class="queue__live-block">
        <p class="queue__live-value">{{ approximateTime }}</p>
        <p class="queue__live-value">{{ selectedData.name }}</p>
      </div>
    </div>

    <div class="queue__live-block">
      <p class="queue__live-message">{{ $t("attention") }} {{ !isSubmitScreen ? $t("queue.live_gotovo_1") : "" }}</p>
      <p class="queue__live-message">{{ $t("queue.live_gotovo_2") }}</p>
      <p class="queue__live-message">{{ $t("queue.live_gotovo_3") }}</p>
    </div>

    <div v-if="!isLiveAvailable" class="queue__live-block queue__live-block--no-near">
      <p class="queue__live-message">{{ $t("queue.no_near_1") }}</p>
      <p class="queue__live-message">{{ $t("queue.no_near_2") }}</p>
    </div>

    <template v-if="!isSubmitScreen">
      <div class="queue__live-container">
        <p class="queue__live-button" :class="{disabled: !isLiveAvailable}" @click="onAddClick">
          {{ $t("button.submit_queue_live") }}
        </p>
        <p class="queue__live-button queue__live-button--fixed" @click="onFixedClick">{{ $t("button.other_time") }}</p>
      </div>

      <p class="queue__live-message">{{ $t("queue.live_gotovo_4") }}</p>
    </template>

    <div v-else class="queue__live-buttons">
      <p @click="isSubmitScreen = false">{{ $t("button.cancel") }}</p>
      <p @click="onSubmitClick">{{ $t("button.submit_time") }}</p>
    </div>
  </div>

  <inner-loader v-if="isLoading" :is-loading="isLoading" />
  <message-popup v-if="isErrorPopup" :message="$t('message.fail')"
                 :additional="$t('message.queue_service_error', {name: selectedData.name})"
                 @close="isErrorPopup = false" />
  <gotovo-result-popup v-if="isSuccessPopup" />
</template>

<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue';
import { NamedAvailableCurrent } from '@/types/AvailableCurrent';
import { useI18n } from 'vue-i18n';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import GotovoResultPopup from '@/components/Popups/GotovoResultPopup.vue';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const props = defineProps({
  selectedData: {
    type: Object as PropType<NamedAvailableCurrent>,
    default: () => ({})
  }
});
const emits = defineEmits(['set-submit-state', 'show-fixed-queue']);

const i18n = useI18n();
const terminalStore = useTerminalStore();
const { addResult, results } = storeToRefs(terminalStore);

const isSubmitScreen = ref(false);
const isErrorPopup = ref(false);
const isSuccessPopup = ref(false);
const isLoading = ref(false);

const isLiveAvailable = computed((): boolean => results.value?.availableCurrent?.quantity !== undefined && results.value.availableCurrent.quantity > 0);

const approximateTime = computed((): string => {
  const toWaitMs = props.selectedData?.approximateWaitingMs;
  if (toWaitMs !== undefined && toWaitMs >= 0) {
    const date = new Date(Date.now() + toWaitMs);
    return [
      date.toLocaleDateString('uk-UA', { day: '2-digit', month: 'long' }),
      date.getFullYear(),
      i18n.t('approximate_at'),
      date.toLocaleTimeString('uk-UA', { hour: 'numeric', minute: 'numeric' })
    ].join(' ');
  }

  return '';
});

const onAddClick = async () => {
  isLoading.value = true;
  await terminalStore.addLiveQueue();
  isSubmitScreen.value = true;
  isLoading.value = false;
};

const onSubmitClick = async () => {
  if (!addResult.value) {
    await terminalStore.addLiveQueue();
  }

  await terminalStore.addQueue({});

  if (addResult.value.success) {
    //await store.dispatch('getTQueueForm');
    isSuccessPopup.value = true;
  } else {
    isErrorPopup.value = true;
  }
};

const onFixedClick = () => emits('show-fixed-queue');

watch(() => isSubmitScreen.value, () => emits('set-submit-state', isSubmitScreen.value));
</script>

<style lang="scss" scoped>
.queue__live {
  @extend .flex-col;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 2.5rem * 2 - 3rem);
  margin: 2.5rem 0;

  &--submit {
    justify-content: flex-start;

    .queue__live-block > .queue__live-block {
      flex-direction: column-reverse;
    }

    .queue__live-message {
      font-size: 1.25rem !important;
      margin-bottom: 0 !important;
    }

    .queue__live-value {
      color: #333 !important;
    }
  }

  &-block {
    @extend .col-container;
    width: 100%;

    &:first-child {
      margin-bottom: 1.5rem;
    }

    &--no-near {
      margin: 3rem 0 1.5rem;
    }
  }

  &-title {
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
  }

  &-value {
    @extend .text-center;
    max-width: 70%;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3rem;

    &:nth-child(2) {
      color: #444;
    }
  }

  &-message {
    font-size: 1.25rem;
    line-height: 1.75rem;

    &:first-child {
      font-size: 1.75rem;
      margin-bottom: 0.5rem;
    }
  }

  &-container {
    @extend .flex-row;
    align-items: flex-end;
  }

  &-button {
    @extend .pointer;
    height: max-content;
    margin: 1rem 0 2rem;
    padding: 3vh 4vw;
    font-size: 4vh;
    background-color: $yellow;
    border-radius: 6px;

    &--fixed {
      margin-left: 2rem;
      background-color: #ccc8;
    }

    &.disabled {
      pointer-events: none;
      //opacity: 0.75;
      //filter: brightness(0.9);
      background-color: #ccc8;

      & + .queue__live-button--fixed {
        background-color: $yellow;
      }
    }
  }

  &-buttons {
    @extend .flex-row;
    margin-top: 2rem;

    & > p {
      @extend .pointer;
      padding: 1rem 2.5rem;
      font-size: 2rem;
      background-color: $yellow;
      border-radius: 4px;

      &:first-child {
        margin-right: 2rem;
      }
    }
  }
}

@media (min-height: 1000px) and (min-width: 1400px) {
  .queue__live-button {
    padding: 2rem 6rem;
    font-size: 2.5rem;
  }
}
</style>
