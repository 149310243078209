import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "keyboard__key-text" }

import { computed, PropType } from "vue";
import { Key } from "@/types/Keyboard";
import { DataField } from "@/types/Form";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'KeyComponent',
  props: {
  itemData: {
    type: Object as PropType<Key>,
    default: () => ({})
  },
  size: {
    type: Number,
    required: true
  },
  keyOffset: {
    type: Number,
    default: 10,
  }
},
  setup(__props) {

const props = __props;

const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);

const currentField = computed((): DataField => fields.value[currentStep.value]);

const itemClass = computed((): string[] => {
  return [
    `keyboard__key--${ props.itemData.size ?? 1 }`,
    props.itemData.action ? `keyboard__key--${ props.itemData.action }` : '',
    `${ isDisabled.value ? 'disabled' : '' }`
  ];
});

const itemStyle = computed(() => {
  const size = props.itemData.size ?? 1;
  const itemSize = size * props.size + (size - 1) * props.keyOffset + 'px';
  return { width: itemSize, height: props.size + 'px', lineHeight: props.size + 'px' };
});

const isDisabled = computed((): boolean => {
  const phoneCondition = currentField.value?.type === 'ph' && props.itemData.action === 'letters';
  const emailCondition = currentField.value?.type === 'e' && props.itemData.action === 'language';
  return phoneCondition || emailCondition;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["keyboard__key", itemClass.value]),
    style: _normalizeStyle(itemStyle.value)
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(__props.itemData.symbol), 1)
  ], 6))
}
}

})