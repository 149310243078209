import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "menu-container" }

import { useRouter } from "vue-router";
import { PropType } from "vue";
import MenuItem from "@/components/Menu/MenuItem.vue";
import { IncludeItem } from "@/types/Info";


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuItems',
  props: {
  items: Array as PropType<IncludeItem[]>,
  hasIcons: {
    type: Boolean,
    default: true,
  }
},
  setup(__props) {



const router = useRouter();

const onClick = (item: IncludeItem) => {
  if (item.type === 'queue') {
    router.push('/queue-tree');
  } else {
    router.push(`/tree`);
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
      return (_openBlock(), _createBlock(MenuItem, {
        key: item.id,
        "item-data": item,
        "has-icon": __props.hasIcons,
        onClick: ($event: any) => (onClick(item))
      }, null, 8, ["item-data", "has-icon", "onClick"]))
    }), 128))
  ]))
}
}

})