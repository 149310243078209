<template>
  <div class="keyboard" :class="`keyboard--${size}`">
    <div v-for="(row, rowKey) in keys" class="keyboard__row" :key="rowKey" :style="{height: `${keySize}px`}">
      <key-component v-for="(item, itemKey) in row" :key="itemKey" :itemData="item" :size="keySize"
                     @click="onClick(item)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { eng } from '@/assets/keys/eng';
import { rus } from '@/assets/keys/rus';
import { ukr } from '@/assets/keys/ukr';
import { num } from '@/assets/keys/num';
import { onlyNum } from '@/assets/keys/only-num';
import KeyComponent from '@/components/Keyboard/KeyComponent.vue';
import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { Key } from '@/types/Keyboard';
import { useMain } from '@/composable/useMain';

const props = defineProps({
  keyboardType: {
    type: String,
    default: ''
  }
});
const emits = defineEmits(['key-click']);

const { isGotovo } = useMain();

const keys = ref<Key[][]>([]);

const keysSet: { [key: string]: Key[][] } = {
  ua: ukr,
  ru: rus,
  en: eng,
  numbers: num,
  onlyNum: onlyNum
};

const KeyActions: { [key: string]: string } = {
  Backspace: 'backspace',
  ArrowLeft: 'backward',
  ArrowRight: 'forward'
};

const size = computed((): string => ['num', 'onlyNum'].includes(props.keyboardType) ? 's' : 'm');

const keySize = computed((): number => {
  const maxRowItemsAmount = 12;
  const maxWidth = document.documentElement.clientWidth - 60 * 2 - 10 * (maxRowItemsAmount - 1);
  const maxItemWidth = Math.floor(maxWidth / maxRowItemsAmount);

  let maxHeight = document.documentElement.clientHeight - 28 * 16 - 24;
  if (isGotovo.value && document.documentElement.clientWidth > 1400 && document.documentElement.clientHeight > 1000) {
    maxHeight -= document.documentElement.clientHeight * 0.2;
  }
  const maxItemHeight = Math.floor(maxHeight / keysSet[props.keyboardType].length);

  return Math.min(maxItemWidth, maxItemHeight);
});

const symbols = computed((): string => {
  let symbols = '';
  keys.value?.map(set => {
    set?.map(item => {
      symbols += item.symbol && !item.action ? item.symbol : '';
    });
  });

  return symbols.toLowerCase();
});

const onClick = (key: Key) => emits('key-click', key);

const findKeyByValue = (set: Key[][], value: string, param = 'symbol' as 'symbol' | 'action'): Key | undefined => {
  for (const item of set) {
    for (const key of item) {
      if (key[param]?.toLowerCase() === value.toLowerCase()) {
        return key;
      }
    }
  }

  return undefined;
};

const onKeyboardBtnClick = (e: Event) => {
  const key = (e as KeyboardEvent).key;
  let item: Key | undefined = undefined;
  if (/\d/.test(key)) {
    item = findKeyByValue(num, key);
  } else if (symbols.value.includes(key)) {
    item = findKeyByValue(keys.value, key);
  } else if (['\'', '"', '`'].includes(key)) {
    item = findKeyByValue(keys.value, '‘');
  } else if (Object.keys(KeyActions).includes(key)) {
    item = findKeyByValue(keys.value, KeyActions[key], 'action');
  }

  item && onClick(item);
};

onMounted(() => document.documentElement.addEventListener('keydown', onKeyboardBtnClick));

onUnmounted(() => document.documentElement.removeEventListener('keydown', onKeyboardBtnClick));

watchEffect(() => keys.value = keysSet[props.keyboardType]);
</script>

<style lang="scss" scoped>
.keyboard {
  @extend .flex-col;
  align-items: center;
  justify-content: center;
  margin: auto;

  &--s {

  }

  &--m {
    width: calc(100vw - 5rem * 2);
    margin: auto 5rem;
  }

  &__row {
    @extend .flex-row;
    height: max-content;
    margin-bottom: 0.625rem;
  }
}
</style>
