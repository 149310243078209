import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import PopupContainer from "@/components/Popups/PopupContainer.vue";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickMessagePopup',
  props: {
  message: String,
  additional: String,
  buttonText: String,
  hasButton: {
    type: Boolean,
    default: true
  },
  teleport: {
    type: String,
    default: '.page',
  },
},
  emits: ['close'],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PopupContainer, {
    class: "message-popup",
    message: __props.message,
    additional: __props.additional,
    teleport: __props.teleport,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      (__props.hasButton)
        ? (_openBlock(), _createBlock(EquickBaseButton, {
            key: 0,
            text: __props.buttonText ?? _ctx.$t('button.ok'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["message", "additional", "teleport"]))
}
}

})