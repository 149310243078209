<template>
  <div class="content__pages">
    <div class="content__button previous" @click="onPreviousClick"></div>
    <div class="flex-row">
      <p v-for="n in currentPages" :key="n" class="content__pages-item"
         :class="{'content__pages-item--selected': pageIndent === n - 1}" @click="onPageClick(n)">
        {{ n }}
      </p>
    </div>
    <div class="content__button next" @click="onNextClick"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";

const props = defineProps({
  pages: {
    type: Number,
    default: 0
  }
});

const emits = defineEmits(['next', 'previous']);

const appStore = useAppStore();
const { pageIndent } = storeToRefs(appStore);

const currentPages = computed((): number[] => {
  const from = props.pages <= 10 ? 1 : Math.min(pageIndent.value + 1, props.pages - 9);
  const to = props.pages <= 10 ? props.pages : Math.min(pageIndent.value + 10, props.pages);
  const pages: number[] = [];
  for (let i = from; i <= to; i++) {
    pages.push(i);
  }
  return pages;
});

const onPreviousClick = () => emits('previous');

const onNextClick = () => emits('next');

const onPageClick = async (number: number) => pageIndent.value = number - 1;
</script>

<style lang="scss" scoped>
.content {
  &__pages {
    @extend .row-container;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    margin: 1rem;

    &-item {
      @extend .pointer;
      width: 3rem;
      margin: 0 1rem;
      line-height: 3rem;
      font-size: 24px;
      text-align: center;
      border: 2px solid transparent;
      border-radius: 50%;
      color: #444;

      &--selected {
        font-weight: 700;
        border-color: #666;
      }
    }
  }

  &__button {
    min-width: 4rem;
    max-width: 4rem;
    height: 100%;
    background-color: transparent !important;
    filter: invert(0.75);

    &.previous {
      margin: 0 1rem 0 2rem;
      padding-left: calc(min(10vw, 4vh));
      padding-right: 3vw;
      @include svgBg($arrow-left, 2.5rem);
    }

    &.next {
      margin: 0 2rem 0 1rem;
      padding-right: calc(min(10vw, 4vh));
      padding-left: 3vw;
      @include svgBg($arrow-right, 2.5rem);
    }
  }
}
</style>
