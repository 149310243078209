<template>
  <main>
    <equick-breadcrumbs />
    <template v-if="!isLoading">
      <div class="form__top">
        <p class="form__top-title">{{ $t('equick.form_title') }}</p>
        <div class="p form__top-value">{{ currentField.name }}</div>
        <equick-queue-form-stepper />
      </div>
      <p v-if="currentField.label" class="form__label">{{ currentField.label }}</p>
      <equick-field-input :key="currentStep" :field="currentField" :mask="mask"
                          :initial="enteredValues[currentField.dataFieldId]?.value ?? []"
                          :initial-keyboard-type="currentKeyboardType" @back="onBackClick" @next="onNextClick"
                          @update:model-value="setEnteredValue" />
    </template>
  </main>

  <footer v-if="!isLoading">
    <div class="footer__button footer__button--back" @click="onBackClick">
      <p class="footer__button-text">{{ $t('footer.button_back') }}</p>
    </div>
    <div class="footer__button footer__button--home" @click="onMainClick">
      <p class="footer__button-text">{{ $t('footer.button_main') }}</p>
    </div>
    <div class="footer__button footer__button--next"
         :class="{'disabled': currentField.hint === 'firstName' && !isAgree}" @click="onNextClick">
      <p class="footer__button-text">{{ $t('footer.button_continue') }}</p>
    </div>
  </footer>

  <equick-loader v-if="isLoading" />
  <equick-message-popup v-if="error" :message="error" @close="onPopupClose" />
</template>

<script setup lang="ts">
import EquickBreadcrumbs from "@/components/Equick/EquickBreadcrumbs.vue";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import { DataField } from "@/types/Form";
import EquickQueueFormStepper from "@/components/Equick/QueueForm/EquickQueueFormStepper.vue";
import EquickFieldInput from "@/components/Equick/Input/EquickFieldInput.vue";
import { InputValue } from "@/types/Keyboard";

const { push } = useRouter();
const appStore = useAppStore();
const { isEditMode, enteredValues, currentStep, keyboardType, path, isAgree } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, fields, error } = storeToRefs(terminalStore);

const isLoading = ref(true);

const currentField = computed((): DataField => fields.value[currentStep.value]);
const currentKeyboardType = computed((): string => {
  if (currentField.value?.type === 'ph') {
    return 'numbers';
  } else if (currentField.value?.type === 'e') {
    return 'en';
  } else {
    return keyboardType.value ? keyboardType.value : 'ua';
  }
});
const mask = computed((): string => {
  if (currentField.value?.type === 'ph' && currentField.value?.inputMask) {
    const countryCode = info.value?.settings?.countryCode ?? '380';
    return currentField.value.inputMask.replace('{countryCode}', countryCode);
  } else {
    return currentField.value?.inputMask ?? '';
  }
});

const onBackClick = () => {
  if (currentStep.value === 0) {
    push('/equick/queue-period');
    appStore.removeLastPathItem();
  } else {
    currentStep.value = currentStep.value - 1;
  }
};

const onMainClick = () => {
  currentStep.value = 0;
  path.value = [];
  push('/equick/queue-tree');
};

const onNextClick = () => {
  if (isEditMode.value) {
    currentStep.value = fields.value.length - 1;
    isEditMode.value = false;
    push('/equick/queue-results');
  } else if (currentStep.value + 1 < fields.value.length) {
    currentStep.value = currentStep.value + 1;
  } else {
    push('/equick/queue-results');
  }
};

const setEnteredValue = (newValue: InputValue[]) => {
  appStore.setEnteredValue(currentField.value.dataFieldId, {
    value: newValue,
    label: currentField.value.format,
    type: currentField.value.type
  });
}

const onPopupClose = () => {
  error.value = '';
  push('/');
};

onMounted(async () => {
  await terminalStore.getTQueueForm();
  if (fields.value.length) {
    path.value = ['queue-tree', 'queue-period', 'queue-form'];
  } else {
    await push('/equick/queue-results');
  }

  isLoading.value = false;
});
</script>

<style scoped lang="scss">
.form {
  &__top {
    @extend .row-container;
    gap: $--8px;
    position: relative;
    margin: $--8px 0;

    &-title {
      @include font(rems(28), rems(34), black, 500);
    }

    &-value {
      margin-top: 3px;
      @include font(rems(24), rems(32), black);
    }
  }

  &__label {
    margin-bottom: $--12px;
    @include font(rems(14), 120%, black);
  }
}

main {
  @extend .flex-col;
  height: 100%;
}

main:has(.main__input--error) {
  & + footer .footer__button--next, & :deep(.main__field-button--next) {
    @extend .disabled;
  }
}

footer {
  margin-top: $--16px;
}
</style>
