<template>
  <div class="permission">
    <div class="permission__icon" :class="{'permission__icon--active': isAgree}" @click="isAgree = !isAgree"></div>
    <p class="permission__text">
      Я даю
      <span @click="isPopupShown = true">Согласие на обработку персональных данных</span>
    </p>
  </div>

  <equick-permission-popup v-if="isPopupShown" @close="isPopupShown = false" />
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import EquickPermissionPopup from "@/components/Equick/Popup/EquickPermissionPopup.vue";

const appStore = useAppStore();
const { isAgree } = storeToRefs(appStore);

const isPopupShown = ref(false);
</script>

<style scoped lang="scss">
.permission {
  @extend .row-container;
  gap: $--8px;
  margin-top: 4px;

  &__icon {
    width: rems(42);
    min-width: rems(42);
    height: rems(42);
    border-radius: 5px;

    background-color: white;
    border: 1.5px solid #ccc;

    &--active {
      border-color: $equick-primary;
      @include svgBg($equick-tick, $--24px);
    }
  }

  &__text {
    @include font(rems(14), 120%, black);

    span {
      @extend .pointer;
      color: $equick-primary;
      font-weight: 500;
    }
  }
}
</style>
