import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

import KeyComponent from '@/components/Keyboard/KeyComponent.vue';
import { Key } from '@/types/Keyboard';
import { useKeyboard } from "@/composable/useKeyboard";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickKeyboard',
  props: {
  keyboardType: {
    type: String,
    default: ''
  }
},
  emits: ['key-click'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const appStore = useAppStore();
const { screenOrientation } = storeToRefs(appStore);
const {
  keys, size, keySize
} = useKeyboard({
  keyboardType: props.keyboardType,
  keyboardOffset: screenOrientation.value === 'landscape' ? 24 : 12,
  keyOffset: screenOrientation.value === 'landscape' ? 10 : 8,
  onClick: (key: Key) => emits('key-click', key)
});

const onClick = (key: Key) => emits('key-click', key);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["keyboard", `keyboard--${_unref(size)}`])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(keys), (row, rowKey) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "keyboard__row",
        key: rowKey,
        style: _normalizeStyle({height: `${_unref(keySize)}px`})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (item, itemKey) => {
          return (_openBlock(), _createBlock(KeyComponent, {
            key: itemKey,
            itemData: item,
            size: _unref(keySize),
            "key-offset": _unref(screenOrientation).value === 'landscape' ? 10 : 8,
            onClick: ($event: any) => (onClick(item))
          }, null, 8, ["itemData", "size", "key-offset", "onClick"]))
        }), 128))
      ], 4))
    }), 128))
  ], 2))
}
}

})