<template>
  <div class="queue__times-item">
    <p class="queue__times-item__text">{{ text }}</p>
    <p v-if="info?.settings?.isCountTime" class="badge">{{ count }}</p>
  </div>
</template>

<script lang="ts" setup>
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";

defineProps({
  text: String,
  count: Number
});

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
</script>

<style lang="scss" scoped>
.queue__times-item {
  position: relative;
  min-width: 6rem;
  margin: 0 1rem 1rem 0;
  background-color: $blue;
  border-radius: 24px;

  &__text {
    @extend .text-center, .bold, .font-size-l;
    line-height: 3.125rem;
    color: white;
  }
}

.badge {
  top: -6px;
  right: -4px;
}
</style>
