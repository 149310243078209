import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { key: 3 }

import { computed, onMounted, ref } from "vue";
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import TitleText from "@/components/Text/TitleText.vue";
import TreeItems from "@/components/Tree/TreeItems.vue";
import BreadcrumbsComponent from "@/components/Text/BreadcrumbsComponent.vue";
import DataInput from "@/components/Input/DataInput.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeView',
  emits: ['set-footer-buttons'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const treeItems = ref([]);
const inputParams = ref({});
const isLoading = ref(true);
//const isDone = ref(false);
const title = ref('');
const currentType = ref('');

const isDataInput = computed((): boolean => ['FLN', 'taxNumber', 'date'].includes(currentType.value));

const onItemClick = async (item: string, index: number) => {
  /*await this.getAnswer({
    paymentSession: this.session,
    data: { select: index }
  });*/
  await setQuestionData();
};

const onNextClick = async () => {
  /*const stringValue = enteredValue.map(item => item.value).join('').trim();
  await this.getAnswer({
    paymentSession: this.session,
    data: { value: stringValue }
  });*/
  await setQuestionData();
};

const setQuestionData = async () => {
  isLoading.value = true;
  /*await this.getQuestion({
    language: this.language,
    paymentSession: this.session
  });*/

  /*if (!this.question.done) {
    const { variantList, text, test, type } = this.question.request;
    this.currentType = type;

    if (type === 'singleSelect') {
      this.treeItems = variantList;
      this.title = text;
    } else if (this.isDataInput) {
      this.inputParams = { ...inputParams[type], label: test };
      this.title = test;
      this.$emit('setFooterButtons',
          [{ text: 'Назад', type: 'back' }, { text: 'Головна', type: 'main' },
            { text: 'Далі', type: 'next', handler: this.handlerNextClick }]
      );
    }

    this.addPathItem(text);
    this.breadcrumbs = this.path.getTexts(this.language);
  } else {
    this.$router.push('/results');
  }*/

  isLoading.value = false;
}

onMounted(() => {
  emits('set-footer-buttons', [{ type: 'back' }, { type: 'main' }]);
  setQuestionData();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(InnerLoader, { isLoading: isLoading.value }, null, 8, ["isLoading"]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_ctx.$attrs.class)
    }, [
      _createVNode(BreadcrumbsComponent),
      _createElementVNode("div", _hoisted_1, [
        (currentType.value === 'singleSelect')
          ? (_openBlock(), _createBlock(TitleText, {
              key: 0,
              text: title.value
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        (currentType.value === 'singleSelect')
          ? (_openBlock(), _createBlock(TreeItems, {
              key: 1,
              items: treeItems.value,
              onItemClick: onItemClick
            }, null, 8, ["items"]))
          : (isDataInput.value)
            ? (_openBlock(), _createBlock(DataInput, _mergeProps({ key: 2 }, inputParams.value, { key: currentType.value }), null, 16))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(currentType.value), 1))
      ])
    ], 2), [
      [_vShow, !isLoading.value]
    ])
  ]))
}
}

})