import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "queue__current" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "queue__current-info" }
const _hoisted_4 = {
  key: 0,
  class: "queue__current-info__item"
}
const _hoisted_5 = { class: "queue__current-message" }
const _hoisted_6 = { class: "queue__current-value" }
const _hoisted_7 = {
  key: 1,
  class: "queue__current-info__item"
}
const _hoisted_8 = { class: "queue__current-message" }
const _hoisted_9 = { class: "queue__current-value" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "queue__current-message" }
const _hoisted_12 = { class: "queue__current-value" }

import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueuePeriodCurrent',
  emits: ['set-queue-type', 'register-current'],
  setup(__props) {



const i18n = useI18n();
const terminalStore = useTerminalStore();
const { availableCurrent, info } = storeToRefs(terminalStore);

const getFormattedTime = (value: number) => {
  const time: string[] = [];
  if (availableCurrent.value?.moreThan) {
    time.push(i18n.t('queue.more_than'));
  }

  value = 120000;

  if (value) {
    const hours = Math.floor(value / 3_600_000);
    if (hours > 0) {
      time.push(`${ hours } ${ i18n.t('units.hours') }.`);
    }
    const minutes = Math.floor(value / 60_000 - hours * 3_600_000);
    if (minutes > 0) {
      time.push(`${ minutes } ${ i18n.t('units.minutes') }.`);
    }
  }

  return time.join(' ');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      innerHTML: _ctx.$t('equick.queue_current_title'),
      class: "queue__current-title"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_unref(availableCurrent)?.quantity > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_unref(info)?.settings?.isCountCurrent)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('equick.queue_current_people')), 1),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(availableCurrent).queueLength ?? 0), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(availableCurrent)?.approximateWaitingMs && _unref(availableCurrent)?.approximateWaitingMs / 60_000 > 0 || true)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('equick.queue_current_time')), 1),
                  _createElementVNode("p", _hoisted_9, _toDisplayString(getFormattedTime(_unref(availableCurrent)?.approximateWaitingMs)), 1)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : (_unref(availableCurrent)?.quantity === 0 && _unref(availableCurrent)?.appearanceWaitingMs >= 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('equick.queue_current_wait')), 1),
              _createElementVNode("p", _hoisted_12, _toDisplayString(getFormattedTime(_unref(availableCurrent)?.appearanceWaitingMs)), 1)
            ]))
          : _createCommentVNode("", true)
    ]),
    _createVNode(EquickBaseButton, {
      text: _ctx.$t('equick.queue_current_register'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('register-current')))
    }, null, 8, ["text"]),
    _createVNode(EquickBaseButton, {
      text: _ctx.$t('equick.queue_current_choose_date'),
      color: "white",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('set-queue-type', 'fixed')))
    }, null, 8, ["text"])
  ]))
}
}

})