import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "content__pages" }
const _hoisted_2 = { class: "flex-row" }
const _hoisted_3 = ["onClick"]

import { computed } from 'vue';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoPaginator',
  props: {
  pages: {
    type: Number,
    default: 0
  }
},
  emits: ['next', 'previous'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const appStore = useAppStore();
const { pageIndent } = storeToRefs(appStore);

const currentPages = computed((): number[] => {
  const from = props.pages <= 10 ? 1 : Math.min(pageIndent.value + 1, props.pages - 9);
  const to = props.pages <= 10 ? props.pages : Math.min(pageIndent.value + 10, props.pages);
  const pages: number[] = [];
  for (let i = from; i <= to; i++) {
    pages.push(i);
  }
  return pages;
});

const onPreviousClick = () => emits('previous');

const onNextClick = () => emits('next');

const onPageClick = async (number: number) => pageIndent.value = number - 1;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "content__button previous",
      onClick: onPreviousClick
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentPages.value, (n) => {
        return (_openBlock(), _createElementBlock("p", {
          key: n,
          class: _normalizeClass(["content__pages-item", {'content__pages-item--selected': _unref(pageIndent) === n - 1}]),
          onClick: ($event: any) => (onPageClick(n))
        }, _toDisplayString(n), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "content__button next",
      onClick: onNextClick
    })
  ]))
}
}

})