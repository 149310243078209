import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main__top" }
const _hoisted_2 = { class: "main__top-left" }
const _hoisted_3 = { class: "main__top-right" }

import EquickImage from "@/components/Equick/EquickImage.vue";
import EquickLogo from "@/components/Equick/EquickLogo.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueueLogos',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(EquickLogo, {
        place: "main",
        class: "main__top-left__logo"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(EquickImage)
    ])
  ]))
}
}

})