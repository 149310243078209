<template>
  <div class="header__buttons">
    <header-button class="unavailable" :text="$t('header.info_button')"/>
    <header-button class="unavailable" :text="$t('header.rates_button')"/>
    <header-cart-button v-if="shoppingCartSum"/>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import HeaderButton from "@/components/Buttons/HeaderButton.vue";
import HeaderCartButton from "@/components/Buttons/HeaderCartButton.vue";

const shoppingCartSum = ref(false);
</script>

<style lang="scss" scoped>
.header__buttons {
  @extend .row-container;
  height: 50px;
  border-radius: 4px;
  overflow: hidden;
}

@media (min-width: 2000px) {
  .header__buttons {
    height: 3vh;
  }
}
</style>
