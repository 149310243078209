import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import InputArrow from "@/components/Input/InputArrow.vue";
import { inject } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputPreviousButton',
  setup(__props) {

const onClick = inject('on-prev-click') as () => void;

const onButtonClick = () => {
  const back = document.getElementById('back');
  if (back && back.classList.contains('disabled')) {
    return;
  }

  onClick();
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "input__button input__button--enabled",
    onClick: onButtonClick
  }, [
    _createVNode(InputArrow, { "is-enabled": true })
  ]))
}
}

})