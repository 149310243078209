<template>
  <section class="main__input">
    <div class="main__search" :class="{'main__search--active': isKeyboardShown}">
      <div class="main__search-input" @click="isKeyboardShown = true">
        <p v-if="!currentValue.length" class="main__search-input__placeholder">{{ $t('equick.search_placeholder') }}</p>
        <div class="main__search-value">
          <p v-for="(item, key) in currentValue" :key="key" class="main__search-value__item"
             :class="{'current': key === currentPosition}" @click="currentPosition = key">
            {{ item.value }}
          </p>
        </div>
      </div>
      <div class="main__search-button clear" :class="{'inactive': !currentValue.length}"
           @click="clearValue(false)"></div>
      <div class="main__search-button search" @click="onSearchClick"></div>
      <div class="main__search-button keyboard" @click="isKeyboardShown = !isKeyboardShown"></div>
    </div>
    <equick-keyboard v-show="isKeyboardShown" :key="currentKeyboardType" :keyboard-type="currentKeyboardType"
                     @key-click="onKeyClick" />
  </section>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import EquickKeyboard from "@/components/Equick/Keyboard/EquickKeyboard.vue";
import { useInput } from "@/composable/useInput";

defineProps({
  modelValue: Boolean,
});
const emits = defineEmits(['update:modelValue', 'search']);

const {
  hintSet, currentValue, startMaskValue, hints, isValid, isKeyboardShown, lastKey, currentPosition,
  currentKeyboardType, prevType, hintsKey, currentField, handlers,

  onKeyClick, changeType, changeValue, deleteSymbol, clearValue, goForward, goBackward, getSplittedValue, setPosition,
  setHintValue, setFormattedValue, setCurrentPosition, setStartMaskValue, validate, onFieldClick,
} = useInput({
  initialValue: [],
  isKeyboardShownByDefault: false,
  keyboardDefaultType: 'ru',
  maxLength: 100,
  mask: ''
});

const textValue = computed((): string => currentValue.value.map(s => s.value).join(''));

const onSearchClick = () => {
  isKeyboardShown.value = false;
  emits('search', textValue.value);
};

watch(() => currentValue.value, () => emits('search', textValue.value), { deep: true });
</script>

<style scoped lang="scss">
.main {
  &__input {
    @extend .flex-col;

    &:has(.main__search--active) {
      height: 100%;
    }
  }

  &__search {
    @extend .flex-row;
    gap: $--16px;

    &--active {
      .main__search-input {
        border-color: $equick-primary;
      }

      .main__search-button.keyboard {
        background-color: $equick-primary !important;

        &:before {
          filter: grayscale(1) brightness(2);
        }
      }
    }

    &-input {
      @extend .row-container, .shadow, .pointer, .smooth-change;
      position: relative;
      width: 100%;
      height: rems(72);
      padding: $--16px;
      background-color: white;
      border: 1.5px solid white;
      border-radius: 15px;

      &__placeholder {
        position: absolute;
        @include font($--24px, 120%, #585858);
      }
    }

    &-value {
      @extend .flex-row;
      @include font($--24px, 120%, black, 500);

      &__item {
        padding: 0 2px;
        border-right: 1.5px solid transparent;

        &:first-child {
          padding-left: 0;
        }

        &.current {
          border-right: 1.5px solid black;
        }
      }
    }

    &-button {
      &.search {
        @include iconButton($equick-search);
      }

      &.keyboard {
        @include iconButton($equick-keyboard);
      }

      &.clear {
        @include iconButton($equick-close);

        &.inactive:before {
          filter: grayscale(1);
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .main__search--active {
    margin-bottom: auto;
  }
}
</style>
