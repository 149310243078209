import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "times" }
const _hoisted_2 = { class: "times__header" }
const _hoisted_3 = { class: "times__header-title" }
const _hoisted_4 = {
  key: 0,
  class: "times__list"
}
const _hoisted_5 = ["onClick"]

import { computed, ref } from "vue";
import MessagePopup from "@/components/Popups/MessagePopup.vue";
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

interface Time {
  date: string;
  time: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoQueueTimes',
  props: {
  selected: String,
  isLoading: Boolean
},
  emits: ['set-time'],
  setup(__props, { emit: __emit }) {


const emits = __emit;

const terminalStore = useTerminalStore();
const { timePeriods } = storeToRefs(terminalStore);

const error = ref('');
const offset = ref(0);

const maxOffset = computed((): number => Math.ceil(timePeriods.value.length / 25));

const times = computed((): Time[][] => {
  const times: Time[][] = [[]];
  const periods = timePeriods.value.slice(offset.value * 25, offset.value * 25 + 25);
  for (const period of periods) {
    times[times.length - 1].push({
      date: period.a,
      time: new Date(period.a).toTimeString().slice(0, 5)
    });
    if (times[times.length - 1].length % 5 === 0) {
      times.push([]);
    }
  }

  return times;
});

const onTimeClick = (time: string) => emits('set-time', time);

const onPrevClick = () => offset.value <= maxOffset.value && offset.value--;

const onNextClick = () => offset.value >= 0 && offset.value++;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["times__header-prev", {invisible: offset.value === 0}]),
          onClick: onPrevClick
        }, null, 2),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('free_time')), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["times__header-next", {invisible: offset.value === maxOffset.value - 1}]),
          onClick: onNextClick
        }, null, 2)
      ]),
      (!__props.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(times.value, (block, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "times__row"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block, (time) => {
                  return (_openBlock(), _createElementBlock("p", {
                    key: time.time,
                    class: _normalizeClass(["times__row-item", {selected: time.date === __props.selected}]),
                    onClick: ($event: any) => (onTimeClick(time.date))
                  }, _toDisplayString(time.time), 11, _hoisted_5))
                }), 128))
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(InnerLoader, {
            key: 1,
            "is-loading": true
          }))
    ]),
    (error.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 0,
          message: error.value,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = ''))
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})