import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "menu-item__icon"
}
const _hoisted_2 = { class: "menu-item__text" }

import { PropType } from "vue";
import { IncludeItem } from "@/types/Info";


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuItem',
  props: {
  itemData: Object as PropType<IncludeItem>,
  hasIcon: Boolean,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menu-item", [`menu-item--${ __props.itemData.type }`, {'menu-item--disabled': !__props.itemData.active}]])
  }, [
    (__props.hasIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.itemData.name ?? __props.itemData.type), 1)
  ], 2))
}
}

})