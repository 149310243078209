<template>
  <header class="header" :class="{'header--main': route.path === '/equick/queue-tree' }">
    <equick-logo class="header__logo" place="header" />
    <section class="header__info">
      <div class="header__info-name">
        <p>№{{ info?.description?.name }}</p>
        <p>{{ info?.description?.address }}, v{{ info?.settings?.version }}</p>
        <p>{{ time }}</p>
      </div>

      <div class="header__info-phones">
        <p v-for="phone in phones" :key="phone">+{{ phone }}</p>
      </div>
    </section>
  </header>
</template>

<script setup lang="ts">
import EquickLogo from "@/components/Equick/EquickLogo.vue";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app.store";
import { useDateTime } from "@/composable/useDatetime";
import { computed, ref, watch } from "vue";
import { InfoSetting } from "@/types/Info";
import { useRoute } from "vue-router";

const route = useRoute();
const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const appStore = useAppStore();
const { serverTime } = storeToRefs(appStore);
const { getDateTimeStr } = useDateTime();

const time = ref('---');

const settings = computed((): InfoSetting => info.value?.settings);
const phones = computed((): string[] => settings.value?.telephone ?? []);

watch(() => serverTime.value, () => time.value = getDateTimeStr(serverTime.value ?? new Date()));
</script>

<style scoped lang="scss">
$bg: "data:image/svg+xml,%3Csvg width='1194' height='549' viewBox='0 0 1194 549' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_1355_12211)'%3E%3Cpath d='M0.0823028 17.3779C0.0378519 14.4733 2.46949 12.1424 5.36955 12.3096L1190.65 80.6552C1192.29 80.7493 1193.77 81.6364 1194.63 83.0303L1290.08 238.461C1291.95 241.499 1290.12 245.457 1286.6 246.016L12.3822 448.097C9.37683 448.574 6.64618 446.278 6.59961 443.235L0.0823028 17.3779Z' fill='url(%23paint0_linear_1355_12211)' fill-opacity='0.4'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_1355_12211' x='-99.9185' y='-87.6992' width='1490.74' height='635.859' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='50' result='effect1_foregroundBlur_1355_12211'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_1355_12211' x1='6.68804' y1='230.437' x2='1425.39' y2='230.437' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='%2318C9E4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A";

.header {
  @extend .row-container;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  @include font($--16px, 120%, black, 300);

  & > * {
    z-index: 1;
  }

  &__info {
    @extend .row-container;
    justify-content: space-between;
    gap: rems(64);

    &-phones, &-name {
      @extend .flex-col;
      gap: $--4px;
    }
  }
}

@media (orientation: landscape) {
  .header {
    &:before {
      position: absolute;
      left: rems(-40);
      top: rems(-24);
      @include before(120%, 52.4vh);
      @include svgBg($bg);
      background-size: 100% 70vh;
      background-position-y: 0;
      z-index: 0;
    }

    &:not(.header--main) {
      .header__logo {
        margin-top: rems(-10);
      }
    }

    &__logo {
      height: rems(100);
    }
  }
}

@media (orientation: portrait) {
  .header {
    &--main .header__logo, &:has(+ .queue-period) .header__logo, &--main .header__bg {
      display: none;
    }

    &__info {
      width: 100%
    }

    &:has(.header__logo) {
      margin-bottom: $--4px;
    }

    &:not(.header--main):not(:has(+ .queue-period)) {
      align-items: center;

      .header__logo {
        width: rems(106);
        min-width: rems(106);
        height: $--40px;
      }

      .header__info {
        gap: rems(36);
        width: max-content;
      }

      &:before {
        $bg: "data:image/svg+xml,%3Csvg width='834' height='133' viewBox='0 0 834 133' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_1415_7231)'%3E%3Cpath d='M-8 27.4045C-8 16.2021 1.20025 7.18423 12.4004 7.40851L841.977 24.0203C843.213 24.045 844.444 24.1843 845.655 24.4364L978.283 52.0578C1001.58 56.9101 998.434 91.1157 974.64 91.633L12.4348 112.556C1.22154 112.799 -8 103.776 -8 92.5604V27.4045Z' fill='url(%23paint0_linear_1415_7231)' fill-opacity='0.4'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_1415_7231' x='-28' y='-12.5957' width='1042.21' height='145.156' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='10' result='effect1_foregroundBlur_1415_7231'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_1415_7231' x1='-8' y1='60.2031' x2='1010.48' y2='60.2032' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='%2318C9E4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A";
        position: absolute;
        top: rems(-24);
        right: rems(-24);
        @include before(100%, rems(132));
        @include svgBg($bg);
        background-size: 140% rems(132);
        z-index: 0;
      }
    }
  }
}
</style>
