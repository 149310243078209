import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "stepper" }

import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueueFormStepper',
  setup(__props) {

const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fields).length, (n) => {
      return (_openBlock(), _createElementBlock("div", {
        key: n,
        class: _normalizeClass(["stepper__item", {'stepper__item--active': n - 1 === _unref(currentStep), 'stepper__item--passed': n - 1 < _unref(currentStep)}])
      }, null, 2))
    }), 128))
  ]))
}
}

})