import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tree-item__text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueTreeGroup',
  props: {
  name: String,
  size: String,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tree-item", `tree-item--${__props.size}`])
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(__props.name), 1)
  ], 2))
}
}

})