import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "queue__live-btn__text" }

import { useRouter } from "vue-router";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueLiveButton',
  setup(__props) {

const router = useRouter();
const terminalStore = useTerminalStore();
const { availableCurrent, addResult } = storeToRefs(terminalStore);

const onClick = async () => {
  await terminalStore.addLiveQueue();
  if (addResult.value?.current) {
    await router.push('queue-form');
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "queue__live-btn",
    onClick: onClick
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('button.nearest')) + " (" + _toDisplayString(_unref(availableCurrent).quantity) + ")", 1)
  ]))
}
}

})