<template>
  <section class="main__content">
    <section class="main__list">
      <div v-for="(item, itemKey) in treeItems" :key="itemKey" class="main__list-item" @click="onItemClick(item)">
        <p v-if="item.type === 'group'" class="main__list-item__text">{{ item.name }}</p>
        <p v-else class="main__list-item__text">{{ item.service.description.name }}</p>
      </div>
      <p v-if="!treeItems.length && searchValue.length" class="main__list-empty">{{ $t('equick.not_found') }}</p>
    </section>
    <footer>
      <div v-if="currentGroup" class="footer__button footer__button--back" @click="currentGroup = ''">
        <p class="footer__button-text">{{ $t('footer.button_back') }}</p>
      </div>
      <div v-if="maxIndent > 1" class="main__pagination">
        <div class="main__pagination-prev" :class="{'disabled': pageIndent === 0}" @click="onPreviousClick"></div>
        <p class="main__pagination-value">{{ pageIndent + 1 }}</p>
        <div class="main__pagination-next" :class="{'disabled': pageIndent === maxIndent - 1}"
             @click="onNextClick"></div>
      </div>
    </footer>
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { TreeGroup } from "@/types/Tree";
import { Service } from "@/types/Service";
import { ServicePackage } from "@/types/ServicePackage";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const props = defineProps({
  searchValue: {
    type: String,
    default: ''
  }
});
const emits = defineEmits(['item-click', 'clear-search'])

const appStore = useAppStore();
const { currentGroup, pageIndent } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { services, servicePackages } = storeToRefs(terminalStore);

const items = computed((): (Service | ServicePackage)[] => {
  return [...services.value, ...servicePackages.value];
});

const groups = computed((): TreeGroup[] => {
  const groups: TreeGroup[] = [];
  [...items.value]
      .map(item => {
        if (item?.group?.length > 0) {
          for (let i = 0; i < item.group.length; i++) {
            const index = groups.findIndex(group => group.name === item.group[i]);
            if (index >= 0) {
              groups[index].groups?.push(item.group[i]);
            } else {
              groups.push({
                type: 'group',
                name: item.group[i],
                parent: i === 0 ? '' : item.group[i - 1],
                services: [],
                groups: []
              });
            }

            if (i === item.group.length - 1) {
              groups[index >= 0 ? index : groups.length - 1].services?.push(item);
            }
          }
        } else {
          groups.push({ type: 'service', service: item });
        }
      });
  return groups;
});

const maxItems = computed((): number => 6);
const maxIndent = computed((): number => Math.ceil(filteredItems.value.length / maxItems.value));

const filteredItems = computed((): TreeGroup[] => {
  const filteredItems: TreeGroup[] = [];

  groups.value.map(group => {
    if (group.type === 'group' && (group.name === currentGroup.value || !currentGroup.value || group.parent === currentGroup.value)) {
      if (group.name !== currentGroup.value) {
        if (!group.parent || group.parent === currentGroup.value) {
          filteredItems.push(group);
        }
      } else {
        group.services?.map(service => filteredItems.push({ type: 'service', service }));
      }
    } else if (group.type === 'service' && !currentGroup.value) {
      filteredItems.push(group);
    }
  });

  return filteredItems.filter(item => {
    if (item.type === 'service') {
      return item?.service?.description?.name?.toLowerCase()?.includes(props.searchValue.toLowerCase());
    } else {
      return item?.name?.toLowerCase()?.includes(props.searchValue.toLowerCase());
    }
  });
});

const treeItems = computed((): TreeGroup[] => {
  return filteredItems.value.slice(pageIndent.value * maxItems.value, pageIndent.value * maxItems.value + maxItems.value);
});

const onItemClick = (item: TreeGroup) => {
  if (item.type === 'group') {
    currentGroup.value = item.name as string;
    emits('clear-search');
  } else {
    emits('item-click', item.service);
  }
};

const onNextClick = async () => {
  if (pageIndent.value < maxIndent.value - 1) {
    pageIndent.value = pageIndent.value + 1;
  } else {
    pageIndent.value = 0;
  }
};

const onPreviousClick = async () => {
  if (maxIndent.value >= 1) {
    if (pageIndent.value > 0) {
      pageIndent.value = pageIndent.value - 1;
    } else {
      pageIndent.value = maxIndent.value - 1;
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  &__content {
    @extend .flex-col;
    height: 100%;
  }

  &__list {
    @extend .flex-row;
    flex-wrap: wrap;
    gap: $--24px;
    height: 100%;
    width: 100%;
    padding: 4px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: initial;
      width: 12px;
      background-color: $equick-primary;
      border-radius: 100px;
    }

    &-item {
      @extend .row-container;
      justify-content: center;
      flex: 1;

      padding: $--16px;
      background-color: white;
      border: 1.5px solid $equick-secondary;
      border-radius: 15px;
      box-shadow: 0 4px 4px 0 #00000040;

      &:active {
        transform: translateY(2px);
      }

      &__text {
        @include font($--24px, 120%, black, 400, center);
      }
    }

    &-empty {
      width: 100%;
      margin: auto;
      @include font(rems(28), 120%, black, 500, center);
    }
  }

  &__pagination {
    @extend .row-container;
    justify-content: center;

    &-prev, &-next {
      @include iconButton($equick-arrow, rems(60), 5px);;
    }

    &-next:before {
      transform: rotate(0.5turn);
    }

    &-value {
      width: rems(180);
      @include font($--40px, 120%, black, 400, center);
    }
  }
}

footer {
  justify-content: center;

  .footer__button--back {
    margin-right: auto;
  }
}

@media (orientation: landscape) {
  .main__list {
    //grid-template-columns: repeat(3, 1fr);
    //grid-template-rows: repeat(2, 1fr);
    margin: $--24px 0;

    &-item {
      min-width: calc((100% - #{$--24px} * 2) / 3);
      max-width: calc((100% - #{$--24px} * 2) / 3);
      min-height: calc((100% - #{$--24px}) / 2);
      max-height: calc((100% - #{$--24px}) / 2);
    }
  }
}

@media (orientation: portrait) {
  .main__list {
    //grid-template-columns: repeat(2, 1fr);
    //grid-template-rows: repeat(3, 1fr);
    margin: rems(36) 0;

    &-item {
      min-width: calc((100% - #{$--24px}) / 2);
      max-width: calc((100% - #{$--24px}) / 2);
      min-height: calc((100% - #{$--24px} * 2) / 3);
      max-height: calc((100% - #{$--24px} * 2) / 3);
    }
  }
}
</style>
