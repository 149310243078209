<template>
  <div class="dates">
    <div class="dates__month">
      <div class="dates__month-prev" :class="{invisible: offset === 0}" @click="onPrevClick"></div>
      <p class="dates__month-title">{{ monthName }} {{ year }}</p>
      <div class="dates__month-next" :class="{invisible: offset === 11}" @click="onNextClick"></div>
    </div>
    <div class="dates__calendar">
      <div class="dates__header">
        <p v-for="day in 7" :key="day" class="dates__header-item">{{ $t(`days[${ day - 1 }]`) }}</p>
      </div>
      <div class="dates__list">
        <div v-for="(week, index) in calendar" :key="index" class="dates__row">
          <p v-for="day in week" :key="day.date" class="dates__row-item" :class="day.class"
             @click="onDateClick(day.date)">
            {{ day.number }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts' setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

interface WeekDay {
  date: Date;
  number: number;
  class: { [key: string]: boolean };
}

const props = defineProps({
  selected: String
});
const emits = defineEmits(['set-date']);

const terminalStore = useTerminalStore();
const { datePeriods } = storeToRefs(terminalStore);

const date = new Date();

const month = ref(date.getMonth());
const year = ref(date.getFullYear());
const offset = ref(0);

const calendar = computed((): WeekDay[][] => {
  const calendar: WeekDay[][] = [];
  const firstDayOfMonth = new Date(year.value, month.value, 1)
  const lastDayOfMonth = new Date(year.value, month.value + 1, 0);
  const daysInMonth = lastDayOfMonth.getDate();
  const firstDayOfWeek = firstDayOfMonth.getDay();

  let currentDate = 2 - firstDayOfWeek;
  while (currentDate <= daysInMonth) {
    const week: WeekDay[] = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(year.value, month.value, currentDate);
      week.push({
        date: day,
        number: day.getDate(),
        class: {
          selected: isDatesEqual(props.selected ?? date, day),
          today: isDatesEqual(date, day),
          invisible: day.getMonth() !== month.value,
          disabled: !datePeriods.value.find(item => isDatesEqual(new Date(item.date), day)),
        }
      });
      currentDate++;
    }

    calendar.push(week);
  }

  return calendar;
});

const monthName = computed((): string => {
  return new Date(year.value, month.value).toLocaleDateString('uk-UA', { month: 'long' });
});

const onNextClick = () => {
  if (month.value < 11) {
    month.value++;
  } else {
    year.value++;
    month.value = 0;
  }
  offset.value++;
};

const onPrevClick = () => {
  if (month.value > 0) {
    month.value--;
  } else {
    year.value--;
    month.value = 11;
  }
  offset.value--;
};

const onDateClick = (date: Date) => emits('set-date', date.toISOString());

const isDatesEqual = (date1: Date | string, date2: Date | string) => new Date(date1).toDateString() === new Date(date2).toDateString();
</script>

<style lang='scss' scoped>
.dates {
  @extend .col-container;
  min-width: 30vw;
  margin-right: 2rem;

  &__month {
    @extend .flex-row;
    justify-content: space-between;

    &-prev, &-next {
      @extend .pointer;
      width: 3rem;
    }

    &-prev {
      margin-right: 1rem;
      @include svgBg($arrow-left, 1.75rem);
    }

    &-next {
      margin-left: 1rem;
      @include svgBg($arrow-right, 1.75rem);
    }

    &-title {
      width: 14rem;
      padding: 1rem 0;
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: capitalize;
      text-align: center;
      background-color: $yellow;
      border-radius: 4px;
    }
  }

  &__calendar {
    @extend .flex-col;
    margin-top: 0.5rem;
  }

  &__header, &__row {
    @extend .flex-row;

    &-item {
      width: 3.5rem;
      margin: 0 2px;
      text-align: center;
    }
  }

  &__header {
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #999;

    &-item {
      text-transform: lowercase;
      font-size: 18px;
    }
  }

  &__list {
    @extend .flex-col;
  }

  &__row {
    &-item {
      @extend .pointer;
      font-size: 1.5rem;
      line-height: 4rem;

      &.today {
        color: $yellow;
        filter: brightness(0.95);
      }

      &.selected {
        background-color: $yellow;
        color: black;
        filter: brightness(1);
        border-radius: 4px;
        font-weight: 700;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

.invisible {
  opacity: 0 !important;
  pointer-events: none;
}
</style>
