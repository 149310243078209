import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputKeyboardButton',
  props: { state: Boolean },
  setup(__props) {

const props = __props;

const isActive = ref(props.state);

const onClick = () => isActive.value = !isActive.value;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input__keyboard", {'input__keyboard--active': isActive.value}]),
    onClick: onClick
  }, null, 2))
}
}

})