<template>
  <inner-loader :isLoading="isLoading" />
  <div v-show="!isLoading && !error" :class="$attrs.class">
    <template v-if="!isGotovo">
      <breadcrumbs-component />
      <title-text :text="$t('queue.choose_period')" />
      <default-queue />
    </template>
    <gotovo-queue v-else @load-data="loadDateTimeData" @set-footer-buttons="setFooterButtons" />
  </div>

  <message-popup v-if="errorText" :message="errorText" @close="goBack" />
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import BreadcrumbsComponent from '@/components/Text/BreadcrumbsComponent.vue';
import TitleText from '@/components/Text/TitleText.vue';
import DefaultQueue from '@/components/Queue/DefaultQueue.vue';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMain } from '@/composable/useMain';
import GotovoQueue from '@/components/Queue/Gotovo/GotovoQueue.vue';
import { FooterButton } from '@/types/Footer';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const emits = defineEmits(['set-footer-buttons']);

const router = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { datePeriods, error } = storeToRefs(terminalStore);
const { isGotovo } = useMain();

const isLoading = ref(true);
const errorText = ref('');

const loadDateTimeData = async () => {
  isLoading.value = true;
  await terminalStore.getTQueueDatePeriods();
  if (datePeriods.value?.length) {
    await terminalStore.setTQueueDatePeriod(datePeriods.value[0].date);
    await terminalStore.getTQueueTimePeriods();
    //setEventSource();
  } else {
    errorText.value = error.value === 'timeout' ? i18n.t('message.load_error') : error.value;
  }
  isLoading.value = false;
};

/*const setEventSource = () => {
  if (eventSource.value !== null) {
    return;
  }

  let path = '', headers = { Authorization: `Bearer ${ store.getters.token }` };
  if (process.env.VUE_APP_SERVER_TYPE === 'local') {
    path = 'http://localhost:3000/sse/status';
    //path = 'http://localhost:3001/sse/status';
  } else {
    const MAIN_API_URL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEVELOPER_SERVER_API : process.env.VUE_APP_SERVER_API;
    path = `${ MAIN_API_URL }/terminal-queue-token/sse/status`;
  }

  eventSource.value = new EventSource(path, {
    withCredentials: true,
    headers
  } as EventSourceInit);

  eventSource.value.onmessage = async function(event: any) {
    const parsedData: {
      timePeriods?: boolean;
      event?: {
        reload?: boolean;
      };
    } = JSON.parse(event.data);
    console.log(parsedData);
    if (parsedData?.timePeriods) {
      await store.dispatch('getTQueueTimePeriods');
    }

    if (parsedData?.event?.reload) {
      await store.dispatch('setToken', '');
      if (route.path === '/' || route.path === 'queue-tree') {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    }
  };
};*/

const goBack = async () => {
  errorText.value = '';
  appStore.removeLastPathItem();
  await router.push('queue-tree');
};

const setFooterButtons = (buttons: FooterButton[]) => emits('set-footer-buttons', buttons);

onMounted(async () => {
  emits('set-footer-buttons', [
    { type: 'back', backLink: 'queue-tree' },
    { type: 'main' }
  ]);

  appStore.addPathItem('queue-period');
  currentStep.value = 0;

  if (!isGotovo.value) {
    await loadDateTimeData();
  }

  isLoading.value = false;
});

/*onUnmounted(() => {
  if (eventSource.value !== null) {
    eventSource.value.close();
  }
});*/

//watch(() => results.value.datePeriod, setEventSource, { deep: true });
</script>

<style lang="scss" scoped>

</style>
