import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import InputArrow from "@/components/Input/InputArrow.vue";
import { inject, onMounted, onUnmounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputNextButton',
  setup(__props) {

const isEnabled = ref(true);
const onClick = inject('on-next-click') as () => void;

const setState = (e: Event) => {
  const detail = (e as CustomEvent).detail;
  isEnabled.value = !detail;
};

const onButtonClick = () => {
  const next = document.getElementById('next');
  if (next && next.classList.contains('disabled')) {
    return;
  }

  onClick();
}

onMounted(() => window.addEventListener('set-button-state', setState));
onUnmounted(() => window.removeEventListener('set-button-state', setState));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input__button", {'input__button--enabled': isEnabled.value}]),
    onClick: onButtonClick
  }, [
    _createVNode(InputArrow, { "is-enabled": isEnabled.value }, null, 8, ["is-enabled"])
  ], 2))
}
}

})