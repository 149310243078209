import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "loader" }
const _hoisted_2 = { class: "loader__items" }
const _hoisted_3 = { class: "loader__text" }

import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoaderComponent',
  props: {
  text: {
    type: String,
    default: null
  }
},
  setup(__props) {

const props = __props;

const i18n = useI18n();
const loaderText = props.text ?? i18n.t('loading');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (n) => {
        return _createElementVNode("div", {
          class: _normalizeClass(["loader__item", `loader__item--${n}`]),
          key: n
        }, null, 2)
      }), 64))
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(loaderText)), 1)
  ]))
}
}

})