<template>
  <div class="input">
    <input-previous-button v-if="hasButtons" />
    <div class="input-box">
      <input-label v-if="currentField?.label" :text="currentField.label" />
      <input-field :current-value="currentValue" :current-position="currentPosition" :is-valid="isValid"
                   @click="onFieldClick" />
      <input-erase-button @click="onClearClick" />
    </div>
    <input-keyboard-button v-if="hasToggleButton" :state="toggleState" :key="toggleState" @click="onToggleClick" />
    <input-next-button v-if="hasButtons" />
  </div>
</template>

<script lang="ts" setup>
import InputPreviousButton from '@/components/Input/InputPreviousButton.vue';
import InputLabel from '@/components/Input/InputLabel.vue';
import InputField from '@/components/Input/InputField.vue';
import InputEraseButton from '@/components/Input/InputEraseButton.vue';
import InputNextButton from '@/components/Input/InputNextButton.vue';
import { computed } from 'vue';
import { DataField } from '@/types/Form';
import InputKeyboardButton from '@/components/Input/InputKeyboardButton.vue';
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app.store";

const props = defineProps({
  currentValue: Array,
  isValid: Boolean,
  currentPosition: Number,
  hasButtons: {
    type: Boolean,
    default: true
  },
  hasToggleButton: Boolean,
  toggleState: Boolean
});
const emits = defineEmits(['clear-value', 'set-position', 'change-toggle-state']);

const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);

const currentField = computed((): DataField => fields.value[currentStep.value]);

const onClearClick = () => emits('clear-value');

const onFieldClick = (position: number) => emits('set-position', position);

const onToggleClick = () => emits('change-toggle-state', !props.toggleState);
</script>

<style lang="scss" scoped>
.input {
  @extend .flex-row, .page-item;
  align-items: flex-end;
  margin: 0.5rem auto;

  &-box {
    @extend .flex-col;
    flex: 1;
    position: relative;
  }

  &__button {
    position: relative;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    background-color: $background-gray;

    &--enabled {
      background-color: $blue;
    }
  }
}
</style>
