<template>
  <div class="header" id="gotovo-header">
    <div class="header__left">
      <reload-button />
      <div class="header__info">
        <p>{{ $t("terminal") }} №: {{ info.description.name }}</p>
        <p>{{ $t("address") }}: {{ info.description.address }}</p>
      </div>
    </div>
    <img class="header__logo" src="images/gotovo.png" alt="logo" />
    <div class="header__right">
      <div v-if="info?.settings?.telephone?.length" class="header__info col-container">
        <p>{{ $t("help_line_phone") }}:</p>
        <div class="header__info-phones">
          <p v-for="ph in info?.settings?.telephone ?? []" :key="ph">0{{ ph }}</p>
        </div>
      </div>
      <div class="header__info-datetime">
        <div class="flex-col">
          <p class="header__info-datetime__day">{{ day }}</p>
          <p class="header__info-datetime__date">{{ date }}</p>
        </div>
        <p class="header__info-datetime__time">{{ time }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import ReloadButton from '@/components/Buttons/ReloadButton.vue';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app.store';

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const appStore = useAppStore();
const { language, serverTime } = storeToRefs(appStore);

const locales: { [key: string]: string } = {
  'ua': 'uk-UA',
  'ru': 'ru-RU',
  'en': 'en-US'
};

const day = ref('');
const date = ref('');
const time = ref('');
const timeout = ref(0);

const timeZone = computed(() => {
  const timeZone = info.value?.settings?.timeZone;
  return timeZone ? timeZone : 'Europe/Kiev';
});

const setValues = (dateTime: Date | string) => {
  dateTime = new Date(dateTime);

  const locale = locales[language.value];
  day.value = dateTime.toLocaleString(locale, { weekday: 'long', timeZone: timeZone.value });
  date.value = dateTime.toLocaleString(locale, { month: 'long', day: 'numeric', timeZone: timeZone.value });
  time.value = dateTime.toLocaleString(locale, { hour: 'numeric', minute: 'numeric', timeZone: timeZone.value });
};

const setDatetime = () => {
  clearTimeout(timeout.value);

  const dateTime = new Date();
  setValues(dateTime);
  timeout.value = setTimeout(setDatetime, (60 - dateTime.getSeconds()) * 1000);
};

onMounted(() => {
  if (!serverTime.value) {
    setDatetime();
  } else {
    setValues(serverTime.value);
  }
});

watch(() => serverTime.value, () => {
  if (serverTime.value) {
    setValues(serverTime.value);
  } else {
    setDatetime();
  }
});
</script>

<style lang="scss" scoped>
.header {
  @extend .row-container;
  justify-content: center;
  width: 100vw;
  max-height: calc(min(10rem, 15vh));
  min-height: calc(min(10rem, 15vh));
  margin-bottom: 1vh;
  transition-duration: 0.25s;
  overflow: hidden;

  &.hidden {
    max-height: 0;
    min-height: 0;
    margin: 0;
  }

  &__left, &__right {
    @extend .row-container;
    height: 100%;
    flex: 1;
  }

  &__left {
    justify-content: space-between;

    .header__info {
      flex: 1;
    }
  }

  &__right {
    justify-content: space-around;

    .header__info {
      text-align: left;
    }
  }

  &__back {
    @extend .row-container, .pointer;
    width: 5rem;
    height: 100%;
    @include svgBg($home, 2rem);
  }

  &__info {
    max-width: 30vw;
    color: #555;
    font-size: 1.5vw;

    &-phones {
      @extend .flex-col;
      margin-left: 1rem;
    }

    &-datetime {
      @extend .row-container, .text-center;
      min-width: max-content;
      margin: 0 1rem;
      font-size: 1.5vw;

      &__date {
        font-weight: 600;
        font-size: 1.75vw;
      }

      &__time {
        margin-left: 1rem;
        font-size: 4vw;
        font-weight: 600;
      }
    }

    p {
      @extend .text-center;
      max-width: 28vw;
    }
  }

  &__logo {
    max-width: calc(30vw - 1rem * 2);
    height: 100%;
    //margin-left: 2rem;
    object-fit: contain;
  }
}

@media (min-height: 1000px) and (min-width: 1400px) {
  .header {
    margin-bottom: 2rem;
  }
}
</style>
