import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "result-items" }

import ResultItem from "@/components/Results/ResultItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResultItems',
  props: { items: Array },
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, key) => {
      return (_openBlock(), _createBlock(ResultItem, {
        "item-data": item,
        key: key
      }, null, 8, ["item-data"]))
    }), 128))
  ]))
}
}

})