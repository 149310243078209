<template>
  <div class="field__hints">
    <div v-for="(item, key) in currentItems" :key="key" class="field__hints-item" @click="onHintClick(item)">
      <p class="field__hints-item__text">{{ item }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { InputValue } from "@/types/Keyboard";

const props = defineProps({
  items: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  value: {
    type: Array as PropType<InputValue[]>,
    default: () => []
  }
});
const emits = defineEmits(['set-value']);

const currentItems = computed((): string[] => {
  const str = props.value?.map(item => item.value)?.join('') ?? '';
  const startsWith = props.items.filter(item => item.startsWith(str));
  const includes = props.items.filter(item => item.includes(str));

  return [...new Set([...startsWith, ...includes])];
});

const onHintClick = (item: string) => emits('set-value', item);
</script>

<style scoped lang="scss">
.field__hints {
  @extend .flex-row;
  gap: $--16px;
  flex-wrap: nowrap;
  height: rems(44);
  min-height: rems(44);
  margin-top: $--16px;
  overflow-x: auto;
  overflow-y: visible !important;

  &:not(:has(+ .permission)) {
    margin-bottom: auto;
  }

  &-item {
    @extend .pointer;
    height: $--40px;
    padding: 0 $--16px;
    background-color: #DFEFF1;
    border-radius: 5px;

    &__text {
      @include font($--20px, $--40px, black);
    }
  }
}
</style>
