import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "queue__fixed" }
const _hoisted_2 = { class: "queue__fixed-title" }
const _hoisted_3 = { class: "queue__fixed-list queue__fixed-list--dates" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "queue__fixed-list__item-value" }
const _hoisted_6 = {
  key: 0,
  class: "queue__fixed-list__item-amount"
}
const _hoisted_7 = {
  key: 0,
  class: "queue__fixed-message"
}
const _hoisted_8 = { class: "queue__fixed-title" }
const _hoisted_9 = {
  key: 0,
  class: "queue__fixed-list queue__fixed-list--times"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "queue__fixed-list__item-value" }
const _hoisted_12 = {
  key: 0,
  class: "queue__fixed-list__item-amount"
}

import { computed, ref, watch, watchEffect } from "vue";
import { useDateTime } from "@/composable/useDatetime";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { DatePeriod } from "@/types/Period";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueuePeriodFixed',
  emits: ['set-fixed-data'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const i18n = useI18n();
const terminalStore = useTerminalStore();
const { datePeriods, timePeriods, results, info } = storeToRefs(terminalStore);
const { getDateMonth } = useDateTime();

const selectedDate = ref('');
const selectedTime = ref('');
const isLoading = ref(false);
const error = ref('');

const dates = computed((): DatePeriod[] => {
  const maxAmount = info.value?.settings?.countDay >= 0 ? info.value.settings.countDay : 7;
  const periods: DatePeriod[] = [];
  for (let i = 0; i < maxAmount; i++) {
    const date = moment().add(i, 'd');
    const period = datePeriods.value.find(i => i.date.slice(0, 10) === date.format('YYYY-MM-DD'));
    if (period) {
      periods.push(period);
    }
  }
  return periods;
});

const setSelectedDate = async (date: string) => {
  const prevSelected = selectedDate.value;
  selectedDate.value = date;
  isLoading.value = true;
  await terminalStore.setTQueueDatePeriod(date);
  if (results.value?.datePeriod) {
    await terminalStore.getTQueueTimePeriods();
  } else {
    selectedDate.value = prevSelected;
    error.value = `${ i18n.t('equick.queue_fixed_date_error') } ${ getDateMonth(date) }`;
  }
  isLoading.value = false;
};

watchEffect(() => selectedDate.value = datePeriods.value[0]?.date ?? '');

watch([() => selectedDate.value, () => selectedTime.value], () => {
  emits('set-fixed-data', { date: selectedDate.value, time: selectedTime.value });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('equick.queue_fixed_choose_date')), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dates.value, (date) => {
          return (_openBlock(), _createElementBlock("div", {
            key: date.date,
            class: _normalizeClass(["queue__fixed-list__item", {'queue__fixed-list__item--selected': date.date === selectedDate.value}]),
            onClick: ($event: any) => (setSelectedDate(date.date))
          }, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(moment)(date.date).format('DD.MM')), 1),
            (_unref(info)?.settings?.isCountDate)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(date.count), 1))
              : _createCommentVNode("", true)
          ], 10, _hoisted_4))
        }), 128))
      ]),
      (!(_unref(timePeriods)?.length && selectedDate.value))
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('queue.no_available_time')) + " " + _toDisplayString(selectedDate.value), 1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('equick.queue_fixed_choose_time')), 1),
            (!isLoading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(timePeriods), (time) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: time.date,
                      class: _normalizeClass(["queue__fixed-list__item", {'queue__fixed-list__item--selected': time.a === selectedTime.value}]),
                      onClick: ($event: any) => (selectedTime.value = time.a)
                    }, [
                      _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(moment)(time.a).format('HH:mm')), 1),
                      (_unref(info)?.settings?.isCountTime)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(time.count), 1))
                        : _createCommentVNode("", true)
                    ], 10, _hoisted_10))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(EquickLoader, {
          key: 0,
          "animation-delay": 0
        }))
      : _createCommentVNode("", true),
    (error.value)
      ? (_openBlock(), _createBlock(EquickMessagePopup, {
          key: 1,
          message: error.value,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = ''))
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})