<template>
  <div class="times">
    <div class="times__header">
      <div class="times__header-prev" :class="{invisible: offset === 0}" @click="onPrevClick"></div>
      <p class="times__header-title">{{ $t('free_time') }}</p>
      <div class="times__header-next" :class="{invisible: offset === maxOffset - 1}" @click="onNextClick"></div>
    </div>
    <div v-if="!isLoading" class="times__list">
      <div v-for="(block, index) in times" :key="index" class="times__row">
        <p v-for="time in block" :key="time.time" class="times__row-item" :class="{selected: time.date === selected}"
           @click="onTimeClick(time.date)">
          {{ time.time }}
        </p>
      </div>
    </div>
    <inner-loader v-else :is-loading="true" />
  </div>
  <message-popup v-if="error" :message="error" @close="error = ''" />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import MessagePopup from "@/components/Popups/MessagePopup.vue";
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

interface Time {
  date: string;
  time: string;
}

defineProps({
  selected: String,
  isLoading: Boolean
});
const emits = defineEmits(['set-time']);

const terminalStore = useTerminalStore();
const { timePeriods } = storeToRefs(terminalStore);

const error = ref('');
const offset = ref(0);

const maxOffset = computed((): number => Math.ceil(timePeriods.value.length / 25));

const times = computed((): Time[][] => {
  const times: Time[][] = [[]];
  const periods = timePeriods.value.slice(offset.value * 25, offset.value * 25 + 25);
  for (const period of periods) {
    times[times.length - 1].push({
      date: period.a,
      time: new Date(period.a).toTimeString().slice(0, 5)
    });
    if (times[times.length - 1].length % 5 === 0) {
      times.push([]);
    }
  }

  return times;
});

const onTimeClick = (time: string) => emits('set-time', time);

const onPrevClick = () => offset.value <= maxOffset.value && offset.value--;

const onNextClick = () => offset.value >= 0 && offset.value++;
</script>

<style lang="scss" scoped>
.times {
  @extend .flex-col;
  position: relative;
  min-width: 30vw;

  &__header {
    @extend .flex-row;
    justify-content: space-between;
    padding-bottom: 36px;
    border-bottom: 2px solid #999;

    &-prev, &-next {
      @extend .pointer;
      width: 3rem;
    }

    &-prev {
      margin-right: 1rem;
      @include svgBg($arrow-left, 1.75rem);
    }

    &-next {
      margin-left: 1rem;
      @include svgBg($arrow-right, 1.75rem);
    }

    &-title {
      width: 14rem;
      padding: 1rem 0;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  &__list {
    @extend .flex-col;
  }

  &__row {
    @extend .flex-row;

    &-item {
      @extend .pointer;
      width: calc((100% - 0.25rem * 4) / 5);
      margin: 6px 0;
      text-align: center;
      font-size: 1.5rem;
      line-height: calc(4rem - 6px * 2);

      &:not(:first-child):not(:last-child) {
        margin: 6px 0.25rem;
      }

      &.selected {
        background-color: $yellow;
        color: black;
        border-radius: 4px;
        font-weight: 700;
      }
    }
  }

  .loader {
    position: absolute;
    top: 96px;
    left: 8px;
    min-height: calc(100% - 96px);
    max-height: calc(100% - 96px);
    min-width: 30vw;
    width: 30vw;
    margin: auto;
  }
}

.invisible {
  opacity: 0 !important;
  pointer-events: none;
}
</style>
