<template>
  <svg class="input__arrow" width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M13.7072 11.814L1.70684 0.270033C1.30956 -0.0990598 0.676499 -0.0884628 0.292816 0.293707C-0.0814916 0.66652 -0.0814916 1.25753 0.292816 1.63029L11.5861 12.4942L0.292816 23.3581C-0.0976053 23.7337 -0.0976053 24.3427 0.292816 24.7183C0.683354 25.0939 1.31636 25.0939 1.70684 24.7183L13.7072 13.1743C14.0976 12.7986 14.0976 12.1897 13.7072 11.814Z"
        :fill="color"/>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({ isEnabled: Boolean });

const color = computed((): string => props.isEnabled ? '#FFFFFF' : '#999999')
</script>

<style lang="scss" scoped>
.input__arrow {
  position: absolute;
  top: calc(50% - 25px / 2);
  left: calc(50% - 14px / 2);
}
</style>