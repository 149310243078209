import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

import { useMain } from '@/composable/useMain';


export default /*@__PURE__*/_defineComponent({
  __name: 'PopupContainer',
  props: {
  message: String,
  additional: String,
  hasCloseButton: {
    type: Boolean,
    default: true
  },
  teleport: {
    type: String,
    default: null
  }
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {


const emits = __emit;

const { isGotovo } = useMain();

const onCloseClick = () => emits('close');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, {
    to: __props.teleport ?? (_unref(isGotovo) ? '.page' : 'body')
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["popup-container", _ctx.$attrs.class])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["popup-area", {'with-button': __props.hasCloseButton}])
      }, [
        (__props.hasCloseButton)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "popup__close",
              onClick: onCloseClick
            }))
          : _createCommentVNode("", true),
        (__props.message)
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              innerHTML: __props.message,
              class: "popup__message"
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        (__props.additional)
          ? (_openBlock(), _createElementBlock("p", {
              key: 2,
              innerHTML: __props.additional,
              class: "popup__additional"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2)
  ], 8, ["to"]))
}
}

})