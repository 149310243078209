<template>
  <div class="header" :class="{'header--queue': isQueueOnly}">
    <reload-button />

    <help-line v-if="headerType === 'helpLine'" />
    <template v-else>
      <img src="/images/st-logo.svg" alt="logo" class="header__logo" draggable="false" />
      <div class="header__qr">
        <p class="header__qr-text">{{ $t('header.qr_text_1') }}<br />{{ $t('header.qr_text_2') }}</p>
        <img src="/images/st-qr.svg" alt="qr" class="header__qr-icon" draggable="false" />
      </div>
    </template>

    <header-rest v-if="!isQueueOnly" class="unavailable" />
    <div class="header__right">
      <header-info />
      <div v-if="!isQueueOnly" class="flex-row">
        <header-buttons />
        <service-menu-button class="unavailable" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ReloadButton from '@/components/Buttons/ReloadButton.vue';
import HelpLine from '@/components/HelpLine/HelpLine.vue';
import HeaderRest from '@/components/Header/HeaderRest.vue';
import HeaderInfo from '@/components/Header/HeaderInfo.vue';
import HeaderButtons from '@/components/Header/HeaderButtons.vue';
import ServiceMenuButton from '@/components/Buttons/ServiceMenuButton.vue';
import { useMain } from "@/composable/useMain";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const { isQueueOnly } = useMain();

const headerType = computed((): string => info.value?.settings?.headerType ?? 'helpLine');
</script>

<style lang="scss" scoped>
@import "src/assets/styles/utils/sizes";

.header {
  @extend .row-container;
  justify-content: space-between;
  height: $header;
  background-color: #f4f4f4;
  z-index: 1500;
  overflow-y: hidden;

  &--queue {
    .help-line {
      margin-right: auto;
    }

    .header__info {
      flex-direction: column;
      align-items: flex-end;
      line-height: 120%;
      margin-top: 1vh;
      margin-right: 0.5rem;
    }
  }

  &__right {
    @extend .flex-col;
    justify-content: space-between;
    height: $header;
  }

  &__logo {
    height: calc(#{$header} - 1rem);
    width: 7rem;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    object-fit: contain;
  }

  &__qr {
    @extend .row-container;
    gap: 1.5rem;
    margin: auto;

    &-text {
      color: #444;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    &-icon {
      height: calc(#{$header} - 1rem);
      border: 2px solid #155599;
      border-radius: 4px;
      object-fit: contain;
    }
  }
}
</style>
