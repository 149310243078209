<template>
  <div class="header__info">
    <p>№{{ info?.description?.name }}</p>
    <p>{{ info?.description?.address }}, v{{ info?.settings?.version }}</p>
    <p>{{ time }}</p>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useDateTime } from '@/composable/useDatetime';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app.store';

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const appStore = useAppStore();
const { serverTime } = storeToRefs(appStore);
const { getDateTimeStr } = useDateTime();

const time = ref('');

watch(() => serverTime.value, () => time.value = getDateTimeStr(serverTime.value));
</script>

<style lang="scss" scoped>
.header__info {
  @extend .flex-row;
  line-height: 2rem;

  & p {
    position: relative;
    top: 2px;
    color: $medium-gray;
    margin-right: 4px;
    font-size: 14px;

    &:last-child {
      margin-left: 0.5rem;
    }
  }
}

@media (min-width: 2000px) {
  .header__info {
    line-height: 2vh;

    & p {
      font-size: 1.25vh;
    }
  }
}
</style>
