import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

import FooterButton from "@/components/Footer/FooterButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterComponent',
  props: { buttons: Array },
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["footer", `footer--btn-${__props.buttons.length}`])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.buttons, (button, key) => {
      return (_openBlock(), _createBlock(FooterButton, {
        buttonData: button,
        key: key
      }, null, 8, ["buttonData"]))
    }), 128))
  ], 2))
}
}

})