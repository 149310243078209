import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "header",
  id: "gotovo-header"
}
const _hoisted_2 = { class: "header__left" }
const _hoisted_3 = { class: "header__info" }
const _hoisted_4 = { class: "header__right" }
const _hoisted_5 = {
  key: 0,
  class: "header__info col-container"
}
const _hoisted_6 = { class: "header__info-phones" }
const _hoisted_7 = { class: "header__info-datetime" }
const _hoisted_8 = { class: "flex-col" }
const _hoisted_9 = { class: "header__info-datetime__day" }
const _hoisted_10 = { class: "header__info-datetime__date" }
const _hoisted_11 = { class: "header__info-datetime__time" }

import { computed, onMounted, ref, watch } from 'vue';
import ReloadButton from '@/components/Buttons/ReloadButton.vue';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoHeader',
  setup(__props) {

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const appStore = useAppStore();
const { language, serverTime } = storeToRefs(appStore);

const locales: { [key: string]: string } = {
  'ua': 'uk-UA',
  'ru': 'ru-RU',
  'en': 'en-US'
};

const day = ref('');
const date = ref('');
const time = ref('');
const timeout = ref(0);

const timeZone = computed(() => {
  const timeZone = info.value?.settings?.timeZone;
  return timeZone ? timeZone : 'Europe/Kiev';
});

const setValues = (dateTime: Date | string) => {
  dateTime = new Date(dateTime);

  const locale = locales[language.value];
  day.value = dateTime.toLocaleString(locale, { weekday: 'long', timeZone: timeZone.value });
  date.value = dateTime.toLocaleString(locale, { month: 'long', day: 'numeric', timeZone: timeZone.value });
  time.value = dateTime.toLocaleString(locale, { hour: 'numeric', minute: 'numeric', timeZone: timeZone.value });
};

const setDatetime = () => {
  clearTimeout(timeout.value);

  const dateTime = new Date();
  setValues(dateTime);
  timeout.value = setTimeout(setDatetime, (60 - dateTime.getSeconds()) * 1000);
};

onMounted(() => {
  if (!serverTime.value) {
    setDatetime();
  } else {
    setValues(serverTime.value);
  }
});

watch(() => serverTime.value, () => {
  if (serverTime.value) {
    setValues(serverTime.value);
  } else {
    setDatetime();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ReloadButton),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("terminal")) + " №: " + _toDisplayString(_unref(info).description.name), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("address")) + ": " + _toDisplayString(_unref(info).description.address), 1)
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "header__logo",
      src: "images/gotovo.png",
      alt: "logo"
    }, null, -1)),
    _createElementVNode("div", _hoisted_4, [
      (_unref(info)?.settings?.telephone?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("help_line_phone")) + ":", 1),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(info)?.settings?.telephone ?? [], (ph) => {
                return (_openBlock(), _createElementBlock("p", { key: ph }, "0" + _toDisplayString(ph), 1))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(day.value), 1),
          _createElementVNode("p", _hoisted_10, _toDisplayString(date.value), 1)
        ]),
        _createElementVNode("p", _hoisted_11, _toDisplayString(time.value), 1)
      ])
    ])
  ]))
}
}

})