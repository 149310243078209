import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import PopupContainer from "@/components/Popups/PopupContainer.vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";
import { useTerminalStore } from "@/store/terminal.store";
import { computed, onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickPermissionPopup',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const appStore = useAppStore();
const { isAgree } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { articles } = storeToRefs(terminalStore);

const texts = computed((): string => {
  return Object.values(articles.value).map(v => [v.description.name, v.description.subject].join('<br/>')).join('<br/>');
});

const onSubmitClick = () => {
  isAgree.value = true;
  emits('close');
};

onMounted(async () => {
  if (!Object.keys(articles.value).length) {
    await terminalStore.getTerminalArticles();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PopupContainer, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        innerHTML: texts.value,
        class: "texts"
      }, null, 8, _hoisted_1),
      _createVNode(EquickBaseButton, {
        text: _ctx.$t('equick.submit_button'),
        onClick: onSubmitClick
      }, null, 8, ["text"])
    ]),
    _: 1
  }))
}
}

})