import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "input__field-placeholder"
}
const _hoisted_2 = ["onClick"]

import { computed, ref, watch, watchEffect } from "vue";
import { DataField } from "@/types/Form";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputField',
  props: {
  currentValue: Array,
  isValid: Boolean,
  currentPosition: {
    type: Number,
    default: 0
  },
},
  emits: ['click'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);

const currentField = computed((): DataField => fields.value[currentStep.value]);

const symbols = ref([] as any);
const position = ref(0);

const handlerClick = (index: number) => emits('click', index);

const setValues = () => {
  symbols.value = props.currentValue;
  position.value = props.currentPosition;
};

setValues();

watch([() => props.currentPosition, () => props.currentValue], setValues);

watchEffect(() => {
  const nextBtn = document.getElementById('next');
  if (nextBtn) {
    if (props.isValid) {
      nextBtn.classList.remove('disabled');
    } else {
      nextBtn.classList.add('disabled');
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input__field", `input__field--${__props.isValid ? 'correct' : 'error'}`])
  }, [
    (!symbols.value?.length && currentField.value?.inputPlaceholder)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(currentField.value.inputPlaceholder), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(symbols.value, (item, key) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["input__field-text", {'current': key === position.value}]),
        key: key,
        onClick: ($event: any) => (handlerClick(key))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["input__field-text__symbol", {'space': item.value === ' ', 'dashed': !item.isMaskSymbol}])
        }, _toDisplayString(item.value), 3)
      ], 10, _hoisted_2))
    }), 128))
  ], 2))
}
}

})