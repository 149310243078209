import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageItem',
  props: {
  text: String,
  isCurrent: Boolean
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", {
    class: _normalizeClass(["languages-item", {'languages-item--selected': __props.isCurrent}])
  }, _toDisplayString(__props.text), 3))
}
}

})