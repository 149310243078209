import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tree-item__amount"
}
const _hoisted_2 = { class: "tree-item__text" }
const _hoisted_3 = {
  key: 1,
  class: "tree-item__additional"
}

import { TreeItem as TreeItemType } from "@/types/Tree";
import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeItem',
  props: {
  itemData: Object as PropType<TreeItemType>,
  size: String,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tree-item", `tree-item--${__props.size}`])
  }, [
    (__props.itemData.amount)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(__props.itemData.amount), 1))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.itemData.text), 1),
    (__props.itemData.additional)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, "(" + _toDisplayString(__props.itemData.additional) + ")", 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})