import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "results__title" }
const _hoisted_2 = { class: "results__table" }
const _hoisted_3 = { class: "results__table-list" }
const _hoisted_4 = { class: "results__table-item__label" }
const _hoisted_5 = { class: "results__table-item__value" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "footer__button-text" }
const _hoisted_9 = { class: "footer__button-text" }

import { computed, onMounted, ref } from 'vue';
import { EnteredValues, InputValue } from '@/types/Keyboard';
import { SelectedItem } from '@/types/Results';
import { useDateTime } from '@/composable/useDatetime';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickBreadcrumbs from "@/components/Equick/EquickBreadcrumbs.vue";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";
import EquickQueueConfirmPopup from "@/components/Equick/Popup/EquickQueueConfirmPopup.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueueResultsView',
  setup(__props) {

const { push } = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { enteredValues, isEditMode, currentStep, path } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, queue, error, fields, selected, addResult } = storeToRefs(terminalStore);
const { getDateMonth, getHourMinute } = useDateTime();

const isLoading = ref(true);
const isConfirmWaiting = ref(false);
const isConfirmError = ref(false);
const result = ref('');
const errorText = ref('');

const message = computed((): string => {
  return result.value === 'success' ? i18n.t('message.success') : i18n.t('message.fail');
});

const additional = computed((): string => {
  if (errorText.value) {
    return errorText.value;
  }
  if (isConfirmError.value) {
    return i18n.t('errors.time_exceeded');
  } else if (result.value === 'success' && addResult.value.ticket.num) {
    return i18n.t('result_ticket_num', { num: addResult.value.ticket.num });
  } else if (error.value && result.value === 'fail') {
    return `${ i18n.t('error') }: ${ i18n.t(error.value) }`;
  }
  return '';
});

const resultItems = computed(() => {
  const items: { label: string; key: string; value: string | number; handler?: () => void | null }[] = [];

  for (const [key, value] of Object.entries((queue.value?.timePeriod ? queue.value : selected.value) ?? {})) {
    let valueStr = value;

    if (['availableCurrent'].includes(key)) {
      continue;
    } else if (key === 'region' && items.find(item => item.key === 'regionId')) {
      continue;
    } else if (key === 'organization' && items.find(item => item.key === 'organizationId')) {
      continue;
    } else if (key === 'service' && items.find(item => item.key === 'serviceId')) {
      continue;
    }


    if (key === 'datePeriod') {
      valueStr = getDateMonth(value as string);
    } else if (key === 'timePeriod') {
      valueStr = getHourMinute(value as string);
    } else if (['regionId', 'organizationId', 'serviceId', 'servicePackageId'].includes(key)) {
      const selectedValue = (selected.value?.[key.slice(0, -2)] as SelectedItem)?.name;
      if (selectedValue) {
        valueStr = selectedValue;
      }
    } else if (key === 'current') {
      valueStr = i18n.t('queue.current');
    }

    let handler;
    if (key === 'datePeriod' || key === 'timePeriod') {
      handler = () => {
        push('queue-period');
      };
    }

    if (key === 'serviceId' || key === 'servicePackageId') {
      handler = () => {
        push('queue-tree');
      };
    }

    if (key === 'region' && (valueStr as { name?: string; })?.name) {
      valueStr = (valueStr as { name: string; }).name;
    }

    items.push({ label: i18n.t(`result.${ key }`), value: valueStr as string, key, handler });
  }

  if (fields.value?.length) {
    fields.value.map((f, i) => {
      const entered = enteredValues.value?.[f.dataFieldId];
      if (entered) {
        items.push({
          label: f.format,
          key: f.dataFieldId.toString(),
          value: entered.value.map(item => item.value).join(''),
          handler: () => {
            isEditMode.value = true;
            currentStep.value = i;
            push('queue-form');
          }
        });
      }
    });
  }

  return items;
});

const getValue = (type: string, value: InputValue[]): string => {
  if (type === 'ph') {
    const countryCode = info.value?.settings?.countryCode ?? '';
    const codeSymbols = `+ ${ countryCode } `;
    return countryCode + value.slice(codeSymbols.length).filter(item => /[0-9]/.test(item.value)).map(item => item.value).join('');
  } else {
    return value.map(item => item.value).join('');
  }
};

const addQueue = async () => {
  isLoading.value = true;

  const items: { dataFieldId: number; value: string; }[] = [];
  for (const [key, value] of Object.entries(enteredValues.value)) {
    if (value?.value) {
      items.push({ dataFieldId: +key, value: getValue(value.type, value.value) });
    }
  }

  await terminalStore.addQueue(items);
  if (addResult.value) {
    errorText.value = '';
    const { success, confirm } = addResult.value;
    if (success && !confirm) {
      result.value = addResult.value ? 'success' : 'fail';
    } else if (confirm) {
      isConfirmWaiting.value = true;
    } else {
      result.value = 'fail';
    }
  } else {
    errorText.value = error.value;
  }

  isLoading.value = false;
};

const closePopup = () => {
  if (result.value === 'success' || isConfirmError.value) {
    enteredValues.value = {} as EnteredValues;
    currentStep.value = 0;
    push('/');
  } else {
    error.value = '';
  }

  result.value = '';
  errorText.value = '';
};

const onBackClick = () => {
  const previousPath = path.value[path.value.length - 2];
  appStore.removeLastPathItem();
  push(previousPath);
};

const onMainClick = () => {
  currentStep.value = 0;
  path.value = [];
  push('/equick/queue-tree');
};

const confirmQueue = (isConfirmed: boolean) => {
  isConfirmWaiting.value = false;

  if (isConfirmed) {
    result.value = 'success';
  } else {
    isConfirmError.value = true;
  }
};

onMounted(async () => {
  path.value = ['queue-tree', 'queue-period', 'queue-form', 'queue-results'];

  await terminalStore.getTQueue();

  if (!fields.value?.length && Object.values(enteredValues.value)?.length) {
    await terminalStore.getTQueueForm();
  }

  isLoading.value = false;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", null, [
      _createVNode(EquickBreadcrumbs),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('equick.results_title')), 1),
      (!isLoading.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(resultItems.value, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.key,
                    class: "results__table-item"
                  }, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(item.label), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(item.value), 1),
                    (item.handler)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "results__table-item__edit",
                          onClick: ($event: any) => (item.handler())
                        }, null, 8, _hoisted_6))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ]),
            _createVNode(EquickBaseButton, {
              text: _ctx.$t('button.add_queue'),
              onClick: addQueue
            }, null, 8, ["text"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_7, [
          _createElementVNode("div", {
            class: "footer__button footer__button--back",
            onClick: onBackClick
          }, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('footer.button_back')), 1)
          ]),
          _createElementVNode("div", {
            class: "footer__button footer__button--home",
            onClick: onMainClick
          }, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('footer.button_main')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(EquickLoader, { key: 1 }))
      : _createCommentVNode("", true),
    (result.value || isConfirmError.value || errorText.value)
      ? (_openBlock(), _createBlock(EquickMessagePopup, {
          key: 2,
          message: message.value,
          additional: additional.value,
          onClose: closePopup
        }, null, 8, ["message", "additional"]))
      : _createCommentVNode("", true),
    (isConfirmWaiting.value)
      ? (_openBlock(), _createBlock(EquickQueueConfirmPopup, {
          key: 3,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (isConfirmWaiting.value = false)),
          onConfirm: confirmQueue
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})