import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

import { onMounted, ref } from 'vue';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import BreadcrumbsComponent from '@/components/Text/BreadcrumbsComponent.vue';
import TitleText from '@/components/Text/TitleText.vue';
import DefaultQueue from '@/components/Queue/DefaultQueue.vue';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMain } from '@/composable/useMain';
import GotovoQueue from '@/components/Queue/Gotovo/GotovoQueue.vue';
import { FooterButton } from '@/types/Footer';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueuePeriodView',
  emits: ['set-footer-buttons'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const router = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { datePeriods, error } = storeToRefs(terminalStore);
const { isGotovo } = useMain();

const isLoading = ref(true);
const errorText = ref('');

const loadDateTimeData = async () => {
  isLoading.value = true;
  await terminalStore.getTQueueDatePeriods();
  if (datePeriods.value?.length) {
    await terminalStore.setTQueueDatePeriod(datePeriods.value[0].date);
    await terminalStore.getTQueueTimePeriods();
    //setEventSource();
  } else {
    errorText.value = error.value === 'timeout' ? i18n.t('message.load_error') : error.value;
  }
  isLoading.value = false;
};

/*const setEventSource = () => {
  if (eventSource.value !== null) {
    return;
  }

  let path = '', headers = { Authorization: `Bearer ${ store.getters.token }` };
  if (process.env.VUE_APP_SERVER_TYPE === 'local') {
    path = 'http://localhost:3000/sse/status';
    //path = 'http://localhost:3001/sse/status';
  } else {
    const MAIN_API_URL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEVELOPER_SERVER_API : process.env.VUE_APP_SERVER_API;
    path = `${ MAIN_API_URL }/terminal-queue-token/sse/status`;
  }

  eventSource.value = new EventSource(path, {
    withCredentials: true,
    headers
  } as EventSourceInit);

  eventSource.value.onmessage = async function(event: any) {
    const parsedData: {
      timePeriods?: boolean;
      event?: {
        reload?: boolean;
      };
    } = JSON.parse(event.data);
    console.log(parsedData);
    if (parsedData?.timePeriods) {
      await store.dispatch('getTQueueTimePeriods');
    }

    if (parsedData?.event?.reload) {
      await store.dispatch('setToken', '');
      if (route.path === '/' || route.path === 'queue-tree') {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    }
  };
};*/

const goBack = async () => {
  errorText.value = '';
  appStore.removeLastPathItem();
  await router.push('queue-tree');
};

const setFooterButtons = (buttons: FooterButton[]) => emits('set-footer-buttons', buttons);

onMounted(async () => {
  emits('set-footer-buttons', [
    { type: 'back', backLink: 'queue-tree' },
    { type: 'main' }
  ]);

  appStore.addPathItem('queue-period');
  currentStep.value = 0;

  if (!isGotovo.value) {
    await loadDateTimeData();
  }

  isLoading.value = false;
});

/*onUnmounted(() => {
  if (eventSource.value !== null) {
    eventSource.value.close();
  }
});*/

//watch(() => results.value.datePeriod, setEventSource, { deep: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(InnerLoader, { isLoading: isLoading.value }, null, 8, ["isLoading"]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_ctx.$attrs.class)
    }, [
      (!_unref(isGotovo))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(BreadcrumbsComponent),
            _createVNode(TitleText, {
              text: _ctx.$t('queue.choose_period')
            }, null, 8, ["text"]),
            _createVNode(DefaultQueue)
          ], 64))
        : (_openBlock(), _createBlock(GotovoQueue, {
            key: 1,
            onLoadData: loadDateTimeData,
            onSetFooterButtons: setFooterButtons
          }))
    ], 2), [
      [_vShow, !isLoading.value && !_unref(error)]
    ]),
    (errorText.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 0,
          message: errorText.value,
          onClose: goBack
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})