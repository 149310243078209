<template>
  <p class="breadcrumbs">
    <span v-for="(item, key) in path" :key="key">{{ $t(`breadcrumb.${item}`) }}</span>
  </p>
</template>

<script lang="ts" setup>
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";

const appStore = useAppStore();
const { path } = storeToRefs(appStore);
</script>

<style lang="scss" scoped>
.breadcrumbs {
  @extend .text;
  margin: 0.5rem auto;
  line-height: 1.5rem;
  font-size: 1rem;

  & > span:not(:last-of-type):after {
    content: '/';
    margin: 0 0.5rem;
  }
}
</style>
