import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import InnerLoader from "@/components/Loader/InnerLoader.vue";
import ResultItems from "@/components/Results/ResultItems.vue";
import { onMounted, ref } from "vue";
import BreadcrumbsComponent from "@/components/Text/BreadcrumbsComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResultsView',
  emits: ['set-footer-buttons'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const resultItems = ref([]);
const isLoading = ref(true);

onMounted(() => {
  emits('set-footer-buttons', [{ type: 'back' }, { type: 'main' }, { type: 'next' }]);
  //this.addPathItem('Підтвердження даних');
  //this.breadcrumbs = this.path;
  /*if (this.question.forTest?.length) {
    this.resultItems = Object.keys(this.question.forTest[0].parameters).map((key, index) => {
      return {
        id: index,
        name: key,
        value: this.question.forTest[0].parameters[key]
      };
    })
  }*/
  isLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(InnerLoader, { "is-loading": isLoading.value }, null, 8, ["is-loading"]),
    _createVNode(BreadcrumbsComponent),
    _createVNode(ResultItems, { items: resultItems.value }, null, 8, ["items"])
  ]))
}
}

})