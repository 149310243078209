<template>
  <div class="queue__live">
    <queue-header :text="$t('queue.live_1')" :additional="$t('queue.live_2')"/>
    <div v-if="availableCurrent?.quantity > 0" class="queue__live-available">
      <template v-if="availableCurrent?.approximateWaitingMs && availableCurrent?.approximateWaitingMs / 60_000 > 0">
        <p class="queue__message">{{ $t('queue.approximate_time') }}:</p>
        <p class="queue__value">{{ getFormattedTime(availableCurrent?.approximateWaitingMs) }}</p>
      </template>
      <p class="queue__message">{{ $t('queue.in_queue') }}:</p>
      <p class="queue__value">{{ availableCurrent.queueLength ?? 0 }}</p>
      <queue-live-button/>
    </div>
    <div v-else-if="availableCurrent?.quantity === 0 && availableCurrent?.appearanceWaitingMs >= 0">
      <p class="queue__message">{{ $t('queue.appearance_time') }}:</p>
      <p class="queue__value">{{ getFormattedTime(availableCurrent?.appearanceWaitingMs) }}</p>
    </div>
    <div v-else class="queue__live-empty">
      <p class="queue__message">{{ $t('queue.no_near_1') }}</p>
      <p class="queue__message">{{ $t('queue.no_near_2') }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import QueueHeader from '@/components/Queue/QueueHeader.vue';
import QueueLiveButton from "@/components/Queue/QueueLiveButton.vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const i18n = useI18n();
const terminalStore = useTerminalStore();
const { availableCurrent } = storeToRefs(terminalStore);

const getFormattedTime = (value: number) => {
  const time = [];
  if (availableCurrent.value?.moreThan) {
    time.push(i18n.t('queue.more_than'));
  }

  if (value) {
    const hours = Math.floor(value / 3_600_000);
    if (hours > 0) {
      time.push(`${ hours } ${ i18n.t('units.hours') }.`);
    }
    const minutes = Math.floor(value / 60_000 - hours * 3_600_000);
    if (minutes > 0) {
      time.push(`${ minutes } ${ i18n.t('units.minutes') }.`);
    }
  }

  return time.join(' ');
}
</script>

<style lang="scss" scoped>
.queue__live {
  @extend .flex-col;
  flex: 1;
  min-width: 28vw;
  padding: 0 1.25rem 0 0;
  border-right: 2px solid $border-gray;

  & .queue__message {
    max-width: 90%;
  }

  &-empty {
    @extend .flex-col;
    justify-content: flex-start;
    height: 100%;
    padding-top: 1.5rem;
  }

  &-available {
    margin-top: 0.5rem;
  }
}

.queue__message {
  @extend .text-center, .font-size-l;
  margin: 0.625rem auto;
  color: $light-gray;

  & + .queue__message {
    margin-top: 2rem;
  }
}

.queue__value {
  @extend .font-size-l, .bold, .text-center;
  margin-bottom: 2rem;
  color: $blue;
}
</style>
