<template>
  <div class="queue__times">
    <queue-time v-for="(item, key) in timePeriods" :key="key" :text="getHourMinute(item.a)" :count="item.count"
                @click="onClick(item.a)" />
  </div>
  <message-popup v-if="error" :message="error" @close="error = ''" />
</template>

<script lang="ts" setup>
import QueueTime from "@/components/Queue/QueueTime.vue";
import { ref } from "vue";
import { useDateTime } from "@/composable/useDatetime";
import { useRouter } from "vue-router";
import MessagePopup from "@/components/Popups/MessagePopup.vue";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";

defineProps({ items: Array });

const router = useRouter();
const terminalStore = useTerminalStore();
const { timePeriods, results } = storeToRefs(terminalStore);
const { getHourMinute } = useDateTime();

const error = ref('');

const onClick = async (time: string) => {
  await terminalStore.setTQueueTimePeriod(time);
  if (results.value?.timePeriod) {
    await router.push('queue-form');
  } else {
    error.value = `Сталася помилка при виборі часу ${ getHourMinute(time) }`;
  }
};
</script>

<style lang="scss" scoped>
.queue__times {
  @extend .flex-row, .vertical-scroll;
  flex-wrap: wrap;
  padding-top: 0.5rem;
}
</style>
