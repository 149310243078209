import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "queue-period" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "footer__button-text" }
const _hoisted_4 = { class: "footer__button-text" }

import EquickBreadcrumbs from "@/components/Equick/EquickBreadcrumbs.vue";
import { computed, onMounted, ref } from "vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useTerminalStore } from "@/store/terminal.store";
import { useI18n } from "vue-i18n";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import EquickQueuePeriodCurrent from "@/components/Equick/QueuePeriod/EquickQueuePeriodCurrent.vue";
import EquickQueuePeriodFixed from "@/components/Equick/QueuePeriod/EquickQueuePeriodFixed.vue";
import moment from "moment";
import EquickQueueLogos from "@/components/Equick/QueuePeriod/EquickQueueLogos.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueuePeriodView',
  setup(__props) {

const i18n = useI18n();
const { push } = useRouter();
const appStore = useAppStore();
const { currentStep, screenOrientation, path } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, datePeriods, error, availableCurrent, results, addResult } = storeToRefs(terminalStore);

const queueType = ref('current');
const isLoading = ref(true);
const isTimeLoading = ref(false);
const fixedData = ref<{ date: string; time: string; }>({} as { date: string; time: string; });
const errorText = ref('');

const isCurrentAvailable = computed((): boolean => {
  return !!(availableCurrent.value?.quantity > 0
      || availableCurrent.value?.quantity === 0 && availableCurrent.value?.appearanceWaitingMs && availableCurrent.value.appearanceWaitingMs >= 0);
});

const loadDateTimeData = async () => {
  isLoading.value = true;
  await terminalStore.getTQueueDatePeriods();
  if (datePeriods.value?.length) {
    await terminalStore.setTQueueDatePeriod(datePeriods.value[0].date);
    await terminalStore.getTQueueTimePeriods();
  } else {
    errorText.value = error.value === 'timeout' ? i18n.t('message.load_error') : error.value;
  }
  isLoading.value = false;
};

const onBackClick = () => {
  if (queueType.value === 'fixed' && isCurrentAvailable.value) {
    queueType.value = 'current';
  } else {
    goBack();
  }
};

const onNextClick = async () => {
  isTimeLoading.value = true;
  await terminalStore.setTQueueTimePeriod(fixedData.value.time);
  isTimeLoading.value = false;
  if (results.value?.timePeriod) {
    await push('/equick/queue-form');
  } else {
    errorText.value = error.value === 'timeout'
        ? i18n.t('message.queue_time_error', { time: moment(fixedData.value.time).format('HH:mm') })
        : error.value;
  }
};

const onRegisterClick = async () => {
  isLoading.value = true;
  await terminalStore.addLiveQueue();
  isLoading.value = false;
  if (addResult.value?.current) {
    await push('queue-form');
  } else {
    errorText.value = error.value === 'timeout' ? i18n.t('message.info_error') : error.value;
  }
};

const goBack = () => {
  errorText.value = '';
  //appStore.removeLastPathItem();
  push('/equick/queue-tree');
};

onMounted(async () => {
  //appStore.addPathItem('queue-period');
  currentStep.value = 0;
  path.value = ['queue-tree', 'queue-period'];

  await loadDateTimeData();

  queueType.value = info.value?.settings?.currentQueue && isCurrentAvailable.value ? 'current' : 'fixed';
  isLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      (_unref(screenOrientation) === 'portrait')
        ? (_openBlock(), _createBlock(EquickQueueLogos, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(EquickBreadcrumbs),
      (!isLoading.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (queueType.value === 'current')
              ? (_openBlock(), _createBlock(EquickQueuePeriodCurrent, {
                  key: 0,
                  onSetQueueType: _cache[0] || (_cache[0] = v => queueType.value = v),
                  onRegisterCurrent: onRegisterClick
                }))
              : (_openBlock(), _createBlock(EquickQueuePeriodFixed, {
                  key: 1,
                  onSetFixedData: _cache[1] || (_cache[1] = v => fixedData.value = v)
                }))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_2, [
          _createElementVNode("div", {
            class: "footer__button footer__button--back",
            onClick: onBackClick
          }, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('footer.button_back')), 1)
          ]),
          (queueType.value === 'fixed')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["footer__button footer__button--next", {'disabled': !fixedData.value.date || !fixedData.value.time}]),
                onClick: onNextClick
              }, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('footer.button_continue')), 1)
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (isLoading.value || isTimeLoading.value)
      ? (_openBlock(), _createBlock(EquickLoader, { key: 1 }))
      : _createCommentVNode("", true),
    (errorText.value)
      ? (_openBlock(), _createBlock(EquickMessagePopup, {
          key: 2,
          message: errorText.value,
          onClose: goBack
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})