import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main__top" }
const _hoisted_2 = { class: "main__top-left" }
const _hoisted_3 = { class: "main__top-left__text" }
const _hoisted_4 = { class: "main__top-right" }

import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import { Service } from "@/types/Service";
import { ServicePackage } from "@/types/ServicePackage";
import EquickLogo from "@/components/Equick/EquickLogo.vue";
import EquickImage from "@/components/Equick/EquickImage.vue";
import EquickSearchInput from "@/components/Equick/Input/EquickSearchInput.vue";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import EquickServiceList from "@/components/Equick/EquickServiceList.vue";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueueTreeView',
  setup(__props) {

const { push } = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { path, currentGroup, pageIndent, isKeyboardShown } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { results, error, info } = storeToRefs(terminalStore);

const isLoading = ref(true);
const serviceError = ref('');
const loadError = ref('');
const searchValue = ref('');
const searchKey = ref(0);

const onItemClick = async (item: Service | ServicePackage) => {
  isLoading.value = true;

  if (item.type === 'service') {
    await terminalStore.setTQueueService(item.id);
  } else {
    await terminalStore.setTQueueServicePackage(item.id);
  }

  isLoading.value = false;

  if (results.value[item.type === 'service' ? 'serviceId' : 'servicePackageId']) {
    await push('/equick/queue-period');
  } else {
    serviceError.value = i18n.t('message.queue_service_error', { name: item.description.name });
  }
};

onMounted(async () => {
  pageIndent.value = 0;
  currentGroup.value = '';
  path.value = ['queue-tree'];

  if (info.value?.settings?.isResource) {
    await terminalStore.getResources();
  } else {
    await terminalStore.getTQueueServices();
    await terminalStore.getTQueueServicePackages();
  }

  if (error.value) {
    loadError.value = error.value === 'timeout' ? i18n.t('message.load_error') : error.value;
  }

  isLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(EquickLogo, {
          place: "main",
          class: "main__top-left__logo"
        }),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('equick.main_title')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(EquickImage)
      ])
    ]),
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(), _createBlock(EquickSearchInput, {
            key: searchKey.value,
            onSearch: _cache[0] || (_cache[0] = v => searchValue.value = v)
          })),
          _withDirectives(_createVNode(EquickServiceList, {
            "search-value": searchValue.value,
            onItemClick: onItemClick,
            onClearSearch: _cache[1] || (_cache[1] = ($event: any) => {searchValue.value = ''; searchKey.value++})
          }, null, 8, ["search-value"]), [
            [_vShow, !_unref(isKeyboardShown)]
          ])
        ], 64))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(EquickLoader, { key: 1 }))
      : _createCommentVNode("", true),
    (serviceError.value)
      ? (_openBlock(), _createBlock(EquickMessagePopup, {
          key: 2,
          message: serviceError.value,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (serviceError.value = ''))
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ]))
}
}

})