<template>
  <svg :width="width" height="4" :viewBox="`0 0 ${width} 4`" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :width="width" height="4" rx="2" :fill="color" stroke-linecap="round"/>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  width: Number,
  isPassed: Boolean,
});

const color = computed((): string => props.isPassed ? '#00B2E0' : '#CCCCCC80');
</script>

<style lang="scss" scoped>

</style>