<template>
  <section class="main__input" :class="{'main__input--error': !isValid}">
    <div class="main__field">
      <!--      <div class="main__field-button main__field-button&#45;&#45;prev" @click="$emit('back')"></div>-->
      <div class="main__field-input">
        <p v-if="!currentValue.length" class="main__field-input__placeholder">
          {{ field.inputPlaceholder ? field.inputPlaceholder : $t('equick.input_placeholder') }}
        </p>
        <div class="main__field-value">
          <p v-for="(item, key) in currentValue" :key="key" class="main__field-value__item"
             :class="{'current': key === currentPosition}" @click="currentPosition = key">
            {{ item.value }}
          </p>
        </div>
      </div>
      <div class="main__field-button main__field-button--clear" :class="{'inactive': !currentValue.length}"
           @click="clearValue(false)"></div>
      <!--      <div class="main__field-button main__field-button&#45;&#45;next" :class="{'disabled': isNextDisabled}"
                 @click="$emit('next')"></div>-->
    </div>
    <equick-field-hints v-if="field?.type === 't'" class="main__hints" :items="hints" :value="currentValue"
                        @set-value="setHintValue" />
    <equick-field-permission v-if="field?.hint === 'firstName'" />
    <equick-keyboard :key="currentKeyboardType" :keyboard-type="currentKeyboardType" @key-click="onKeyClick" />
  </section>
</template>

<script setup lang="ts">
import { PropType, watch } from "vue";
import EquickKeyboard from "@/components/Equick/Keyboard/EquickKeyboard.vue";
import { useInput } from "@/composable/useInput";
import { DataField } from "@/types/Form";
import { InputValue } from "@/types/Keyboard";
import EquickFieldHints from "@/components/Equick/Input/EquickFieldHints.vue";
import EquickFieldPermission from "@/components/Equick/Input/EquickFieldPermission.vue";

const props = defineProps({
  modelValue: Boolean,
  field: {
    type: Object as PropType<DataField>,
    default: () => ({})
  },
  initial: {
    type: Array as PropType<InputValue[]>,
    default: () => []
  },
  mask: String,
  initialKeyboardType: String,
});
const emits = defineEmits(['back', 'next', 'update:modelValue']);

const {
  currentValue, hints, isValid, currentPosition, currentKeyboardType,
  onKeyClick, clearValue, setHintValue,
} = useInput({
  initialValue: props.initial,
  isKeyboardShownByDefault: true,
  keyboardDefaultType: props.initialKeyboardType ?? 'ru',
  maxLength: 1000,
  mask: props.mask ?? '',
});

watch(() => currentValue.value, () => emits('update:modelValue', currentValue.value), { deep: true });
</script>

<style scoped lang="scss">
.main {
  &__input {
    @extend .flex-col;
    height: 100%;
    //height: calc(100% - 8rem);

    &--error {
      .main__field-input {
        border-color: red;
      }
    }
  }

  &__field {
    @extend .flex-row;
    gap: $--16px;

    &:not(:has(+ .field__hints)) {
      margin-bottom: auto;
    }

    &--active {
      .main__field-input {
        border-color: $equick-primary;
      }
    }

    &-input {
      @extend .row-container, .shadow, .pointer, .smooth-change;
      position: relative;
      width: 100%;
      height: rems(72);
      padding: $--16px;
      background-color: white;
      border: 1.5px solid white;
      border-radius: 15px;

      &__placeholder {
        position: absolute;
        @include font($--24px, 120%, #585858);
      }
    }

    &-value {
      @extend .flex-row;
      @include font($--24px, 120%, black, 500);

      &__item {
        padding: 0 2px;
        border-right: 1.5px solid transparent;

        &:first-child {
          padding-left: 0;
        }

        &.current {
          border-right: 1.5px solid black;
        }
      }
    }

    &-button {
      &--clear {
        @include iconButton($equick-close);

        &.inactive:before {
          filter: grayscale(1);
        }
      }

      &--prev, &--next {
        &, &:before {
          width: rems(60) !important;
          min-width: rems(60) !important;
        }
      }

      &--prev {
        @include iconButton($equick-arrow);
      }

      &--next {
        @include iconButton($equick-arrow);

        &:before {
          transform: rotate(0.5turn);
        }
      }
    }
  }
}
</style>
