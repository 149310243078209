import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "tree-item__quantity"
}
const _hoisted_3 = { class: "tree-item__quantity-amount" }
const _hoisted_4 = { class: "tree-item__text" }
const _hoisted_5 = {
  key: 0,
  class: "tree-item__additional"
}
const _hoisted_6 = {
  key: 1,
  class: "tree-item__additional"
}

import { computed, PropType } from "vue";
import { Service } from "@/types/Service";
import { ServicePackage } from "@/types/ServicePackage";
import { useMain } from "@/composable/useMain";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueTreeItem',
  props: {
  itemData: {
    type: Object as PropType<Service | ServicePackage>,
    default: () => ({})
  },
  size: String,
},
  setup(__props) {

const props = __props;

const i18n = useI18n();
const { isGotovo } = useMain();
const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

const name = computed((): string => props.itemData?.description?.name ?? `id: ${ props.itemData.id }`);

const duration = computed((): string => {
  const duration = props.itemData?.type === 'service'
      ? props.itemData.duration
      : props.itemData?.items?.reduce((prev, next) => {
        return prev + next.duration
      }, 0);
  return duration ? getDurationStr(duration) : '';
});

const getDurationStr = (duration: number): string => {
  const result: string[] = [];
  const hours = Math.floor(duration / 3600);
  if (hours > 0) {
    result.push(`${ hours } ${i18n.t('units.hours')}`);
    duration -= hours * 3600;
  }

  const minutes = Math.floor(duration / 60);
  if (minutes > 0) {
    result.push(`${ minutes } ${i18n.t('units.minutes')}`);
    duration -= minutes * 60;
  }

  if (duration > 0) {
    result.push(`${ duration } ${i18n.t('units.seconds')}`);
  }

  return result.join(' ');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tree-item", `tree-item--${__props.size}`])
  }, [
    (_unref(isGotovo))
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          alt: "icon",
          src: `/media/${__props.itemData.iconPath}`,
          class: "tree-item__icon",
          onerror: "this.onerror = null; this.src = '/media/dmsu/default.svg'"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_unref(isGotovo) && __props.itemData.availableCurrent && _unref(info)?.settings?.isCountService)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(__props.itemData.availableCurrent.quantity ?? 0), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_4, _toDisplayString(name.value), 1),
    (!_unref(isGotovo))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (duration.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('duration')) + ": " + _toDisplayString(duration.value), 1))
            : (__props.itemData.description?.caption)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(__props.itemData.description.caption), 1))
              : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}
}

})