<template>
  <popup-container class="message" :message="$t('attention')" @close="onButtonClick">
    <div class="content">
      <p>Для завершення реєстрації в черзі зателефонуйте, будь ласка, з номера</p>
      <p class="text--phone">{{ getPhone(clientPhone) }}</p>
      <p>на один з номерів зі списку:</p>
      <p v-for="phone in phoneCallCenterList" :key="phone" class="text--phone">{{ getPhone(phone) }}</p>
      <p>Час для підтвердження</p>
      <p class="text--time">{{ waitTime }}</p>
    </div>
    <default-button :text="$t('button.cancel')" @click="onButtonClick" />
  </popup-container>
</template>

<script lang="ts" setup>
import PopupContainer from '@/components/Popups/PopupContainer.vue';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const emits = defineEmits(['close', 'confirm']);

const terminalStore = useTerminalStore();
const { addResult, checkResult } = storeToRefs(terminalStore);

const waitTime = ref('--:--');
const checkTimer = ref(0);
const waitingTimer = ref(0);
const waitingTextInterval = ref(0);

const delay = computed((): number => addResult.value?.confirmData?.delay ?? 0);
const clientPhone = computed((): string => addResult.value?.confirmData?.phoneClient ?? '-');
const phoneCallCenterList = computed((): string[] => addResult.value?.confirmData?.phoneCallCenterList ?? []);

const getPhone = (phone: string) => {
  const phoneParts = [
    `${ phone.slice(0, 4) } (${ phone.slice(4, 6) })`,
    phone.slice(6, 9),
    phone.slice(9, 11),
    phone.slice(11)
  ];
  return phoneParts.join(' ');
};

const getTimeText = (time: number) => {
  const parts = [`0${ Math.floor(time / 60) }`.slice(-2), `0${ time % 60 }`.slice(-2)];
  return parts.join(':');
};

const checkQueue = () => {
  checkTimer.value = setTimeout(async () => {
    await terminalStore.checkQueue();
    if (checkResult.value?.success) {
      emits('confirm', true);
      onButtonClick();
    } else {
      checkQueue();
    }
  }, 5000);
};

const setTextTimer = () => {
  let remainTime = delay.value;
  waitTime.value = getTimeText(remainTime);

  waitingTextInterval.value = setInterval(() => {
    remainTime--;
    waitTime.value = getTimeText(remainTime);

    if (remainTime <= 0) {
      clearInterval(waitingTextInterval.value);
    }
  }, 1000);
};

const setWaitTimer = () => {
  setTimeout(() => {
    emits('confirm', false);
    onButtonClick();
  }, delay.value * 1000);
};

const onButtonClick = () => {
  clearTimeout(waitingTimer.value);
  clearTimeout(checkTimer.value);
  clearInterval(waitingTextInterval.value);
  emits('close');
};

onMounted(() => {
  clearTimeout(checkTimer.value);
  checkQueue();
  setTextTimer();
  setWaitTimer();
});
</script>

<style lang="scss" scoped>
.message {
  :deep(.popup-area) {
    max-width: 50vw;
    min-width: 40vw;
    padding-top: 4rem;
  }
}

.content {
  @extend .col-container;
  width: max-content;
  margin-bottom: 1.5rem;

  p {
    max-width: 52vw;
    text-align: center;
    text-wrap: balance;
    font-size: 20px;
    color: #888;

    &:not(.text--phone) + .text--phone {
      margin-top: 0.5rem;
    }

    &.text--phone, &.text--time {
      font-weight: 700;
      font-size: 24px;
      color: #666;

      + p:not(.text--phone) {
        margin-top: 0.5rem;
      }
    }

    &.text--time {
      margin: 1rem 0 0;
      font-size: 40px;
    }
  }
}

.gotovo .message {
  .default-button {
    min-width: 4rem;
    background-color: $yellow;

    :deep(.default-button__text) {
      color: #333 !important;
    }
  }
}
</style>
