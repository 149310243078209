<template>
  <div class="queue__dates">
    <queue-date v-for="(item, key) in datePeriods" :key="key" :text="getDateMonth(item.date)" :count="item.count"
                :isSelected="item.date === selected" :day="getWeekday(item.date)" @click="onClick(item.date)"/>
    <p v-if="!datePeriods?.length" class="queue__dates-message">{{ $t('queue.no_dates') }}</p>
  </div>
</template>

<script lang="ts" setup>
import QueueDate from "@/components/Queue/QueueDate.vue";
import { useDateTime } from "@/composable/useDatetime";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

defineProps({
  selected: String
});
const emits = defineEmits(['set-date']);

const terminalStore = useTerminalStore();
const { datePeriods } = storeToRefs(terminalStore);
const { getDateMonth, getWeekday } = useDateTime();

const onClick = (date: string) => emits('set-date', date);
</script>

<style lang="scss" scoped>
.queue__dates {
  @extend .flex-row, .horizontal-scroll;
  padding-top: 0.5rem;

  &-message {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    color: #666;
  }
}
</style>
