import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
//import store, {storeKey} from './store';
import i18n from './i18n';
import { createPinia } from "pinia";
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

/*const app = createApp(App);
app.provide(storeKey, store).use(i18n).use(router).mount('#app');*/
createApp(App).use(pinia).use(i18n).use(router).mount('#app');
