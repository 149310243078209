<template>
  <div class="queue__datetime">
    <p class="queue__datetime-title">{{ $t("choose_time") }}</p>
    <div class="queue__datetime-panel">
      <gotovo-queue-dates :selected="selectedDate" @set-date="setSelectedDate" />
      <gotovo-queue-times :selected="selectedTime" :is-loading="isLoading" @set-time="setSelectedTime" />
    </div>
  </div>
  <p class="queue__datetime-button" :class="{disabled: !selectedDate || !selectedTime}" @click="onPrintClick">
    {{ $t("button.print") }}
  </p>
  <message-popup v-if="error" :message="error" @close="error = ''" />
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import { useDateTime } from '@/composable/useDatetime';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import GotovoQueueDates from '@/components/Queue/Gotovo/GotovoQueueDates.vue';
import GotovoQueueTimes from '@/components/Queue/Gotovo/GotovoQueueTimes.vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const emits = defineEmits(['print']);

const terminalStore = useTerminalStore();
const { datePeriods, results } = storeToRefs(terminalStore);
const { getDateMonth } = useDateTime();

const selectedDate = ref('');
const selectedTime = ref('');
const isLoading = ref(false);
const error = ref('');

const setSelectedDate = async (date: string) => {
  const prevSelected = selectedDate.value;
  selectedDate.value = date;
  selectedTime.value = '';
  isLoading.value = true;
  await terminalStore.setTQueueDatePeriod(date);
  if (results.value?.datePeriod) {
    await terminalStore.getTQueueTimePeriods();
  } else {
    selectedDate.value = prevSelected;
    error.value = `Не вдалось завантажити вільний час для дати ${ getDateMonth(date) }`;
  }
  isLoading.value = false;
};

const setSelectedTime = async (time: string) => {
  selectedTime.value = time;
  await terminalStore.setTQueueTimePeriod(time);
};

const onPrintClick = () => emits('print');

watchEffect(() => selectedDate.value = datePeriods.value[0]?.date);
</script>

<style lang="scss" scoped>
.queue__datetime {
  @extend .col-container;

  &-title {
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
  }

  &-panel {
    @extend .flex-row;
    justify-content: center;
    width: 100%;
  }

  &-button {
    @extend .pointer;
    width: max-content;
    margin: 1vh auto auto;
    padding: 3vh 4vw;
    font-size: 4vh;
    background-color: $yellow;
    border-radius: 6px;

    &.disabled {
      pointer-events: none;
      filter: brightness(0.9);
    }
  }
}

@media (min-height: 1000px) and (min-width: 1400px) {
  .queue__datetime-button {
    margin: 2rem auto auto;
    padding: 2rem 6rem;
    font-size: 2.5rem;
  }
}
</style>
