<template>
  <div class="queue__live-btn" @click="onClick">
    <p class="queue__live-btn__text">{{ $t('button.nearest') }} ({{ availableCurrent.quantity }})</p>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";

const router = useRouter();
const terminalStore = useTerminalStore();
const { availableCurrent, addResult } = storeToRefs(terminalStore);

const onClick = async () => {
  await terminalStore.addLiveQueue();
  if (addResult.value?.current) {
    await router.push('queue-form');
  }
}
</script>

<style lang="scss" scoped>
.queue__live-btn {
  position: relative;
  margin: 2.5rem 0;
  background-color: $blue;
  border-radius: 16px;

  &__text {
    @extend .text-center, .upper, .font-size-default;
    line-height: 3.125rem;
    color: white;
  }
}

.badge {
  top: -4px;
  right: -4px;
}
</style>
