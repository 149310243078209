import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

import { eng } from '@/assets/keys/eng';
import { rus } from '@/assets/keys/rus';
import { ukr } from '@/assets/keys/ukr';
import { num } from '@/assets/keys/num';
import { onlyNum } from '@/assets/keys/only-num';
import KeyComponent from '@/components/Keyboard/KeyComponent.vue';
import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { Key } from '@/types/Keyboard';
import { useMain } from '@/composable/useMain';


export default /*@__PURE__*/_defineComponent({
  __name: 'KeyboardComponent',
  props: {
  keyboardType: {
    type: String,
    default: ''
  }
},
  emits: ['key-click'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const { isGotovo } = useMain();

const keys = ref<Key[][]>([]);

const keysSet: { [key: string]: Key[][] } = {
  ua: ukr,
  ru: rus,
  en: eng,
  numbers: num,
  onlyNum: onlyNum
};

const KeyActions: { [key: string]: string } = {
  Backspace: 'backspace',
  ArrowLeft: 'backward',
  ArrowRight: 'forward'
};

const size = computed((): string => ['num', 'onlyNum'].includes(props.keyboardType) ? 's' : 'm');

const keySize = computed((): number => {
  const maxRowItemsAmount = 12;
  const maxWidth = document.documentElement.clientWidth - 60 * 2 - 10 * (maxRowItemsAmount - 1);
  const maxItemWidth = Math.floor(maxWidth / maxRowItemsAmount);

  let maxHeight = document.documentElement.clientHeight - 28 * 16 - 24;
  if (isGotovo.value && document.documentElement.clientWidth > 1400 && document.documentElement.clientHeight > 1000) {
    maxHeight -= document.documentElement.clientHeight * 0.2;
  }
  const maxItemHeight = Math.floor(maxHeight / keysSet[props.keyboardType].length);

  return Math.min(maxItemWidth, maxItemHeight);
});

const symbols = computed((): string => {
  let symbols = '';
  keys.value?.map(set => {
    set?.map(item => {
      symbols += item.symbol && !item.action ? item.symbol : '';
    });
  });

  return symbols.toLowerCase();
});

const onClick = (key: Key) => emits('key-click', key);

const findKeyByValue = (set: Key[][], value: string, param = 'symbol' as 'symbol' | 'action'): Key | undefined => {
  for (const item of set) {
    for (const key of item) {
      if (key[param]?.toLowerCase() === value.toLowerCase()) {
        return key;
      }
    }
  }

  return undefined;
};

const onKeyboardBtnClick = (e: Event) => {
  const key = (e as KeyboardEvent).key;
  let item: Key | undefined = undefined;
  if (/\d/.test(key)) {
    item = findKeyByValue(num, key);
  } else if (symbols.value.includes(key)) {
    item = findKeyByValue(keys.value, key);
  } else if (['\'', '"', '`'].includes(key)) {
    item = findKeyByValue(keys.value, '‘');
  } else if (Object.keys(KeyActions).includes(key)) {
    item = findKeyByValue(keys.value, KeyActions[key], 'action');
  }

  item && onClick(item);
};

onMounted(() => document.documentElement.addEventListener('keydown', onKeyboardBtnClick));

onUnmounted(() => document.documentElement.removeEventListener('keydown', onKeyboardBtnClick));

watchEffect(() => keys.value = keysSet[props.keyboardType]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["keyboard", `keyboard--${size.value}`])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(keys.value, (row, rowKey) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "keyboard__row",
        key: rowKey,
        style: _normalizeStyle({height: `${keySize.value}px`})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (item, itemKey) => {
          return (_openBlock(), _createBlock(KeyComponent, {
            key: itemKey,
            itemData: item,
            size: keySize.value,
            onClick: ($event: any) => (onClick(item))
          }, null, 8, ["itemData", "size", "onClick"]))
        }), 128))
      ], 4))
    }), 128))
  ], 2))
}
}

})