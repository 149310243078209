<template>
  <popup-container class="message" :message="$t('message.success')" @close="onCloseClick">
    <div class="info">
      <template v-if="addResult?.print?.organization">
        <p class="info__organization-name">{{ addResult.print.organization.description.name }}</p>
        <p class="info__organization-address">{{ addResult.print.organization.description.address }}</p>
      </template>
      <template v-if="addResult?.print?.service">
        <p class="info__service-name">{{ addResult.print.service.description.name }}</p>
      </template>
      <template v-if="addResult?.print?.ticket">
        <p class="info__ticket-text">{{ $t("ticket_num") }}:</p>
        <p class="info__ticket-num">{{ addResult.print.ticket.num }}</p>
        <p class="info__ticket-time">{{ addResult.print.ticket.plannedTime }}</p>
      </template>
    </div>
    <default-button :text="$t('button.ok')" @click="onCloseClick" />
  </popup-container>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import PopupContainer from '@/components/Popups/PopupContainer.vue';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const router = useRouter();
const terminalStore = useTerminalStore();
const { addResult } = storeToRefs(terminalStore);

const timeout = ref(0);

const onCloseClick = () => {
  clearTimeout(timeout.value);
  router.push('/');
};

onMounted(() => timeout.value = setTimeout(onCloseClick, 10000));
</script>

<style lang="scss" scoped>
.info {
  @extend .col-container;
  margin-bottom: 1.5rem;

  p {
    color: #333;
  }

  &__organization {
    &-name {
      margin-top: -0.5rem;
      margin-bottom: 2px;
      font-size: 20px;
      font-weight: 600;
    }

    &-address {
      margin-bottom: 1rem;
      font-size: 14px;
      color: #666 !important;
    }
  }

  &__service-name {
    font-size: 20px;
    color: #444;
  }

  &__ticket {
    &-text {
      margin: 1rem 0 0.5rem;
      font-size: 16px;
    }

    &-num {
      margin-bottom: 1rem;
      font-size: 60px;
      font-weight: 700;
    }

    &-time {
      color: #555 !important;
      font-size: 18px;
    }
  }
}
</style>
