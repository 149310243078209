export const ru = {
  title: 'Терминал',
  account: 'Счет',
  uah: '.-',
  helpline: 'Мы всегда рядом',
  helpline_2: ', звоните',
  search: 'Поиск',
  loading: 'Загрузка',
  timer_question: 'Вы еще здесь?',
  duration: 'Длительность',
  welcome_title: 'Добро пожаловать в терминал самообслуживания!',
  welcome_subtitle: 'Пожалуйста, выберите услугу',
  choose_service: 'Выберите услугу',
  error: 'Ошибка',
  result_ticket_num: 'Номер Вашего талона: {num}',
  ticket_num: 'Номер талона',
  terminal: 'Терминал',
  address: 'Адрес',
  help_line_phone: 'Телефон горячей линии',
  approximate_at: 'приблизительно в',
  confirmation: 'Подтверджение',
  attention: 'Внимание!',
  choose_time: 'Выбор времени',
  free_time: 'Свободное время',
  days: ['Пн', 'Вт',' Ср', 'Чт', 'Пт',' Сб', 'Вс'],
  header: {
    info_button: 'Согласие на обработку персональных данных',
    rates_button: 'Информация и тарифы',
    qr_text_1: 'Стабильность, эффективность и прозрачность в каждом процессе!',
    qr_text_2: 'Узнайте больше о нашем сервисе электронной очереди'
  },
  footer: {
    button_back: 'Назад',
    button_main: 'Главная',
    button_next: 'Далее',
    button_continue: 'Продолжить',
  },
  permission: {
    part_1: 'Предоставляю согласие на сбор, обработку и использование указанных мной персональных данных.',
    part_2: 'С правами субъекта персональных данных ознакомлен(на).',
  },
  button: {
    ok: 'Ок',
    nearest: 'Ближайшее время',
    edit: 'Редактировать',
    add_queue: 'Записаться',
    yes: 'Да',
    reload: 'Перезагрузить',
    to_main: 'На главную',
    permission: 'Согласие на обработку персональных данных',
    submit_queue_live: 'Подтвердить ближайшее время',
    cancel: 'Отменить',
    submit_time: 'Подтвердить время',
    other_time: 'Выбрать другое время',
    close: 'Закрыть',
    print: 'Распечатать талон',
  },
  queue: {
    available_time: 'Доступное время записи на',
    no_available_time: 'Нету доступного времени для записи на',
    fixed: 'На другую дату и время',
    live_1: 'Живая очередь',
    live_2: '(на сегодня)',
    no_near_1: 'На данный момент, талоны в текущую очередь отсутствуют',
    no_near_2: 'Пожалуйста выберете удобное для Вас время и дату',
    choose_period: 'Пожалуйста выберите вариант постановления в очередь',
    no_dates: 'Нету доступных дат',
    approximate_time: 'Приблизительное время ожидания',
    appearance_time: 'Ожидается появление талонов через',
    in_queue: 'Людей в очереди на выбранную услугу',
    current: 'Текущая',
    more_than: 'Более, чем',
    live_gotovo_1: 'Время указано ориентировочно!',
    live_gotovo_2: 'Не уходите с подразделения, Вас могут вызвать раньше.',
    live_gotovo_3: 'Следите за очередью на табло.',
    live_gotovo_4: 'Если Вас не устраивает предложенное время, Вы можете выбрать другое время и дату',
    ticket_amount: 'Талонов на сегодня',
    free: 'Свободных',
    confirm_title: 'Для завершения регистрации в очереди позвоните, пожалуйста, с номера',
    confirm_phones: 'на один из номеров из списка:',
    confirm_time: 'Время для подтверждения',
  },
  message: {
    success: 'Успех',
    fail: 'Ошибка',
    queue_service_error: 'Сейчас невозможно записаться в очередь на услугу {name}',
    queue_time_error: 'Не удалось записаться на время {time}',
    load_error: 'Не удалось загрузить данные',
    server_error: 'Потеряна связь с сервером',
    info_error: 'Что-то пошло не так... Попробуйте обратиться позже'
  },
  breadcrumb: {
    'queue-period': 'Дата и время',
    'queue-form': 'Введение данных',
    'queue-results': 'Результаты',
    'queue-tree': 'Выбор услуги',
  },
  result: {
    datePeriod: 'Дата',
    organizationId: 'Организация',
    regionId: 'Регион',
    region: 'Регион',
    serviceId: 'Услуга',
    servicePackageId: 'Пакет услуг',
    timePeriod: 'Время',
    current: 'Тип очереди'
  },
  errors: {
    ERROR_LOGIN: 'Ошибка авторизации',
    ERROR_NO_SSE: 'SSE не доступен',
    ERROR_REGION_SELECT: 'Не выбран регион',
    ERROR_NO_REGION: 'Нет регионов',
    ERROR_ORGANIZATION_SELECT: 'Не выбрана организация',
    ERROR_NO_ORGANIZATION: 'Нет организаций',
    ERROR_NO_SERVICE_PACKAGE: 'Нет пакетов услуг',
    ERROR_NO_SERVICE_PACKAGE_ITEM: 'Нет компонентов пакетов услуг',
    ERROR_SERVICE_PACKAGE_NOT_AVAILABLE: 'Пакет услуг не доступен',
    ERROR_SERVICE_SELECT: 'Не выбрана услуга',
    ERROR_NO_SERVICE: 'Нет услуг',
    ERROR_DATE_SELECT: 'Не выбрана дата',
    ERROR_TIME_RESERVED: 'Время зарезервировано',
    ERROR_TIME_SELECT: 'Не выбрано время',
    ERROR_NO_CLIENT: 'Нет клиентов',
    ERROR_CLIENT_SELECT: 'Не выбран клиент',
    ERROR_NO_OPERATOR: 'Нет операторов',
    ERROR_OPERATOR_SELECT: 'Не выбран оператор',
    ERROR_NO_SCHEDULE: 'Нет расписания',
    ERROR_SCHEDULE_SELECT: 'Не выбрано расписание',
    ERROR_NO_SIGNATURE: 'Нет сигнатуры',
    ERROR_SIGNATURE_SELECT: 'Не выбрана сигнатура',
    ERROR_REQUIRED: 'Введены не все необходимые данные',
    ERROR_SHORT_TEXT: 'Ошибка: SHORT_TEXT',
    ERROR_PHONE: 'Неверный номер телефона',
    ERROR_EMAIL: 'Неверный адрес электронной почты',
    ERROR_CHECK_LIMIT: 'Превышен лимит. Количество попыток исчерпано',
    ERROR_CHECK_LIMIT_FIO:
      'Данные, которые Вы ввели для получения талона в электронную очередь на эту дату уже используются для другой записи. Рекомендуем повторить попытку записи в электронную очередь на другую дату.',
    ERROR_CHECK_LIMIT_FIO_ONLY: 'К сожалению, система не может осуществить запись в электронную очередь с такими данными.' +
      '<br/>' +
      '<span class="smaller">Рекомендуем проверить фамилию, усказанную при регистрации, так как она должна быть введена на украинском языке с большой буквы без любых дополнительных знаков.</span>' +
      '<br/>' +
      '<span class="smaller">Обратите внимание, что данная фамилия, к тому же, не может быть использована для записи в электронную очередь больше трёх раз в неделю.</span>',
    //ERROR_CHECK_LIMIT_FIO_ONLY: 'Пожалуйста, введите исключительно фамилию.',
    ERROR_ADD_RESERVATION: 'Время зарезервировано',
    disagree: 'Вы не подтвердили соглашение на сбор, обработку и использование персональных данных',
    datetime_choose: 'Вы не выбрали дату или время',
    time_exceeded: 'Вышло время ожидания',
  },

  units: {
    hours: 'ч',
    minutes: 'мин',
    seconds: 'сек',
  },

  equick: {
    'queue-tree': 'Выбор услуги',
    'queue-period': 'Дата и время',
    'queue-form': 'Данные',
    'queue-results': 'Подтверджение',

    main_title: 'Выберите услугу, или воспользуйтесь поиском.',
    form_title: 'Заполните данные:',
    results_title: 'Подтверждение данных',
    input_placeholder: 'Введите данные',
    search_placeholder: 'Введите название услуги',
    submit_button: 'Соглашаюсь',
    not_found: 'Ничего не найдено',
    queue_current_title: 'Вы можете записаться в текущую очередь,<br />или выбрать удобную вам дату и время',
    queue_current_people: 'На данный момент в очереди:',
    queue_current_time: 'Приблизительное время ожидания:',
    queue_current_wait: 'Ожидается появление талонов через:',
    queue_current_register: 'Записаться в текущую очередь',
    queue_current_choose_date: 'Перейти к выбору даты и времени',
    queue_fixed_choose_date: 'Выберите дату:',
    queue_fixed_choose_time: 'Выберите время:',
    queue_fixed_date_error: 'Не удалось загрузить свободное время для даты',
  }
};
