import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__buttons" }

import { ref } from "vue";
import HeaderButton from "@/components/Buttons/HeaderButton.vue";
import HeaderCartButton from "@/components/Buttons/HeaderCartButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderButtons',
  setup(__props) {

const shoppingCartSum = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(HeaderButton, {
      class: "unavailable",
      text: _ctx.$t('header.info_button')
    }, null, 8, ["text"]),
    _createVNode(HeaderButton, {
      class: "unavailable",
      text: _ctx.$t('header.rates_button')
    }, null, 8, ["text"]),
    (shoppingCartSum.value)
      ? (_openBlock(), _createBlock(HeaderCartButton, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
}

})