import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "content" }

import { computed, onMounted, ref, watch } from 'vue';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import BreadcrumbsComponent from '@/components/Text/BreadcrumbsComponent.vue';
import TitleText from '@/components/Text/TitleText.vue';
import QueueTreeItem from '@/components/Tree/QueueTreeItem.vue';
import { Service } from '@/types/Service';
import { ServicePackage } from '@/types/ServicePackage';
import { useRouter } from 'vue-router';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import { useI18n } from 'vue-i18n';
import { useMain } from '@/composable/useMain';
import { TreeGroup } from '@/types/Tree';
import QueueTreeGroup from '@/components/Tree/QueueTreeGroup.vue';
import GotovoSearchInput from '@/components/Input/Gotovo/GotovoSearchInput.vue';
import GotovoPaginator from '@/components/Paginator/GotovoPaginator.vue';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import { info } from "sass";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueTreeView',
  emits: ['set-footer-buttons'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const { push } = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { currentGroup, isAgree, pageIndent } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { services, servicePackages, results, error, info } = storeToRefs(terminalStore);
const { isGotovo } = useMain();

const isLoading = ref(true);
const serviceError = ref('');
const loadError = ref('');
const isAgreeError = ref(false);
const searchName = ref('');
const isSearchActive = ref(false);

const items = computed((): (Service | ServicePackage)[] => {
  return [...services.value, ...servicePackages.value];
  //return [...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.servicePackages];
});

const groups = computed((): TreeGroup[] => {
  const groups: TreeGroup[] = [];
  [...items.value]
      //.sort((a, b) => b.group?.length - a.group?.length)
      .map(item => {
        if (item?.group?.length > 0) {
          for (let i = 0; i < item.group.length; i++) {
            const index = groups.findIndex(group => group.name === item.group[i]);
            if (index >= 0) {
              groups[index].groups?.push(item.group[i]);
            } else {
              groups.push({
                type: 'group',
                name: item.group[i],
                parent: i === 0 ? '' : item.group[i - 1],
                services: [],
                groups: []
              });
            }

            if (i === item.group.length - 1) {
              groups[index >= 0 ? index : groups.length - 1].services?.push(item);
            }
          }
        } else {
          groups.push({ type: 'service', service: item });
        }
      });
  return groups;
});

const maxItems = computed((): number => {
  if (isGotovo.value) {
    return isSearchActive.value ? 2 : 6;
  } else {
    return 9;
  }
});
const maxIndent = computed((): number => Math.ceil(currentItems.value.length / maxItems.value));
const pages = computed((): number => Math.ceil(currentItems.value.length / maxItems.value));

const currentItems = computed((): TreeGroup[] => {
  const currentItems: TreeGroup[] = [];
  if (!isSearchActive.value) {
    groups.value.map(group => {
      if (group.type === 'group' && (group.name === currentGroup.value || !currentGroup.value || group.parent === currentGroup.value)) {
        if (group.name !== currentGroup.value) {
          if (!group.parent || group.parent === currentGroup.value) {
            currentItems.push(group);
          }
        } else {
          group.services?.map(service => currentItems.push({ type: 'service', service }));
        }
      } else if (group.type === 'service' && !currentGroup.value) {
        currentItems.push(group);
      }
    });

  } else {
    items.value
        .filter(item => item.description?.name?.toLowerCase()?.includes(searchName.value.toLowerCase()))
        .map(item => currentItems.push({ type: 'service', service: item }));
  }

  //currentItems.sort(item => item.type === 'group' ? -1 : 1);
  return currentItems;
});

const treeItems = computed((): TreeGroup[] => {
  return currentItems.value.slice(pageIndent.value * maxItems.value, pageIndent.value * maxItems.value + maxItems.value);
});

const itemSize = computed((): string => treeItems.value.length > 2 ? 's' : 'm');

const onGroupClick = (groupName: string) => currentGroup.value = groupName;

const onItemClick = async (item: Service | ServicePackage) => {
  if (isGotovo.value && !isAgree.value) {
    isAgreeError.value = true;
    return;
  }

  isLoading.value = true;

  if (item.type === 'service') {
    await terminalStore.setTQueueService(item.id);
  } else {
    await terminalStore.setTQueueServicePackage(item.id);
  }

  isLoading.value = false;

  if (results.value[item.type === 'service' ? 'serviceId' : 'servicePackageId']) {
    await push('/queue-period');
  } else {
    serviceError.value = i18n.t('message.queue_service_error', { name: item.description.name });
  }
};

const onNextClick = async () => {
  if (pageIndent.value < maxIndent.value - 1) {
    pageIndent.value = pageIndent.value + 1;
  } else {
    pageIndent.value = 0;
  }
};

const onPreviousClick = async () => {
  if (maxIndent.value >= 1) {
    if (pageIndent.value > 0) {
      pageIndent.value = pageIndent.value - 1;
    } else {
      pageIndent.value = maxIndent.value - 1;
    }
  }
};

onMounted(async () => {
  if (!isGotovo.value) {
    emits('set-footer-buttons', [
      { type: 'back', backLink: '/' },
      { type: 'main' }
    ]);
  } else {
    emits('set-footer-buttons', []);
  }

  pageIndent.value = 0;
  currentGroup.value = '';
  appStore.addPathItem('queue-tree');

  if (info.value?.settings?.isResource) {
    await terminalStore.getResources();
  } else {
    await terminalStore.getTQueueServices();
    await terminalStore.getTQueueServicePackages();
  }

  if (error.value) {
    loadError.value = error.value === 'timeout' ? i18n.t('message.load_error') : error.value;
  }

  const header = document.getElementById('gotovo-header');
  header && header.classList.remove('hidden');
  isLoading.value = false;
});

watch(() => currentGroup.value, () => {
  if (isGotovo.value) {
    if (currentGroup.value) {
      emits('set-footer-buttons', [
        {
          type: 'back', handler: async () => {
            pageIndent.value = 0;
            currentGroup.value = '';
          }
        },
        { type: 'main' }
      ]);
    } else {
      emits('set-footer-buttons', []);
    }
  } else {
    if (currentGroup.value) {
      emits('set-footer-buttons', [
        {
          type: 'back', handler: async () => {
            pageIndent.value = 0;
            currentGroup.value = '';
          }
        },
        { type: 'main' }
      ]);
    } else {
      emits('set-footer-buttons', [
        { type: 'back', backLink: '/' },
        { type: 'main' }
      ]);
    }
  }
});

watch(() => isSearchActive.value, async () => {
  const header = document.getElementById('gotovo-header');

  if (!isSearchActive.value) {
    searchName.value = '';
    pageIndent.value = 0;
    currentGroup.value = '';
    header && header.classList.remove('hidden');
  } else {
    header && header.classList.add('hidden');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(InnerLoader, { isLoading: isLoading.value }, null, 8, ["isLoading"]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_ctx.$attrs.class)
    }, [
      (!_unref(isGotovo))
        ? (_openBlock(), _createBlock(BreadcrumbsComponent, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TitleText, {
          text: _ctx.$t('choose_service')
        }, null, 8, ["text"]),
        (_unref(isGotovo) && !_unref(currentGroup))
          ? (_openBlock(), _createBlock(GotovoSearchInput, {
              key: 0,
              onSetValue: _cache[0] || (_cache[0] = v => searchName.value = v),
              onSetState: _cache[1] || (_cache[1] = v => isSearchActive.value = v)
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["content__items", {'content__items--search': isSearchActive.value}])
        }, [
          (!_unref(isGotovo) && currentItems.value.length > maxItems.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "content__button previous",
                onClick: onPreviousClick
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["tree-items", {'tree-items--search': isSearchActive.value}])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(treeItems.value, (group) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: group.name
              }, [
                (group.type === 'group')
                  ? (_openBlock(), _createBlock(QueueTreeGroup, {
                      key: 0,
                      name: group.name,
                      size: itemSize.value,
                      onClick: ($event: any) => (onGroupClick(group.name as string))
                    }, null, 8, ["name", "size", "onClick"]))
                  : (_openBlock(), _createBlock(QueueTreeItem, {
                      key: group.service?.id,
                      "item-data": group.service,
                      size: itemSize.value,
                      onClick: ($event: any) => (onItemClick(group.service as Service | ServicePackage))
                    }, null, 8, ["item-data", "size", "onClick"]))
              ], 64))
            }), 128))
          ], 2),
          (!_unref(isGotovo) && currentItems.value.length > maxItems.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "content__button next",
                onClick: onNextClick
              }))
            : _createCommentVNode("", true)
        ], 2),
        (_unref(isGotovo) && pages.value > 1)
          ? (_openBlock(), _createBlock(GotovoPaginator, {
              key: 1,
              pages: pages.value,
              onNext: onNextClick,
              onPrevious: onPreviousClick
            }, null, 8, ["pages"]))
          : _createCommentVNode("", true)
      ])
    ], 2), [
      [_vShow, !isLoading.value && !loadError.value]
    ]),
    (serviceError.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 0,
          message: serviceError.value,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (serviceError.value = ''))
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (loadError.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 1,
          message: loadError.value,
          onClose: _cache[3] || (_cache[3] = ($event: any) => {loadError.value = ''; _unref(push)('/')})
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (isAgreeError.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 2,
          message: _ctx.$t('attention'),
          additional: _ctx.$t('errors.disagree'),
          onClose: _cache[4] || (_cache[4] = ($event: any) => (isAgreeError.value = false))
        }, null, 8, ["message", "additional"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})