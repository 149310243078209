import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "queue__datetime" }
const _hoisted_2 = {
  key: 3,
  class: "queue__datetime-loader"
}

import { ref, watchEffect } from "vue";
import QueueHeader from "@/components/Queue/QueueHeader.vue";
import QueueDates from "@/components/Queue/QueueDates.vue";
import QueueMessage from "@/components/Queue/QueueMessage.vue";
import QueueTimes from "@/components/Queue/QueueTimes.vue";
import { useDateTime } from "@/composable/useDatetime";
import LoaderComponent from "@/components/Loader/LoaderComponent.vue";
import MessagePopup from "@/components/Popups/MessagePopup.vue";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueDateTime',
  setup(__props) {

const terminalStore = useTerminalStore();
const { datePeriods, timePeriods, results } = storeToRefs(terminalStore);
const { getDateMonth } = useDateTime();

const selectedDate = ref('');
const isLoading = ref(false);
const error = ref('');

const setSelectedDate = async (date: string) => {
  const prevSelected = selectedDate.value;
  selectedDate.value = date;
  isLoading.value = true;
  await terminalStore.setTQueueDatePeriod(date);
  if (results.value?.datePeriod) {
    await terminalStore.getTQueueTimePeriods();
  } else {
    selectedDate.value = prevSelected;
    error.value = `Не вдалось завантажити вільний час для дати ${ getDateMonth(date) }`;
  }
  isLoading.value = false;
};

watchEffect(() => selectedDate.value = datePeriods.value[0]?.date);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(QueueHeader, {
        text: _ctx.$t('queue.fixed')
      }, null, 8, ["text"]),
      _createVNode(QueueDates, {
        selected: selectedDate.value,
        onSetDate: setSelectedDate
      }, null, 8, ["selected"]),
      (_unref(timePeriods)?.length && selectedDate.value)
        ? (_openBlock(), _createBlock(QueueMessage, {
            key: 0,
            text: `${_ctx.$t('queue.available_time')} ${_unref(getDateMonth)(selectedDate.value)}`
          }, null, 8, ["text"]))
        : (_openBlock(), _createBlock(QueueMessage, {
            key: 1,
            text: `${_ctx.$t('queue.no_available_time')} ${_unref(getDateMonth)(selectedDate.value)}`
          }, null, 8, ["text"])),
      (!isLoading.value)
        ? (_openBlock(), _createBlock(QueueTimes, { key: 2 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(LoaderComponent, { text: "" })
          ]))
    ]),
    (error.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 0,
          message: error.value,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = ''))
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})