import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-container" }

import { computed, onMounted, ref } from "vue";
import TitleText from "@/components/Text/TitleText.vue";
import SubtitleText from "@/components/Text/SubtitleText.vue";
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import MenuItems from "@/components/Menu/MenuItems.vue";
import { IncludeItem } from "@/types/Info";
import LanguagesComponent from "@/components/Languages/LanguagesComponent.vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuView',
  emits: ['set-footer-buttons'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const appStore = useAppStore();
const { path } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

const isLoading = ref(false);

const items = computed((): IncludeItem[] => info.value?.include?.filter(i => i.active));

onMounted(() => {
  path.value = [];
  emits('set-footer-buttons', []);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(InnerLoader, { "is-loading": isLoading.value }, null, 8, ["is-loading"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$attrs.class)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TitleText, {
          text: _ctx.$t('welcome_title')
        }, null, 8, ["text"]),
        _createVNode(SubtitleText, {
          text: _ctx.$t('welcome_subtitle')
        }, null, 8, ["text"])
      ]),
      _withDirectives(_createVNode(MenuItems, { items: items.value }, null, 8, ["items"]), [
        [_vShow, !isLoading.value]
      ]),
      _createVNode(LanguagesComponent)
    ], 2)
  ], 64))
}
}

})