import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header__qr" }
const _hoisted_2 = { class: "header__qr-text" }
const _hoisted_3 = { class: "header__right" }
const _hoisted_4 = {
  key: 0,
  class: "flex-row"
}

import ReloadButton from '@/components/Buttons/ReloadButton.vue';
import HelpLine from '@/components/HelpLine/HelpLine.vue';
import HeaderRest from '@/components/Header/HeaderRest.vue';
import HeaderInfo from '@/components/Header/HeaderInfo.vue';
import HeaderButtons from '@/components/Header/HeaderButtons.vue';
import ServiceMenuButton from '@/components/Buttons/ServiceMenuButton.vue';
import { useMain } from "@/composable/useMain";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultHeader',
  setup(__props) {

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const { isQueueOnly } = useMain();

const headerType = computed((): string => info.value?.settings?.headerType ?? 'helpLine');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header", {'header--queue': _unref(isQueueOnly)}])
  }, [
    _createVNode(ReloadButton),
    (headerType.value === 'helpLine')
      ? (_openBlock(), _createBlock(HelpLine, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: "/images/st-logo.svg",
            alt: "logo",
            class: "header__logo",
            draggable: "false"
          }, null, -1)),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.$t('header.qr_text_1')), 1),
              _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(_toDisplayString(_ctx.$t('header.qr_text_2')), 1)
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: "/images/st-qr.svg",
              alt: "qr",
              class: "header__qr-icon",
              draggable: "false"
            }, null, -1))
          ])
        ], 64)),
    (!_unref(isQueueOnly))
      ? (_openBlock(), _createBlock(HeaderRest, {
          key: 2,
          class: "unavailable"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(HeaderInfo),
      (!_unref(isQueueOnly))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(HeaderButtons),
            _createVNode(ServiceMenuButton, { class: "unavailable" })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})