<template>
  <div class="tree-item" :class="`tree-item--${size}`">
    <img v-if="isGotovo" alt="icon" :src="`/media/${itemData.iconPath}`" class="tree-item__icon"
         onerror="this.onerror = null; this.src = '/media/dmsu/default.svg'" />
    <div v-if="isGotovo && itemData.availableCurrent && info?.settings?.isCountService" class="tree-item__quantity">
      <p class="tree-item__quantity-amount">{{ itemData.availableCurrent.quantity ?? 0 }}</p>
      <!--      <p class="tree-item__quantity-text">{{ $t('queue.free') }}</p>-->
    </div>
    <p class="tree-item__text">{{ name }}</p>
    <template v-if="!isGotovo">
      <p v-if="duration" class="tree-item__additional">
        {{ $t('duration') }}: {{ duration }}
      </p>
      <p v-else-if="itemData.description?.caption" class="tree-item__additional">
        {{ itemData.description.caption }}
      </p>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import { Service } from "@/types/Service";
import { ServicePackage } from "@/types/ServicePackage";
import { useMain } from "@/composable/useMain";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const props = defineProps({
  itemData: {
    type: Object as PropType<Service | ServicePackage>,
    default: () => ({})
  },
  size: String,
});

const i18n = useI18n();
const { isGotovo } = useMain();
const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

const name = computed((): string => props.itemData?.description?.name ?? `id: ${ props.itemData.id }`);

const duration = computed((): string => {
  const duration = props.itemData?.type === 'service'
      ? props.itemData.duration
      : props.itemData?.items?.reduce((prev, next) => {
        return prev + next.duration
      }, 0);
  return duration ? getDurationStr(duration) : '';
});

const getDurationStr = (duration: number): string => {
  const result: string[] = [];
  const hours = Math.floor(duration / 3600);
  if (hours > 0) {
    result.push(`${ hours } ${i18n.t('units.hours')}`);
    duration -= hours * 3600;
  }

  const minutes = Math.floor(duration / 60);
  if (minutes > 0) {
    result.push(`${ minutes } ${i18n.t('units.minutes')}`);
    duration -= minutes * 60;
  }

  if (duration > 0) {
    result.push(`${ duration } ${i18n.t('units.seconds')}`);
  }

  return result.join(' ');
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/components/gotovo-queue-tree";

.tree-item {
  @extend .flex-col, .pointer;
  position: relative;
  flex: 1;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  height: calc(100% / 3 - 1rem);
  padding: 4px;
  color: $dark-gray;
  border: 1px solid $dark-gray--tr;
  border-radius: 4px;
  box-sizing: border-box;

  &:active {
    transform: scale(0.97);
  }

  &--s {
    min-width: calc((100% - 1rem * 2) / 3);
    max-width: calc((100% - 1rem * 2) / 3);
  }

  &--m {
    min-width: calc((100% - 1rem) / 2);
    max-width: calc((100% - 1rem) / 2);
  }

  &__amount {
    @extend .bold, .blue;
    position: absolute;
    top: 4px;
    right: 0.5rem;
  }

  &__text {
    @extend .row-container, .upper;
    justify-content: center;
    height: 100%;
    min-width: 100%;
    width: 100%;
    font-size: min(122%, 1.375rem);
    text-wrap: balance;
    text-align: center;
  }

  &__additional {
    @extend .font-size-m;
    color: #888;
  }
}

p {
  @extend .text-center;
  max-width: 80%;
}

.gotovo .tree-items--search .tree-item {
  height: calc(100% - 5rem);
}

.gotovo .tree-item {
  &__text {
    position: relative;
    right: 3rem;
    width: 100%;
    font-size: 2.75vh;
  }

  &__quantity {
    @extend .row-container;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;

    & > p {
      @extend .text-center, .bold;
      min-width: 3rem;
      width: 3rem;
    }

    &-amount {
      line-height: 54px;
      font-size: 2.25vh;
      background-color: $yellow;
      border-bottom-left-radius: 8px;
    }

    &-text {
      @extend .col-container;
      justify-content: center;
      height: 100%;
      writing-mode: vertical-lr;
      text-orientation: upright;
      text-transform: uppercase;
      font-size: 1.75vh;
    }
  }
}

@media (max-height: 900px) {
  .gotovo .tree-item__quantity {
    &-text {
      font-size: calc(min(2vh, 0.75vw));
    }
  }
}
</style>
