import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dates" }
const _hoisted_2 = { class: "dates__month" }
const _hoisted_3 = { class: "dates__month-title" }
const _hoisted_4 = { class: "dates__calendar" }
const _hoisted_5 = { class: "dates__header" }
const _hoisted_6 = { class: "dates__list" }
const _hoisted_7 = ["onClick"]

import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

interface WeekDay {
  date: Date;
  number: number;
  class: { [key: string]: boolean };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoQueueDates',
  props: {
  selected: String
},
  emits: ['set-date'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const terminalStore = useTerminalStore();
const { datePeriods } = storeToRefs(terminalStore);

const date = new Date();

const month = ref(date.getMonth());
const year = ref(date.getFullYear());
const offset = ref(0);

const calendar = computed((): WeekDay[][] => {
  const calendar: WeekDay[][] = [];
  const firstDayOfMonth = new Date(year.value, month.value, 1)
  const lastDayOfMonth = new Date(year.value, month.value + 1, 0);
  const daysInMonth = lastDayOfMonth.getDate();
  const firstDayOfWeek = firstDayOfMonth.getDay();

  let currentDate = 2 - firstDayOfWeek;
  while (currentDate <= daysInMonth) {
    const week: WeekDay[] = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(year.value, month.value, currentDate);
      week.push({
        date: day,
        number: day.getDate(),
        class: {
          selected: isDatesEqual(props.selected ?? date, day),
          today: isDatesEqual(date, day),
          invisible: day.getMonth() !== month.value,
          disabled: !datePeriods.value.find(item => isDatesEqual(new Date(item.date), day)),
        }
      });
      currentDate++;
    }

    calendar.push(week);
  }

  return calendar;
});

const monthName = computed((): string => {
  return new Date(year.value, month.value).toLocaleDateString('uk-UA', { month: 'long' });
});

const onNextClick = () => {
  if (month.value < 11) {
    month.value++;
  } else {
    year.value++;
    month.value = 0;
  }
  offset.value++;
};

const onPrevClick = () => {
  if (month.value > 0) {
    month.value--;
  } else {
    year.value--;
    month.value = 11;
  }
  offset.value--;
};

const onDateClick = (date: Date) => emits('set-date', date.toISOString());

const isDatesEqual = (date1: Date | string, date2: Date | string) => new Date(date1).toDateString() === new Date(date2).toDateString();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["dates__month-prev", {invisible: offset.value === 0}]),
        onClick: onPrevClick
      }, null, 2),
      _createElementVNode("p", _hoisted_3, _toDisplayString(monthName.value) + " " + _toDisplayString(year.value), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["dates__month-next", {invisible: offset.value === 11}]),
        onClick: onNextClick
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, (day) => {
          return _createElementVNode("p", {
            key: day,
            class: "dates__header-item"
          }, _toDisplayString(_ctx.$t(`days[${ day - 1 }]`)), 1)
        }), 64))
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(calendar.value, (week, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "dates__row"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week, (day) => {
              return (_openBlock(), _createElementBlock("p", {
                key: day.date,
                class: _normalizeClass(["dates__row-item", day.class]),
                onClick: ($event: any) => (onDateClick(day.date))
              }, _toDisplayString(day.number), 11, _hoisted_7))
            }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})