<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="7" :fill="color"/>
  </svg>

</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({ isPassed: Boolean });

const color = computed((): string => props.isPassed ? '#00B2E0' : '#CDCDCD');
</script>

<style lang="scss" scoped>

</style>