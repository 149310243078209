<template>
  <div class="queue__fixed">
    <p class="queue__fixed-title">{{ $t('equick.queue_fixed_choose_date') }}</p>
    <div class="queue__fixed-list queue__fixed-list--dates">
      <div v-for="date in dates" :key="date.date" class="queue__fixed-list__item"
           :class="{'queue__fixed-list__item--selected': date.date === selectedDate}"
           @click="setSelectedDate(date.date)">
        <p class="queue__fixed-list__item-value">{{ moment(date.date).format('DD.MM') }}</p>
        <p v-if="info?.settings?.isCountDate" class="queue__fixed-list__item-amount">{{ date.count }}</p>
      </div>
    </div>

    <p v-if="!(timePeriods?.length && selectedDate)" class="queue__fixed-message">
      {{ $t('queue.no_available_time') }} {{ selectedDate }}
    </p>
    <template v-else>
      <p class="queue__fixed-title">{{ $t('equick.queue_fixed_choose_time') }}</p>
      <div v-if="!isLoading" class="queue__fixed-list queue__fixed-list--times">
        <div v-for="time in timePeriods" :key="time.date" class="queue__fixed-list__item"
             :class="{'queue__fixed-list__item--selected': time.a === selectedTime}"
             @click="selectedTime = time.a">
          <p class="queue__fixed-list__item-value">{{ moment(time.a).format('HH:mm') }}</p>
          <p v-if="info?.settings?.isCountTime" class="queue__fixed-list__item-amount">{{ time.count }}</p>
        </div>
      </div>
    </template>
  </div>

  <equick-loader v-if="isLoading" :animation-delay="0" />
  <equick-message-popup v-if="error" :message="error" @close="error = ''" />
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";
import { useDateTime } from "@/composable/useDatetime";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { DatePeriod } from "@/types/Period";

const emits = defineEmits(['set-fixed-data']);

const i18n = useI18n();
const terminalStore = useTerminalStore();
const { datePeriods, timePeriods, results, info } = storeToRefs(terminalStore);
const { getDateMonth } = useDateTime();

const selectedDate = ref('');
const selectedTime = ref('');
const isLoading = ref(false);
const error = ref('');

const dates = computed((): DatePeriod[] => {
  const maxAmount = info.value?.settings?.countDay >= 0 ? info.value.settings.countDay : 7;
  const periods: DatePeriod[] = [];
  for (let i = 0; i < maxAmount; i++) {
    const date = moment().add(i, 'd');
    const period = datePeriods.value.find(i => i.date.slice(0, 10) === date.format('YYYY-MM-DD'));
    if (period) {
      periods.push(period);
    }
  }
  return periods;
});

const setSelectedDate = async (date: string) => {
  const prevSelected = selectedDate.value;
  selectedDate.value = date;
  isLoading.value = true;
  await terminalStore.setTQueueDatePeriod(date);
  if (results.value?.datePeriod) {
    await terminalStore.getTQueueTimePeriods();
  } else {
    selectedDate.value = prevSelected;
    error.value = `${ i18n.t('equick.queue_fixed_date_error') } ${ getDateMonth(date) }`;
  }
  isLoading.value = false;
};

watchEffect(() => selectedDate.value = datePeriods.value[0]?.date ?? '');

watch([() => selectedDate.value, () => selectedTime.value], () => {
  emits('set-fixed-data', { date: selectedDate.value, time: selectedTime.value });
});
</script>

<style scoped lang="scss">
.queue__fixed {
  @extend .flex-col;

  &-title {
    margin-top: $--24px;
    @include font(rems(28), 120%, black, 500);
  }

  &-list {
    @extend .flex-row;
    flex-wrap: wrap;
    gap: $--24px rems(36);
    padding-top: $--24px;
    overflow-y: auto;

    &--dates {
      height: 100%;
      max-height: calc(#{rems(56)} * 2 + #{$--24px} * 2);
    }

    &--times {
      max-height: calc(100% - #{rems(42)});
    }

    &__item {
      @extend .row-container, .pointer;
      justify-content: center;
      position: relative;
      width: rems(106);
      height: rems(56);
      border-radius: 5px;
      background-color: white;
      box-sizing: border-box;
      transition-duration: 0.15s;

      &--selected {
        background-color: $equick-primary;

        .queue__fixed-list__item-value {
          color: white;
        }

        .queue__fixed-list__item-amount {
          background-color: white;
          color: black;
          border: 1.5px solid $equick-secondary;
        }
      }

      &-value {
        @include font($--24px, 120%, black, 500, center);
      }

      &-amount {
        position: absolute;
        top: rems(-18);
        right: rems(-4);
        width: rems(36);
        height: rems(36);
        border-radius: 50%;
        @include font(18px, rems(36), white, 500, center);
        background-color: $equick-primary;
      }
    }
  }
}

@media (orientation: landscape) {
  .queue__fixed {
    max-height: calc(100% - #{rems(42)});
  }
}

@media (orientation: portrait) {
  .queue__fixed {
    max-height: calc(100% - 21rem);
  }
}
</style>
