<template>
  <div class="footer-button" :class="[`footer-button--${buttonData.type}`, {disabled: buttonData.isDisabled}]"
       :id="buttonData.type" @click="onClick">
    <p class="footer-button__text">
      {{ buttonData?.text ? buttonData.text : $t(`footer.button_${ buttonData.type }`) }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { FooterButton } from '@/types/Footer';
import { useRouter } from 'vue-router';
import { useMain } from '@/composable/useMain';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";

const props = defineProps({
  buttonData: {
    type: Object as PropType<FooterButton>,
    default: () => ({})
  }
});

const router = useRouter();
const appStore = useAppStore();
const { currentGroup, pageIndent, path } = storeToRefs(appStore);
const { isGotovo } = useMain();

const onClick = async () => {
  const { type, handler, backLink } = props.buttonData;
  if (handler) {
    handler();
  } else if (backLink) {
    appStore.removeLastPathItem();
    await router.push(backLink);
  } else if (type === 'back') {
    appStore.removeLastPathItem();
    await router.back();
  } else if (type === 'main') {
    if (isGotovo.value) {
      if (router.currentRoute.value.path === '/queue-tree') {
        currentGroup.value = '';
        pageIndent.value = 0;
      } else {
        await router.push('/queue-tree');
      }
    } else {
      path.value = [];
      await router.push('/');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/utils/sizes";

@include setFooterIcons();

.footer-button {
  @extend .pointer;
  flex: 1;
  position: relative;

  &.disabled {
    filter: grayscale(.5);
    pointer-events: none;
  }

  &--back, &--next {
    background-color: $blue;
    flex: 1;
  }

  &--back {
    background-position-x: 1.875rem;

    & > .footer-button__text {
      text-indent: 5rem;
    }
  }

  &--next {
    background-position-x: calc(100% - 1.875rem);

    & > .footer-button__text {
      position: absolute;
      right: 5rem;
    }
  }

  &--main {
    @extend .text-center;
    background-color: $dark-gray;
    flex: 2;
  }

  &__text {
    @extend .font-size-xxl, .upper;
    line-height: $footer;
    color: white;
  }
}

.gotovo .footer-button {
  background-color: transparent;

  &--back {
    flex: 1 !important;
    background: #ccc8;

    .footer-button__text {
      text-indent: 0;
      text-align: center;
    }
  }

  &--next {
    @include svgBg($arrow-right, 2rem, transparent, calc(100% - 1.5rem));
  }

  &__text {
    @extend .bold;
    color: #333;
    text-transform: unset;
    line-height: calc(min(7rem, 10vh));
    font-size: 2rem;
  }

  &--main {
    flex: 3 !important;
    background-color: $yellow;
  }
}
</style>
