<template>
  <div class="queue__datetime">
    <queue-header :text="$t('queue.fixed')"/>
    <queue-dates :selected="selectedDate" @setDate="setSelectedDate"/>
    <queue-message v-if="timePeriods?.length && selectedDate"
                   :text="`${$t('queue.available_time')} ${getDateMonth(selectedDate)}`"/>
    <queue-message v-else :text="`${$t('queue.no_available_time')} ${getDateMonth(selectedDate)}`"/>
    <queue-times v-if="!isLoading"/>
    <div v-else class="queue__datetime-loader">
      <loader-component text=""/>
    </div>
  </div>
  <message-popup v-if="error" :message="error" @close="error = ''"/>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from "vue";
import QueueHeader from "@/components/Queue/QueueHeader.vue";
import QueueDates from "@/components/Queue/QueueDates.vue";
import QueueMessage from "@/components/Queue/QueueMessage.vue";
import QueueTimes from "@/components/Queue/QueueTimes.vue";
import { useDateTime } from "@/composable/useDatetime";
import LoaderComponent from "@/components/Loader/LoaderComponent.vue";
import MessagePopup from "@/components/Popups/MessagePopup.vue";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const terminalStore = useTerminalStore();
const { datePeriods, timePeriods, results } = storeToRefs(terminalStore);
const { getDateMonth } = useDateTime();

const selectedDate = ref('');
const isLoading = ref(false);
const error = ref('');

const setSelectedDate = async (date: string) => {
  const prevSelected = selectedDate.value;
  selectedDate.value = date;
  isLoading.value = true;
  await terminalStore.setTQueueDatePeriod(date);
  if (results.value?.datePeriod) {
    await terminalStore.getTQueueTimePeriods();
  } else {
    selectedDate.value = prevSelected;
    error.value = `Не вдалось завантажити вільний час для дати ${ getDateMonth(date) }`;
  }
  isLoading.value = false;
};

watchEffect(() => selectedDate.value = datePeriods.value[0]?.date);

</script>

<style lang="scss" scoped>
@import "src/assets/styles/utils/sizes";

$datetime-height: calc(100vh - #{$header} - 1.875rem - 0.4rem - 3.125rem - 1.25rem - 1rem);

.queue__datetime {
  flex: 2;
  max-width: 60vw;
  max-height: $datetime-height;
  padding-left: 1.25rem;
  padding-bottom: 1rem;

  :deep(.queue__times) {
    max-height: calc(100% - 3.125rem - 0.5rem - 3.125rem - 0.9375rem - 10px - 0.625rem * 2 - 10px - 0.5rem);
  }

  &-loader {
    position: relative;
    width: 100%;

    &, & > .loader {
      height: 35vh;
      max-width: 60vw;
    }

    & > .loader {
      position: absolute;
    }
  }

  & .queue__message {
    text-align: left;
  }
}
</style>
