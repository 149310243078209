<template>
  <div class="queue__current">
    <p v-html="$t('equick.queue_current_title')" class="queue__current-title"></p>

    <div class="queue__current-info">
      <template v-if="availableCurrent?.quantity > 0">
        <div v-if="info?.settings?.isCountCurrent" class="queue__current-info__item">
          <p class="queue__current-message">{{ $t('equick.queue_current_people') }}</p>
          <p class="queue__current-value">{{ availableCurrent.queueLength ?? 0 }}</p>
        </div>
        <div
            v-if="availableCurrent?.approximateWaitingMs && availableCurrent?.approximateWaitingMs / 60_000 > 0 || true"
            class="queue__current-info__item">
          <p class="queue__current-message">{{ $t('equick.queue_current_time') }}</p>
          <p class="queue__current-value">{{ getFormattedTime(availableCurrent?.approximateWaitingMs) }}</p>
        </div>
      </template>
      <div v-else-if="availableCurrent?.quantity === 0 && availableCurrent?.appearanceWaitingMs >= 0">
        <p class="queue__current-message">{{ $t('equick.queue_current_wait') }}</p>
        <p class="queue__current-value">{{ getFormattedTime(availableCurrent?.appearanceWaitingMs) }}</p>
      </div>
    </div>

    <equick-base-button :text="$t('equick.queue_current_register')" @click="$emit('register-current')" />
    <equick-base-button :text="$t('equick.queue_current_choose_date')" color="white"
                        @click="$emit('set-queue-type', 'fixed')" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";

defineEmits(['set-queue-type', 'register-current']);

const i18n = useI18n();
const terminalStore = useTerminalStore();
const { availableCurrent, info } = storeToRefs(terminalStore);

const getFormattedTime = (value: number) => {
  const time: string[] = [];
  if (availableCurrent.value?.moreThan) {
    time.push(i18n.t('queue.more_than'));
  }

  value = 120000;

  if (value) {
    const hours = Math.floor(value / 3_600_000);
    if (hours > 0) {
      time.push(`${ hours } ${ i18n.t('units.hours') }.`);
    }
    const minutes = Math.floor(value / 60_000 - hours * 3_600_000);
    if (minutes > 0) {
      time.push(`${ minutes } ${ i18n.t('units.minutes') }.`);
    }
  }

  return time.join(' ');
};
</script>

<style scoped lang="scss">
.queue__current {
  @extend .col-container;
  height: 100%;

  &-title {
    margin-bottom: $--16px;
    @include font(rems(28), 120%, black, 500, center);
    text-wrap: balance;
  }

  &-info {
    @extend .row-container;


    &__item {
      @extend .col-container;
      gap: $--24px;
    }
  }

  &-message {
    @include font($--24px, 120%, black, 300, center);
  }

  &-value {
    @include font(rems(55), 120%, black, 500, center);
  }
}

.base-button {
  max-width: rems(381);

  & + & {
    margin-top: $--32px;
  }
}

@media (orientation: landscape) {
  .queue__current {
    justify-content: flex-end;

    &-info {
      gap: 9vw;
      margin: 8.75vh 0;
    }
  }
}

@media (orientation: portrait) {
  .queue__current {
    justify-content: center;

    &-info {
      flex-direction: column;
      gap: $--16px;
      margin-bottom: $--40px;

      &__item:not(:last-child) {
        padding-bottom: $--16px;
        border-bottom: 1.5px solid $equick-primary;
      }
    }
  }

  .base-button:first-of-type {
    margin-top: $--40px;
  }
}
</style>
