<template>
  <div v-if="isShown" class="languages">
    <language-item v-for="language in languages" :key="language" :text="language.language"
                     :is-current="language.language === selected" @click="onClick(language.language)"/>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Language } from "@/types/Info";
import { useRoute } from "vue-router";
import LanguageItem from "@/components/Languages/LanguageItem.vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const i18n = useI18n();
const route = useRoute();
const appStore = useAppStore();
const { language, acceptLanguages } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

const isShown = ref(true);

const selected = computed((): string => language.value || i18n.locale.value);
const languages = computed((): Language[] => {
  return info.value.languages.filter(l => acceptLanguages.value.includes(l.language));
});

const onClick = (language: string) => {
  i18n.locale.value = language;
  appStore.setLanguage(language);
};

const setState = () => isShown.value = route.path === '' || route.path === '/';

onMounted(() => {
  setState();
  //i18n.locale.value = selected.value ?? 'ua';
});

watch(() => route.path, setState);

</script>

<style lang="scss" scoped>
.languages {
  @extend .row-container;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #ccc4;
  border-radius: 4px;
}
</style>
