import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "queue"
}

import { onMounted, ref, watch } from 'vue';
import GotovoQueueLive from '@/components/Queue/Gotovo/GotovoQueueLive.vue';
import { AvailableCurrent, NamedAvailableCurrent } from '@/types/AvailableCurrent';
import { useRouter } from 'vue-router';
import GotovoQueueDateTime from '@/components/Queue/Gotovo/GotovoQueueDateTime.vue';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import GotovoResultPopup from '@/components/Popups/GotovoResultPopup.vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoQueue',
  emits: ['load-data', 'set-footer-buttons'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const router = useRouter();
const terminalStore = useTerminalStore();
const { availableCurrent, results, services, servicePackages, selected, addResult, info } = storeToRefs(terminalStore);

const isLive = ref(true);
const isLiveSubmit = ref(false);
const isChooseError = ref(false);
const isLoading = ref(false);
const selectedData = ref<NamedAvailableCurrent>({} as NamedAvailableCurrent);
const isErrorPopup = ref(false);
const isSuccessPopup = ref(false);
const liveKey = ref(0);

const goBack = () => router.push('/queue-tree');

const showLive = () => isLive.value = true;

const showFixed = () => {
  isLive.value = false;
  emits('load-data');
};

const onPrintClick = async () => {
  if (!results.value.timePeriod) {
    isChooseError.value = true;
  } else {
    await terminalStore.addQueue({});

    if (addResult.value.success) {
      /*await store.dispatch('getTQueueForm');
      await store.dispatch('printDocument', {
        address: addResult.value?.print?.organization?.description?.address ?? '-',
        serviceName: addResult.value?.print?.service?.description?.name ?? '-',
        ticketNum: addResult.value?.print?.ticket?.num ?? '-',
        ticketPullTime: addResult.value?.print?.ticket?.pullTime ?? '-'
      });*/
      isSuccessPopup.value = true;
    } else {
      isErrorPopup.value = true;
    }
  }
};

onMounted(async () => {
  isLoading.value = true;
  isLive.value = !!availableCurrent.value || !!results.value?.availableCurrent;

  if (results.value?.availableCurrent) {
    selectedData.value = {
      ...results.value.availableCurrent,
      name: ''
    };
  }

  if (results.value.serviceId !== undefined) {
    if (!services.value?.length) {
      info.value?.settings?.isResource ? await terminalStore.getResources() : await terminalStore.getTQueueServices();
    }

    const service = services.value.find(item => item.id === +(results.value.serviceId as string));
    if (service) {
      selectedData.value = {
        ...selectedData.value,
        ...(service.availableCurrent ?? {} as AvailableCurrent),
        name: service.description.name ?? `${ service.id }`
      };
    }
  } else if (results.value.servicePackageId) {
    if (!servicePackages.value?.length) {
      info.value?.settings?.isResource ? await terminalStore.getResources() : await terminalStore.getTQueueServicePackages();
    }

    const servicePackage = servicePackages.value.find(item => item.id === +(results.value.servicePackageId as string));
    if (servicePackage) {
      selectedData.value = {
        ...selectedData.value,
        ...(servicePackage.availableCurrent ?? {} as AvailableCurrent),
        name: servicePackage.description.name ?? `${ servicePackage.id }`
      };
    }
  }

  if (!selectedData.value) {
    await terminalStore.getTQueueForm();
    selectedData.value = {
      ...availableCurrent.value,
      name: selected.value?.service?.name ? selected.value.service.name : `${ results.value.serviceId }`
    };
  }

  isLoading.value = false;
});

watch(() => isLive.value, () => {
  if (isLive.value) {
    isLiveSubmit.value = false;
  }
});

watch([() => isLiveSubmit.value, () => isLive.value], () => {
  if (isLiveSubmit.value) {
    emits('set-footer-buttons', [
      {
        type: 'back', handler: () => {
          isLiveSubmit.value = false;
          isLive.value = true;
          liveKey.value++;
        }
      },
      { type: 'main' }
    ]);
  } else if (isLive.value) {
    emits('set-footer-buttons', [
      { type: 'back', handler: goBack },
      { type: 'main' }
    ]);
  } else {
    emits('set-footer-buttons', [
      {
        type: 'back',
        handler: availableCurrent.value ? showLive : goBack
      },
      { type: 'main' }
    ]);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (isLive.value)
            ? (_openBlock(), _createBlock(GotovoQueueLive, {
                "selected-data": selectedData.value,
                key: liveKey.value,
                onSetSubmitState: _cache[0] || (_cache[0] = v => isLiveSubmit.value = v),
                onShowFixedQueue: showFixed
              }, null, 8, ["selected-data"]))
            : (_openBlock(), _createBlock(GotovoQueueDateTime, {
                key: 1,
                onPrint: onPrintClick
              }))
        ]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(InnerLoader, {
          key: 1,
          "is-loading": isLoading.value
        }, null, 8, ["is-loading"]))
      : _createCommentVNode("", true),
    (isChooseError.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 2,
          additional: _ctx.$t('errors.datetime_choose'),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (isChooseError.value = false))
        }, null, 8, ["additional"]))
      : _createCommentVNode("", true),
    (isErrorPopup.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 3,
          additional: _ctx.$t('message.queue_service_error', {name: selectedData.value.name}),
          onClose: _cache[2] || (_cache[2] = ($event: any) => (isErrorPopup.value = false))
        }, null, 8, ["additional"]))
      : _createCommentVNode("", true),
    (isSuccessPopup.value)
      ? (_openBlock(), _createBlock(GotovoResultPopup, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})