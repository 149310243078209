<template>
  <div class="tree-items">
    <tree-item v-for="(item, key) in items" :key="key" :item-data="item" :size="items.length > 2 ? 's' : 'm'"
               @click="onClick(item, key)"/>
  </div>
</template>

<script lang="ts" setup>
import TreeItem from "@/components/Tree/TreeItem.vue";
import { PropType } from "vue";
import { TreeItem as TreeItemType } from "@/types/Tree";

defineProps({
  items: {
    type: Array as PropType<TreeItemType[]>
  }
});
const emits = defineEmits(['item-click']);

const onClick = (item: TreeItemType, key: string) => emits('item-click', item, key);
</script>

<style lang="scss" scoped>
.tree-items {
  @extend .flex-row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 0.9375rem * 2);
  min-height: calc(22vh * 2 + 1.25rem * 2);
  margin: 0 0.9375rem;
}
</style>
