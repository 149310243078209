<template>
  <div v-if="!isLoading" class="queue">
    <gotovo-queue-live v-if="isLive" :selected-data="selectedData" :key="liveKey"
                       @set-submit-state="v => isLiveSubmit = v" @show-fixed-queue="showFixed" />
    <gotovo-queue-date-time v-else @print="onPrintClick" />
  </div>
  <inner-loader v-if="isLoading" :is-loading="isLoading" />
  <message-popup v-if="isChooseError" :additional="$t('errors.datetime_choose')" @close="isChooseError = false" />
  <message-popup v-if="isErrorPopup" :additional="$t('message.queue_service_error', {name: selectedData.name})"
                 @close="isErrorPopup = false" />
  <gotovo-result-popup v-if="isSuccessPopup" />
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import GotovoQueueLive from '@/components/Queue/Gotovo/GotovoQueueLive.vue';
import { AvailableCurrent, NamedAvailableCurrent } from '@/types/AvailableCurrent';
import { useRouter } from 'vue-router';
import GotovoQueueDateTime from '@/components/Queue/Gotovo/GotovoQueueDateTime.vue';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import GotovoResultPopup from '@/components/Popups/GotovoResultPopup.vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const emits = defineEmits(['load-data', 'set-footer-buttons']);

const router = useRouter();
const terminalStore = useTerminalStore();
const { availableCurrent, results, services, servicePackages, selected, addResult, info } = storeToRefs(terminalStore);

const isLive = ref(true);
const isLiveSubmit = ref(false);
const isChooseError = ref(false);
const isLoading = ref(false);
const selectedData = ref<NamedAvailableCurrent>({} as NamedAvailableCurrent);
const isErrorPopup = ref(false);
const isSuccessPopup = ref(false);
const liveKey = ref(0);

const goBack = () => router.push('/queue-tree');

const showLive = () => isLive.value = true;

const showFixed = () => {
  isLive.value = false;
  emits('load-data');
};

const onPrintClick = async () => {
  if (!results.value.timePeriod) {
    isChooseError.value = true;
  } else {
    await terminalStore.addQueue({});

    if (addResult.value.success) {
      /*await store.dispatch('getTQueueForm');
      await store.dispatch('printDocument', {
        address: addResult.value?.print?.organization?.description?.address ?? '-',
        serviceName: addResult.value?.print?.service?.description?.name ?? '-',
        ticketNum: addResult.value?.print?.ticket?.num ?? '-',
        ticketPullTime: addResult.value?.print?.ticket?.pullTime ?? '-'
      });*/
      isSuccessPopup.value = true;
    } else {
      isErrorPopup.value = true;
    }
  }
};

onMounted(async () => {
  isLoading.value = true;
  isLive.value = !!availableCurrent.value || !!results.value?.availableCurrent;

  if (results.value?.availableCurrent) {
    selectedData.value = {
      ...results.value.availableCurrent,
      name: ''
    };
  }

  if (results.value.serviceId !== undefined) {
    if (!services.value?.length) {
      info.value?.settings?.isResource ? await terminalStore.getResources() : await terminalStore.getTQueueServices();
    }

    const service = services.value.find(item => item.id === +(results.value.serviceId as string));
    if (service) {
      selectedData.value = {
        ...selectedData.value,
        ...(service.availableCurrent ?? {} as AvailableCurrent),
        name: service.description.name ?? `${ service.id }`
      };
    }
  } else if (results.value.servicePackageId) {
    if (!servicePackages.value?.length) {
      info.value?.settings?.isResource ? await terminalStore.getResources() : await terminalStore.getTQueueServicePackages();
    }

    const servicePackage = servicePackages.value.find(item => item.id === +(results.value.servicePackageId as string));
    if (servicePackage) {
      selectedData.value = {
        ...selectedData.value,
        ...(servicePackage.availableCurrent ?? {} as AvailableCurrent),
        name: servicePackage.description.name ?? `${ servicePackage.id }`
      };
    }
  }

  if (!selectedData.value) {
    await terminalStore.getTQueueForm();
    selectedData.value = {
      ...availableCurrent.value,
      name: selected.value?.service?.name ? selected.value.service.name : `${ results.value.serviceId }`
    };
  }

  isLoading.value = false;
});

watch(() => isLive.value, () => {
  if (isLive.value) {
    isLiveSubmit.value = false;
  }
});

watch([() => isLiveSubmit.value, () => isLive.value], () => {
  if (isLiveSubmit.value) {
    emits('set-footer-buttons', [
      {
        type: 'back', handler: () => {
          isLiveSubmit.value = false;
          isLive.value = true;
          liveKey.value++;
        }
      },
      { type: 'main' }
    ]);
  } else if (isLive.value) {
    emits('set-footer-buttons', [
      { type: 'back', handler: goBack },
      { type: 'main' }
    ]);
  } else {
    emits('set-footer-buttons', [
      {
        type: 'back',
        handler: availableCurrent.value ? showLive : goBack
      },
      { type: 'main' }
    ]);
  }
});
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/utils/sizes";

.queue {
  @extend .flex-col, .page-item;
  width: 100%;
  height: calc(100vh - 10rem - 0.5rem);
  margin: 0;

  &__footer {
    height: 3rem;
  }
}
</style>
