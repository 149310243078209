<template>
  <div class="keyboard__key" :class="itemClass" :style="itemStyle">
    <p class="keyboard__key-text">{{ itemData.symbol }}</p>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import { Key } from "@/types/Keyboard";
import { DataField } from "@/types/Form";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const props = defineProps({
  itemData: {
    type: Object as PropType<Key>,
    default: () => ({})
  },
  size: {
    type: Number,
    required: true
  },
  keyOffset: {
    type: Number,
    default: 10,
  }
});

const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);

const currentField = computed((): DataField => fields.value[currentStep.value]);

const itemClass = computed((): string[] => {
  return [
    `keyboard__key--${ props.itemData.size ?? 1 }`,
    props.itemData.action ? `keyboard__key--${ props.itemData.action }` : '',
    `${ isDisabled.value ? 'disabled' : '' }`
  ];
});

const itemStyle = computed(() => {
  const size = props.itemData.size ?? 1;
  const itemSize = size * props.size + (size - 1) * props.keyOffset + 'px';
  return { width: itemSize, height: props.size + 'px', lineHeight: props.size + 'px' };
});

const isDisabled = computed((): boolean => {
  const phoneCondition = currentField.value?.type === 'ph' && props.itemData.action === 'letters';
  const emailCondition = currentField.value?.type === 'e' && props.itemData.action === 'language';
  return phoneCondition || emailCondition;
});

</script>

<style lang="scss" scoped>
@include setKeyIcons();

.keyboard__key {
  @extend .row-container;
  position: relative;
  justify-content: center;
  box-shadow: 2px 2px 8px #6664;
  border-radius: 15px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:not(:first-of-type) {
    margin-left: 0.3125rem;
  }

  &:not(:last-of-type) {
    margin-right: 0.3125rem;
  }

  &:active {
    background-color: #ffffff;
    box-shadow: inset 4px 4px 10px 0 $blue--tr;

    & > .keyboard__key-text {
      transform: scale(0.9);
    }
  }

  &--language > &-text {
    @extend .bolder, .blue, .upper, .font-size-m;
    position: absolute;
    bottom: 0.3125rem;
    right: 0.3125rem;
    color: $dark-gray !important;
    line-height: initial;
    text-shadow: -1px -1px 4px white;
  }

  &-text {
    @extend .font-size-xl;
    text-transform: lowercase;
  }
}
</style>
