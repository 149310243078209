import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "queue__dates" }
const _hoisted_2 = {
  key: 0,
  class: "queue__dates-message"
}

import QueueDate from "@/components/Queue/QueueDate.vue";
import { useDateTime } from "@/composable/useDatetime";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueDates',
  props: {
  selected: String
},
  emits: ['set-date'],
  setup(__props, { emit: __emit }) {


const emits = __emit;

const terminalStore = useTerminalStore();
const { datePeriods } = storeToRefs(terminalStore);
const { getDateMonth, getWeekday } = useDateTime();

const onClick = (date: string) => emits('set-date', date);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(datePeriods), (item, key) => {
      return (_openBlock(), _createBlock(QueueDate, {
        key: key,
        text: _unref(getDateMonth)(item.date),
        count: item.count,
        isSelected: item.date === __props.selected,
        day: _unref(getWeekday)(item.date),
        onClick: ($event: any) => (onClick(item.date))
      }, null, 8, ["text", "count", "isSelected", "day", "onClick"]))
    }), 128)),
    (!_unref(datePeriods)?.length)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('queue.no_dates')), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})