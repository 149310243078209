import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "stepper" }
const _hoisted_2 = { class: "stepper__item" }

import { computed, PropType } from "vue";
import StepperLine from "@/components/Stepper/StepperLine.vue";
import StepperPoint from "@/components/Stepper/StepperPoint.vue";
import { StepperItem } from "@/types/Stepper";


export default /*@__PURE__*/_defineComponent({
  __name: 'StepperComponent',
  props: {
  items: {
    type: Array as PropType<StepperItem[]>,
    default: () => []
  },
  currentItem: Number
},
  setup(__props) {

const props = __props;

const lineWidth = computed((): number => {
  const maxWight = document.documentElement.clientWidth - 85 * 2;
  return Math.floor(maxWight / (props.items.length + 1));
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(StepperLine, {
        class: "stepper__line",
        isPassed: true,
        width: lineWidth.value
      }, null, 8, ["width"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: "stepper__item"
      }, [
        _createVNode(StepperLine, {
          class: "stepper__line",
          isPassed: key < __props.currentItem,
          width: lineWidth.value
        }, null, 8, ["isPassed", "width"]),
        _createVNode(StepperPoint, {
          class: "stepper__point",
          isPassed: key <= __props.currentItem
        }, null, 8, ["isPassed"]),
        _createElementVNode("p", {
          class: _normalizeClass(["stepper__text", {'stepper__text--required': item.isRequired}])
        }, _toDisplayString(item.text), 3)
      ]))
    }), 128))
  ]))
}
}

})