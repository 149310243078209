import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header__info" }
const _hoisted_2 = { class: "header__info-name" }
const _hoisted_3 = { class: "header__info-phones" }

import EquickLogo from "@/components/Equick/EquickLogo.vue";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app.store";
import { useDateTime } from "@/composable/useDatetime";
import { computed, ref, watch } from "vue";
import { InfoSetting } from "@/types/Info";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickHeader',
  setup(__props) {

const route = useRoute();
const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const appStore = useAppStore();
const { serverTime } = storeToRefs(appStore);
const { getDateTimeStr } = useDateTime();

const time = ref('---');

const settings = computed((): InfoSetting => info.value?.settings);
const phones = computed((): string[] => settings.value?.telephone ?? []);

watch(() => serverTime.value, () => time.value = getDateTimeStr(serverTime.value ?? new Date()));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", {'header--main': _unref(route).path === '/equick/queue-tree' }])
  }, [
    _createVNode(EquickLogo, {
      class: "header__logo",
      place: "header"
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, "№" + _toDisplayString(_unref(info)?.description?.name), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(info)?.description?.address) + ", v" + _toDisplayString(_unref(info)?.settings?.version), 1),
        _createElementVNode("p", null, _toDisplayString(time.value), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phones.value, (phone) => {
          return (_openBlock(), _createElementBlock("p", { key: phone }, "+" + _toDisplayString(phone), 1))
        }), 128))
      ])
    ])
  ], 2))
}
}

})