<template>
  <div class="input__button input__button--enabled" @click="onButtonClick">
    <input-arrow :is-enabled="true"/>
  </div>
</template>

<script lang="ts" setup>
import InputArrow from "@/components/Input/InputArrow.vue";
import { inject } from "vue";

const onClick = inject('on-prev-click') as () => void;

const onButtonClick = () => {
  const back = document.getElementById('back');
  if (back && back.classList.contains('disabled')) {
    return;
  }

  onClick();
}

</script>

<style lang="scss" scoped>
.input__button {
  margin-right: 1.25rem;
  transform: rotate(0.5turn);
}
</style>