<template>
  <main class="queue-period">
    <equick-queue-logos v-if="screenOrientation === 'portrait'" />
    <equick-breadcrumbs />
    <template v-if="!isLoading">
      <equick-queue-period-current v-if="queueType === 'current'" @set-queue-type="v => queueType = v"
                                   @register-current="onRegisterClick" />
      <equick-queue-period-fixed v-else @set-fixed-data="v => fixedData = v" />
    </template>
  </main>

  <footer v-if="!isLoading">
    <div class="footer__button footer__button--back" @click="onBackClick">
      <p class="footer__button-text">{{ $t('footer.button_back') }}</p>
    </div>
    <div v-if="queueType === 'fixed'" class="footer__button footer__button--next"
         :class="{'disabled': !fixedData.date || !fixedData.time}" @click="onNextClick">
      <p class="footer__button-text">{{ $t('footer.button_continue') }}</p>
    </div>
  </footer>

  <equick-loader v-if="isLoading || isTimeLoading" />
  <equick-message-popup v-if="errorText" :message="errorText" @close="goBack" />
</template>

<script setup lang="ts">
import EquickBreadcrumbs from "@/components/Equick/EquickBreadcrumbs.vue";
import { computed, onMounted, ref } from "vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useTerminalStore } from "@/store/terminal.store";
import { useI18n } from "vue-i18n";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import EquickQueuePeriodCurrent from "@/components/Equick/QueuePeriod/EquickQueuePeriodCurrent.vue";
import EquickQueuePeriodFixed from "@/components/Equick/QueuePeriod/EquickQueuePeriodFixed.vue";
import moment from "moment";
import EquickQueueLogos from "@/components/Equick/QueuePeriod/EquickQueueLogos.vue";

const i18n = useI18n();
const { push } = useRouter();
const appStore = useAppStore();
const { currentStep, screenOrientation, path } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, datePeriods, error, availableCurrent, results, addResult } = storeToRefs(terminalStore);

const queueType = ref('current');
const isLoading = ref(true);
const isTimeLoading = ref(false);
const fixedData = ref<{ date: string; time: string; }>({} as { date: string; time: string; });
const errorText = ref('');

const isCurrentAvailable = computed((): boolean => {
  return !!(availableCurrent.value?.quantity > 0
      || availableCurrent.value?.quantity === 0 && availableCurrent.value?.appearanceWaitingMs && availableCurrent.value.appearanceWaitingMs >= 0);
});

const loadDateTimeData = async () => {
  isLoading.value = true;
  await terminalStore.getTQueueDatePeriods();
  if (datePeriods.value?.length) {
    await terminalStore.setTQueueDatePeriod(datePeriods.value[0].date);
    await terminalStore.getTQueueTimePeriods();
  } else {
    errorText.value = error.value === 'timeout' ? i18n.t('message.load_error') : error.value;
  }
  isLoading.value = false;
};

const onBackClick = () => {
  if (queueType.value === 'fixed' && isCurrentAvailable.value) {
    queueType.value = 'current';
  } else {
    goBack();
  }
};

const onNextClick = async () => {
  isTimeLoading.value = true;
  await terminalStore.setTQueueTimePeriod(fixedData.value.time);
  isTimeLoading.value = false;
  if (results.value?.timePeriod) {
    await push('/equick/queue-form');
  } else {
    errorText.value = error.value === 'timeout'
        ? i18n.t('message.queue_time_error', { time: moment(fixedData.value.time).format('HH:mm') })
        : error.value;
  }
};

const onRegisterClick = async () => {
  isLoading.value = true;
  await terminalStore.addLiveQueue();
  isLoading.value = false;
  if (addResult.value?.current) {
    await push('queue-form');
  } else {
    errorText.value = error.value === 'timeout' ? i18n.t('message.info_error') : error.value;
  }
};

const goBack = () => {
  errorText.value = '';
  //appStore.removeLastPathItem();
  push('/equick/queue-tree');
};

onMounted(async () => {
  //appStore.addPathItem('queue-period');
  currentStep.value = 0;
  path.value = ['queue-tree', 'queue-period'];

  await loadDateTimeData();

  queueType.value = info.value?.settings?.currentQueue && isCurrentAvailable.value ? 'current' : 'fixed';
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
main {
  @extend .flex-col;
}

@media (orientation: landscape) {
  main {
    max-height: calc(100% - #{rems(174)});
    height: calc(100% - #{rems(174)});
  }
}

@media (orientation: portrait) {
  main {
    height: calc(100% - #{rems(154)});
  }
}
</style>
