<template>
  <div class="hints">
    <hint-item v-for="(item, key) in currentItems" :key="key" :text="item" @click="onClick(item)"/>
  </div>
</template>

<script lang="ts" setup>
import HintItem from "@/components/Hints/HintItem.vue";
import { onMounted, onUpdated, PropType, ref, watch } from "vue";
import { InputValue } from "@/types/Keyboard";

const props = defineProps({
  items: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  value: {
    type: Array as PropType<InputValue[]>,
    default: () => []
  }
});
const emits = defineEmits(['set-value']);

const currentItems = ref<string[]>(props.items);

const onClick = (item: string) => emits('set-value', item);

const setCurrentItems = () => {
  const str = props.value?.map(item => item.value)?.join('') ?? '';
  const startsWith = props.items.filter(item => item.startsWith(str));
  const includes = props.items.filter(item => item.includes(str));

  console.log(123)
  currentItems.value = [...new Set([...startsWith, ...includes])];
};

onMounted(() => setCurrentItems());

onUpdated(() => console.log('hints'));

watch(() => props.value, setCurrentItems, { deep: true })

</script>

<style lang="scss" scoped>
.hints {
  @extend .flex-row, .horizontal-scroll;
  width: calc(100vw - 5rem * 2 - 1.5rem * 2);
  min-height: calc(2.5rem + 0.75rem + 0.5rem + 4px);
  margin: 0 5rem 0.75rem 5rem;
  padding: 0 1.5rem;
}
</style>
