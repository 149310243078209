<template>
  <div class="input__button" :class="{'input__button--enabled': isEnabled}" @click="onButtonClick">
    <input-arrow :is-enabled="isEnabled"/>
  </div>
</template>

<script lang="ts" setup>
import InputArrow from "@/components/Input/InputArrow.vue";
import { inject, onMounted, onUnmounted, ref } from "vue";

const isEnabled = ref(true);
const onClick = inject('on-next-click') as () => void;

const setState = (e: Event) => {
  const detail = (e as CustomEvent).detail;
  isEnabled.value = !detail;
};

const onButtonClick = () => {
  const next = document.getElementById('next');
  if (next && next.classList.contains('disabled')) {
    return;
  }

  onClick();
}

onMounted(() => window.addEventListener('set-button-state', setState));
onUnmounted(() => window.removeEventListener('set-button-state', setState));
</script>

<style lang="scss" scoped>
.input__button {
  margin-left: 1.25rem;
}
</style>