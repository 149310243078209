<template>
  <div class="breadcrumbs">
    <div v-for="item in path" :key="item" class="breadcrumbs__item" @click="onItemClick(item)">
      {{ $t(`equick.${ item }`) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const { push } = useRouter();
const appStore = useAppStore();
const { path } = storeToRefs(appStore);

const onItemClick = (item: string) => {
  appStore.setLastPathItem(item);
  push(`/equick/${ item }`);
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  @extend .flex-row;

  &__item {
    @extend .row-container, .pointer;
    min-width: max-content;
    @include font(rems(18), 120%, #6E6E6E);

    &:last-child {
      color: black;
      pointer-events: none;
    }

    &:not(:first-child):before {
      margin: 2px 2px 0;
      @include before($--40px, $--40px);
      @include svgBg($equick-stepper-arrow);
    }

    &:not(:first-child):not(:last-child):before {
      filter: invert(0.4);
    }
  }
}

@media (orientation: landscape) {

}

@media (orientation: portrait) {
  .breadcrumbs {
    margin-top: $--4px;
  }
}
</style>
