import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import DataInput from '@/components/Input/DataInput.vue';
import { InputValue } from '@/types/Keyboard';


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoSearchInput',
  emits: ['set-value', 'set-state'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const value = ref<InputValue[]>([]);

const onValueChange = (v: InputValue[]) => emits('set-value', v.map(item => item.value).join(''));

const onKeyboardStateChange = (v: boolean) => {
  emits('set-state', v);
  if (!v) {
    value.value = [];
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DataInput, {
    class: "search",
    "has-hints": false,
    "has-buttons": false,
    keyboard: 'ua',
    initial: value.value,
    "has-toggle-button": false,
    onSetValue: onValueChange,
    onSetKeyboardState: onKeyboardStateChange
  }, null, 8, ["initial"]))
}
}

})