<template>
  <div class="stepper">
    <div class="stepper__item">
      <stepper-line class="stepper__line" :isPassed="true" :width="lineWidth"/>
    </div>
    <div v-for="(item ,key) in items" :key="key" class="stepper__item">
      <stepper-line class="stepper__line" :isPassed="key < currentItem" :width="lineWidth"/>
      <stepper-point class="stepper__point" :isPassed="key <= currentItem"/>
      <p class="stepper__text" :class="{'stepper__text--required': item.isRequired}">{{ item.text }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import StepperLine from "@/components/Stepper/StepperLine.vue";
import StepperPoint from "@/components/Stepper/StepperPoint.vue";
import { StepperItem } from "@/types/Stepper";

const props = defineProps({
  items: {
    type: Array as PropType<StepperItem[]>,
    default: () => []
  },
  currentItem: Number
});

const lineWidth = computed((): number => {
  const maxWight = document.documentElement.clientWidth - 85 * 2;
  return Math.floor(maxWight / (props.items.length + 1));
});
</script>

<style lang="scss" scoped>
.stepper {
  @extend .flex-row;
  width: calc(100vw - 5rem * 2);
  min-height: 2.25rem;
  margin: 0.5rem auto;

  &__line {
    flex: 1;
    position: absolute;
    bottom: 0.25rem;
  }

  &__point {
    position: absolute;
    bottom: -1px;
    left: -7px;
  }

  &__item {
    flex: 1;
    position: relative;
  }

  &__text {
    @extend .font-size-s, .text-center;
    position: relative;
    left: -50%;
    width: 100%;
    max-height: 2rem;
    margin-bottom: 0.5rem;
    line-height: 1.25rem;
    color: $medium-gray;

    &--required:after {
      content: '*';
      display: inline-block;
      position: absolute;
      top: -2px;
      margin-left: 2px;
      color: $red;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>
