import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "queue__header" }
const _hoisted_2 = { key: 0 }


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueHeader',
  props: {
  text: String,
  additional: String,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(__props.text), 1),
    (__props.additional)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.additional), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})