import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }


export default /*@__PURE__*/_defineComponent({
  __name: 'TitleText',
  props: { text: String },
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(__props.text), 1))
}
}

})