import { firstName } from "@/assets/files/first-name";
import { lastName } from "@/assets/files/last-name";
import { middleName } from "@/assets/files/middle-name";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import { DataField } from "@/types/Form";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import { InputValue, Key } from "@/types/Keyboard";

interface Params {
  initialValue: InputValue[];
  isKeyboardShownByDefault: boolean;
  keyboardDefaultType: string;

  maxLength: number;
  mask: string;
}

type Handlers = { [key: string]: () => void };

export const useInput = (params: Params) => {
  const { initialValue, isKeyboardShownByDefault, keyboardDefaultType, maxLength, mask } = params;

  const appStore = useAppStore();
  const { currentStep, keyboardType, isKeyboardShown } = storeToRefs(appStore);
  const terminalStore = useTerminalStore();
  const { fields } = storeToRefs(terminalStore);

  const hintSet: { [key: string]: string[] } = { firstName, lastName, middleName };

  const currentValue = ref<InputValue[]>(initialValue as InputValue[]);
  const startMaskValue = ref<InputValue[]>([]);
  const hints = ref<string[]>([]);
  const isValid = ref(false);
  const lastKey = ref<Key>({} as Key);
  const currentPosition = ref((initialValue?.length ?? 0) - 1);
  const currentKeyboardType = ref(keyboardDefaultType);
  const prevType = ref('ua');
  const hintsKey = ref(0);

  const currentField = computed((): DataField => fields.value?.[currentStep.value] ?? {});

  const onKeyClick = (key: Key) => {
    lastKey.value = key;
    if (key.action) {
      handlers.value?.[key.action]();
    } else {
      handlers.value.symbol();
    }
  };

  const changeType = () => {
    if (['e', 'ph'].includes(currentField.value?.type)) {
      return;
    }

    const type = lastKey.value.symbol ? lastKey.value.symbol : lastKey.value.action;
    if (type === 'letters') {
      currentKeyboardType.value = prevType.value;
    } else {
      currentKeyboardType.value = type as string;
    }

    if (lastKey.value.action === 'language') {
      const nextLanguages = {
        ua: 'ru',
        ru: 'en',
        en: 'ua'
      } as { [key: string]: string };
      prevType.value = nextLanguages[lastKey.value.symbol];
      currentKeyboardType.value = nextLanguages[lastKey.value.symbol];
      keyboardType.value = nextLanguages[lastKey.value.symbol];
    }
  };

  const changeValue = () => {
    if (currentValue.value.length >= maxLength) {
      return;
    }

    const value = { value: lastKey.value.symbol, isMaskSymbol: false, index: currentValue.value.length };
    if (currentPosition.value >= 0 && currentPosition.value !== currentValue.value.length - 1) {
      currentValue.value.splice(currentPosition.value + 1, 0, value);
    } else {
      currentValue.value.push(value);
    }

    if (mask) {
      setFormattedValue();
      setCurrentPosition('add');
    } else {
      currentPosition.value++;
    }

    validate();
  };

  const deleteSymbol = () => {
    if (startMaskValue.value?.length && currentPosition.value < startMaskValue.value.length) {
      return;
    }

    currentValue.value.splice(currentPosition.value, 1);

    if (mask) {
      setFormattedValue();
      setCurrentPosition('delete');
    } else {
      currentPosition.value--;
      if (currentPosition.value === -1 && currentValue.value.length) {
        currentPosition.value = 0;
      }
    }

    validate();
  };

  const clearValue = (hideKeyboard = true) => {
    if (hideKeyboard) {
      isKeyboardShown.value = false;
    }

    currentValue.value = [];

    if (mask) {
      setStartMaskValue(false);
      //setFormattedValue();
    } else {
      currentPosition.value = -1;
    }

    validate();
  };

  const goForward = () => {
    if (currentPosition.value !== currentValue.value.length - 1) {
      currentPosition.value++;

      if (currentValue.value[currentPosition.value].isMaskSymbol) {
        goForward();
      }
    }
  };

  const goBackward = () => {
    if (currentPosition.value > (startMaskValue.value?.length ?? 1) - 1) {
      currentPosition.value--;

      if (currentValue.value[currentPosition.value].isMaskSymbol) {
        goBackward();
      }
    }
  };

  const getSplittedValue = (value: string) => {
    return `${ value }`.split('').map((item, index) => ({ value: item, isMaskSymbol: false, index }));
  };

  const setPosition = (position: number) => currentPosition.value = position;

  const setHintValue = (value: string) => {
    value = value.toUpperCase();
    const spaceItem = [...currentValue.value].reverse().find(item => item.value === ' ');
    if (spaceItem) {
      const afterSpaceSymbols = currentValue.value.slice(spaceItem.index + 1);
      const afterSpaceText = afterSpaceSymbols.map(item => item.value).join('');
      if (value.startsWith(afterSpaceText)) {
        currentValue.value.splice(spaceItem.index + 1);
      }

      currentValue.value = [...currentValue.value, ...getSplittedValue(value)];
      currentPosition.value = currentValue.value.length - 1;
    } else {
      currentValue.value = getSplittedValue(value);
      //todo test
      currentPosition.value = value.length - 1;
    }
  };

  const setFormattedValue = () => {
    const formattedValue: InputValue[] = [];
    const value = currentValue.value.filter(item => !item.isMaskSymbol);
    let counter = 0;
    for (let i = 0; i < mask.length; i++) {
      if (counter >= value.length) {
        break;
      }

      if (mask[i] === '#') {
        formattedValue.push({ ...value[counter++], index: i });
      } else {
        formattedValue.push({ value: mask[i], isMaskSymbol: true, index: i });
      }
    }

    currentValue.value = formattedValue;
  };

  const setCurrentPosition = (action: string) => {
    if (currentValue.value.length <= mask.length) {
      if (currentPosition.value + 1 === currentValue.value.length - 1) {
        currentPosition.value = currentValue.value.length - 1;
      } else if (currentValue.value.length !== mask.length) {
        let item: InputValue = {} as InputValue;
        if (action === 'add') {
          item = currentValue.value.slice(currentPosition.value + 1).find(item => !item.isMaskSymbol) ?? {} as InputValue;
        } else if (action === 'delete') {
          item = currentValue.value.slice(0, currentPosition.value).reverse().find(item => !item.isMaskSymbol) ?? {} as InputValue;
        }
        currentPosition.value = currentValue.value.indexOf(item);
      } else if (currentPosition.value + 1 < mask.length) {
        currentValue.value.length = mask.length;
        const item = currentValue.value.slice(currentPosition.value + 1).find(item => !item.isMaskSymbol) ?? {} as InputValue;
        currentPosition.value = item.index;
      }
    }
  };

  const setStartMaskValue = (isInitial = false) => {
    if (isInitial && initialValue?.length) {
      currentValue.value = initialValue;
      currentPosition.value = initialValue.length - 1;
    } else if (mask) {
      currentValue.value = [...startMaskValue.value];
      currentPosition.value = startMaskValue.value.length - 1;
    }
  };

  const validate = () => {
    const { inputRegExp, required } = currentField.value;
    if (required && !currentValue.value.length) {
      isValid.value = false;
    } else if ((required || initialValue?.length) && inputRegExp) {
      const strValue = currentValue.value.map(item => item.value).join('');
      if (currentField.value?.type === 'ph') {
        const phone = strValue.slice((startMaskValue.value?.length - 1) ?? 0).replaceAll(' ', '').replaceAll('-', '');
        isValid.value = new RegExp(inputRegExp).test(phone);
      } else {
        isValid.value = new RegExp(inputRegExp).test(strValue);
      }
    } else {
      isValid.value = true;
    }
  };

  const onFieldClick = (e: Event) => {
    if ((e.target as HTMLElement)?.getAttribute('class')?.includes('input__field')) {
      isKeyboardShown.value = !isKeyboardShown.value;
    }
  };

  const handlers = ref<Handlers>({
    language: changeType,
    numbers: changeType,
    letters: changeType,
    symbol: changeValue,
    backspace: deleteSymbol,
    forward: goForward,
    backward: goBackward
  });

  onMounted(() => {
    isKeyboardShown.value = isKeyboardShownByDefault;

    if (mask) {
      startMaskValue.value = [];
      for (let i = 0; i < mask.length; i++) {
        if (mask[i] !== '#') {
          startMaskValue.value.push({ value: mask[i], isMaskSymbol: true, index: i });
        } else {
          break;
        }
      }
    }

    validate();
    setStartMaskValue(true);
  });

  /*watch(() => currentValue.value, (value: InputValue[]) => {
    emits('set-value', value, isValid.value);
    if (!value.length) {
      setStartMaskValue();
    }
  }, { deep: true });*/

  /*watch(() => isKeyboardShown.value, () => {
    emits('set-keyboard-state', isKeyboardShown.value);
    if (!isKeyboardShown.value) {
      clearValue();
    }
  });*/

  watchEffect(() => {
    if (currentField.value?.hint) {
      hints.value = hintSet?.[currentField.value.hint] ?? [];
    } else {
      hints.value = [];
    }
  });

  return {
    hintSet, currentValue, startMaskValue, hints, isValid, isKeyboardShown, lastKey, currentPosition,
    currentKeyboardType, prevType, hintsKey, currentField, handlers,

    onKeyClick, changeType, changeValue, deleteSymbol, clearValue, goForward, goBackward, getSplittedValue, setPosition,
    setHintValue, setFormattedValue, setCurrentPosition, setStartMaskValue, validate, onFieldClick,
  }
};
