<template>
  <div>
    <inner-loader :isLoading="isLoading"/>
    <div v-show="!isLoading" :class="$attrs.class">
      <breadcrumbs-component/>
      <div class="content">
        <title-text v-if="currentType === 'singleSelect'" :text="title"/>
        <tree-items v-if="currentType === 'singleSelect'" :items="treeItems" @itemClick="onItemClick"/>
        <data-input v-else-if="isDataInput" v-bind="inputParams" :key="currentType"/>
        <p v-else>{{ currentType }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import TitleText from "@/components/Text/TitleText.vue";
import TreeItems from "@/components/Tree/TreeItems.vue";
import BreadcrumbsComponent from "@/components/Text/BreadcrumbsComponent.vue";
import DataInput from "@/components/Input/DataInput.vue";

const emits = defineEmits(['set-footer-buttons']);

const treeItems = ref([]);
const inputParams = ref({});
const isLoading = ref(true);
//const isDone = ref(false);
const title = ref('');
const currentType = ref('');

const isDataInput = computed((): boolean => ['FLN', 'taxNumber', 'date'].includes(currentType.value));

const onItemClick = async (item: string, index: number) => {
  /*await this.getAnswer({
    paymentSession: this.session,
    data: { select: index }
  });*/
  await setQuestionData();
};

const onNextClick = async () => {
  /*const stringValue = enteredValue.map(item => item.value).join('').trim();
  await this.getAnswer({
    paymentSession: this.session,
    data: { value: stringValue }
  });*/
  await setQuestionData();
};

const setQuestionData = async () => {
  isLoading.value = true;
  /*await this.getQuestion({
    language: this.language,
    paymentSession: this.session
  });*/

  /*if (!this.question.done) {
    const { variantList, text, test, type } = this.question.request;
    this.currentType = type;

    if (type === 'singleSelect') {
      this.treeItems = variantList;
      this.title = text;
    } else if (this.isDataInput) {
      this.inputParams = { ...inputParams[type], label: test };
      this.title = test;
      this.$emit('setFooterButtons',
          [{ text: 'Назад', type: 'back' }, { text: 'Головна', type: 'main' },
            { text: 'Далі', type: 'next', handler: this.handlerNextClick }]
      );
    }

    this.addPathItem(text);
    this.breadcrumbs = this.path.getTexts(this.language);
  } else {
    this.$router.push('/results');
  }*/

  isLoading.value = false;
}

onMounted(() => {
  emits('set-footer-buttons', [{ type: 'back' }, { type: 'main' }]);
  setQuestionData();
});

</script>

<style lang="scss" scoped>
.main {
  justify-content: flex-start;
}
</style>
