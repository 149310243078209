import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "footer-button__text" }

import { PropType } from 'vue';
import { FooterButton } from '@/types/Footer';
import { useRouter } from 'vue-router';
import { useMain } from '@/composable/useMain';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterButton',
  props: {
  buttonData: {
    type: Object as PropType<FooterButton>,
    default: () => ({})
  }
},
  setup(__props) {

const props = __props;

const router = useRouter();
const appStore = useAppStore();
const { currentGroup, pageIndent, path } = storeToRefs(appStore);
const { isGotovo } = useMain();

const onClick = async () => {
  const { type, handler, backLink } = props.buttonData;
  if (handler) {
    handler();
  } else if (backLink) {
    appStore.removeLastPathItem();
    await router.push(backLink);
  } else if (type === 'back') {
    appStore.removeLastPathItem();
    await router.back();
  } else if (type === 'main') {
    if (isGotovo.value) {
      if (router.currentRoute.value.path === '/queue-tree') {
        currentGroup.value = '';
        pageIndent.value = 0;
      } else {
        await router.push('/queue-tree');
      }
    } else {
      path.value = [];
      await router.push('/');
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["footer-button", [`footer-button--${__props.buttonData.type}`, {disabled: __props.buttonData.isDisabled}]]),
    id: __props.buttonData.type,
    onClick: onClick
  }, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.buttonData?.text ? __props.buttonData.text : _ctx.$t(`footer.button_${ __props.buttonData.type }`)), 1)
  ], 10, _hoisted_1))
}
}

})