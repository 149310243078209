<template>
  <div class="keyboard" :class="`keyboard--${size}`">
    <div v-for="(row, rowKey) in keys" class="keyboard__row" :key="rowKey" :style="{height: `${keySize}px`}">
      <key-component v-for="(item, itemKey) in row" :key="itemKey" :itemData="item" :size="keySize"
                     :key-offset="screenOrientation.value === 'landscape' ? 10 : 8" @click="onClick(item)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import KeyComponent from '@/components/Keyboard/KeyComponent.vue';
import { Key } from '@/types/Keyboard';
import { useKeyboard } from "@/composable/useKeyboard";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";

const props = defineProps({
  keyboardType: {
    type: String,
    default: ''
  }
});
const emits = defineEmits(['key-click']);

const appStore = useAppStore();
const { screenOrientation } = storeToRefs(appStore);
const {
  keys, size, keySize
} = useKeyboard({
  keyboardType: props.keyboardType,
  keyboardOffset: screenOrientation.value === 'landscape' ? 24 : 12,
  keyOffset: screenOrientation.value === 'landscape' ? 10 : 8,
  onClick: (key: Key) => emits('key-click', key)
});

const onClick = (key: Key) => emits('key-click', key);
</script>

<style lang="scss" scoped>
.keyboard {
  @extend .flex-col;
  align-items: center;
  justify-content: center;
  margin: auto;

  &__row {
    @extend .flex-row;
    height: max-content;
  }

  &__key {
    background-color: white;

    &:deep(.keyboard__key-text) {
      @include font($--24px, 120%, black, 400);
      text-transform: uppercase;
    }

    &:active, &:focus {
      background-color: #ffffff;
      border: 1.5px solid $equick-secondary;
      box-shadow: 2px 2px 8px #66666644;

      &:deep(.keyboard__key-text) {
        transform: scale(1);
      }
    }
  }
}

@media (orientation: landscape) {
  .keyboard {
    &__row {
      margin-bottom: rems(10);
    }

    &__key {
      &--backward, &--forward {
        background-size: 2.25vw;
      }

      &--numbers, &--backspace, &--letters {
        background-size: 3.25vw;
      }

      &--language {
        background-size: 5.5vw;

        &:deep(.keyboard__key-text) {
          font-size:  $--24px;
          font-weight: 500;
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .keyboard {
    position: relative;
    left: rems(-12);
    margin-top: rems(36);

    &__row {
      margin-bottom: $--8px;
    }

    &__key {
      border-radius: 8px;

      &:not(&:last-of-type) {
        margin-right: $--4px;
      }

      &:not(&:first-of-type) {
        margin-left: $--4px;
      }

      &:deep(.keyboard__key-text) {
        @include font(rems(30), 120%, black, 400);
      }

      &--language:deep(.keyboard__key-text) {
        font-size:  $--24px;
      }
    }
  }
}
</style>
