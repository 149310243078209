import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tree-items" }

import TreeItem from "@/components/Tree/TreeItem.vue";
import { PropType } from "vue";
import { TreeItem as TreeItemType } from "@/types/Tree";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeItems',
  props: {
  items: {
    type: Array as PropType<TreeItemType[]>
  }
},
  emits: ['item-click'],
  setup(__props, { emit: __emit }) {


const emits = __emit;

const onClick = (item: TreeItemType, key: string) => emits('item-click', item, key);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, key) => {
      return (_openBlock(), _createBlock(TreeItem, {
        key: key,
        "item-data": item,
        size: __props.items.length > 2 ? 's' : 'm',
        onClick: ($event: any) => (onClick(item, key))
      }, null, 8, ["item-data", "size", "onClick"]))
    }), 128))
  ]))
}
}

})