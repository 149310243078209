<template>
  <div class="queue">
    <queue-live v-if="info?.settings?.currentQueue"/>
    <queue-date-time v-if="info?.settings?.fixedQueue"/>
  </div>
</template>

<script lang="ts" setup>
import QueueLive from "@/components/Queue/QueueLive.vue";
import QueueDateTime from "@/components/Queue/QueueDateTime.vue";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
</script>

<style lang="scss" scoped>
@import "../../assets/styles/utils/sizes";

.queue {
  @extend .flex-row, .page-item;
  height: calc(100vh - #{$header} - #{$breadcrumbs} - #{$title} - #{$footer} - 0.5rem);
  margin-top: 0.5rem;
}
</style>
