import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "queue" }

import QueueLive from "@/components/Queue/QueueLive.vue";
import QueueDateTime from "@/components/Queue/QueueDateTime.vue";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultQueue',
  setup(__props) {

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(info)?.settings?.currentQueue)
      ? (_openBlock(), _createBlock(QueueLive, { key: 0 }))
      : _createCommentVNode("", true),
    (_unref(info)?.settings?.fixedQueue)
      ? (_openBlock(), _createBlock(QueueDateTime, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}
}

})