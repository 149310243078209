<template>
  <div class="input__keyboard" :class="{'input__keyboard--active': isActive}" @click="onClick"></div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps({ state: Boolean });

const isActive = ref(props.state);

const onClick = () => isActive.value = !isActive.value;
</script>

<style lang="scss" scoped>
.input__keyboard {
  position: relative;
  left: -2px;
  width: 5.3125rem;
  height: calc(4.375rem + 1.5px * 2);
  @include svgBg($keyboard, 3rem, $light-gray--tr);

  &--active {
    background-color: $blue;
  }
}
</style>
