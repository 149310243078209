import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__info" }

import { ref, watch } from 'vue';
import { useDateTime } from '@/composable/useDatetime';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderInfo',
  setup(__props) {

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const appStore = useAppStore();
const { serverTime } = storeToRefs(appStore);
const { getDateTimeStr } = useDateTime();

const time = ref('');

watch(() => serverTime.value, () => time.value = getDateTimeStr(serverTime.value));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, "№" + _toDisplayString(_unref(info)?.description?.name), 1),
    _createElementVNode("p", null, _toDisplayString(_unref(info)?.description?.address) + ", v" + _toDisplayString(_unref(info)?.settings?.version), 1),
    _createElementVNode("p", null, _toDisplayString(time.value), 1)
  ]))
}
}

})