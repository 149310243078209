import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input" }
const _hoisted_2 = { class: "input-box" }

import InputPreviousButton from '@/components/Input/InputPreviousButton.vue';
import InputLabel from '@/components/Input/InputLabel.vue';
import InputField from '@/components/Input/InputField.vue';
import InputEraseButton from '@/components/Input/InputEraseButton.vue';
import InputNextButton from '@/components/Input/InputNextButton.vue';
import { computed } from 'vue';
import { DataField } from '@/types/Form';
import InputKeyboardButton from '@/components/Input/InputKeyboardButton.vue';
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputComponent',
  props: {
  currentValue: Array,
  isValid: Boolean,
  currentPosition: Number,
  hasButtons: {
    type: Boolean,
    default: true
  },
  hasToggleButton: Boolean,
  toggleState: Boolean
},
  emits: ['clear-value', 'set-position', 'change-toggle-state'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);

const currentField = computed((): DataField => fields.value[currentStep.value]);

const onClearClick = () => emits('clear-value');

const onFieldClick = (position: number) => emits('set-position', position);

const onToggleClick = () => emits('change-toggle-state', !props.toggleState);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.hasButtons)
      ? (_openBlock(), _createBlock(InputPreviousButton, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (currentField.value?.label)
        ? (_openBlock(), _createBlock(InputLabel, {
            key: 0,
            text: currentField.value.label
          }, null, 8, ["text"]))
        : _createCommentVNode("", true),
      _createVNode(InputField, {
        "current-value": __props.currentValue,
        "current-position": __props.currentPosition,
        "is-valid": __props.isValid,
        onClick: onFieldClick
      }, null, 8, ["current-value", "current-position", "is-valid"]),
      _createVNode(InputEraseButton, { onClick: onClearClick })
    ]),
    (__props.hasToggleButton)
      ? (_openBlock(), _createBlock(InputKeyboardButton, {
          state: __props.toggleState,
          key: __props.toggleState,
          onClick: onToggleClick
        }, null, 8, ["state"]))
      : _createCommentVNode("", true),
    (__props.hasButtons)
      ? (_openBlock(), _createBlock(InputNextButton, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}
}

})