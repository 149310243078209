import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-button__text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickBaseButton',
  props: {
  text: String,
  color: {
    type: String,
    default: 'primary'
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-button", __props.color])
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(__props.text), 1)
  ], 2))
}
}

})