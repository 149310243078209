import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "viewBox"]
const _hoisted_2 = ["width", "fill"]

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StepperLine',
  props: {
  width: Number,
  isPassed: Boolean,
},
  setup(__props) {

const props = __props;

const color = computed((): string => props.isPassed ? '#00B2E0' : '#CCCCCC80');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: "4",
    viewBox: `0 0 ${__props.width} 4`,
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("rect", {
      width: __props.width,
      height: "4",
      rx: "2",
      fill: color.value,
      "stroke-linecap": "round"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})