import { RouteRecordRaw } from "vue-router";
import EquickQueueTreeView from "@/views/Equick/EquickQueueTreeView.vue";
import EquickQueuePeriodView from "@/views/Equick/EquickQueuePeriodView.vue";
import EquickQueueFormView from "@/views/Equick/EquickQueueFormView.vue";
import EquickQueueResultsView from "@/views/Equick/EquickQueueResultsView.vue";

/*export const equickRoutes: RouteRecordRaw[] = [{
  path: '/equick',
  children: [{
    path: '/queue-tree',
    name: 'EquickQueueTreeView',
    component: EquickQueueTreeView
  }, /!*{
    path: '/queue-period',
    name: 'EquickQueuePeriodView',
    component: QueuePeriodView
  }, {
    path: '/queue-form',
    name: 'EquickQueueFormView',
    component: QueueFormView
  }, {
    path: '/queue-results',
    name: 'EquickQueueResultsView',
    component: QueueResultsView
  }*!/]
}];*/
export const equickRoutes: RouteRecordRaw[] = [{
  path: '/equick/queue-tree',
  name: 'EquickQueueTreeView',
  component: EquickQueueTreeView,
}, {
  path: '/equick/queue-period',
  name: 'EquickQueuePeriodView',
  component: EquickQueuePeriodView,
},{
  path: '/equick/queue-form',
  name: 'EquickQueueFormView',
  component: EquickQueueFormView,
}, {
  path: '/equick/queue-results',
  name: 'EquickQueueResultsView',
  component: EquickQueueResultsView
}];
