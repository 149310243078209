import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "queue__live" }
const _hoisted_2 = {
  key: 0,
  class: "queue__live-available"
}
const _hoisted_3 = { class: "queue__message" }
const _hoisted_4 = { class: "queue__value" }
const _hoisted_5 = { class: "queue__message" }
const _hoisted_6 = { class: "queue__value" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "queue__message" }
const _hoisted_9 = { class: "queue__value" }
const _hoisted_10 = {
  key: 2,
  class: "queue__live-empty"
}
const _hoisted_11 = { class: "queue__message" }
const _hoisted_12 = { class: "queue__message" }

import QueueHeader from '@/components/Queue/QueueHeader.vue';
import QueueLiveButton from "@/components/Queue/QueueLiveButton.vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueLive',
  setup(__props) {

const i18n = useI18n();
const terminalStore = useTerminalStore();
const { availableCurrent } = storeToRefs(terminalStore);

const getFormattedTime = (value: number) => {
  const time = [];
  if (availableCurrent.value?.moreThan) {
    time.push(i18n.t('queue.more_than'));
  }

  if (value) {
    const hours = Math.floor(value / 3_600_000);
    if (hours > 0) {
      time.push(`${ hours } ${ i18n.t('units.hours') }.`);
    }
    const minutes = Math.floor(value / 60_000 - hours * 3_600_000);
    if (minutes > 0) {
      time.push(`${ minutes } ${ i18n.t('units.minutes') }.`);
    }
  }

  return time.join(' ');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(QueueHeader, {
      text: _ctx.$t('queue.live_1'),
      additional: _ctx.$t('queue.live_2')
    }, null, 8, ["text", "additional"]),
    (_unref(availableCurrent)?.quantity > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_unref(availableCurrent)?.approximateWaitingMs && _unref(availableCurrent)?.approximateWaitingMs / 60_000 > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('queue.approximate_time')) + ":", 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(getFormattedTime(_unref(availableCurrent)?.approximateWaitingMs)), 1)
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('queue.in_queue')) + ":", 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(availableCurrent).queueLength ?? 0), 1),
          _createVNode(QueueLiveButton)
        ]))
      : (_unref(availableCurrent)?.quantity === 0 && _unref(availableCurrent)?.appearanceWaitingMs >= 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('queue.appearance_time')) + ":", 1),
            _createElementVNode("p", _hoisted_9, _toDisplayString(getFormattedTime(_unref(availableCurrent)?.appearanceWaitingMs)), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('queue.no_near_1')), 1),
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('queue.no_near_2')), 1)
          ]))
  ]))
}
}

})