<template>
  <popup-container :has-close-button="false" teleport=".page">
    <p class="popup__message">{{ $t('timer_question') }}</p>
    <div class="popup__timer" :style="`--outer_percent_deg: ${(currentValue) * 100 / delayOff}%`">
      <p class="popup__timer-text">{{ moment.utc(currentValue * 1000).format('mm:ss') }}</p>

      <svg>
        <circle :class="{'inactive': currentValue < 1}" r="150" cx="160" cy="160"
                :style="`animation-duration: ${delayOff}s;`"></circle>
      </svg>
    </div>
    <equick-base-button :text="$t('footer.button_continue')" @click="onButtonCLick" />
  </popup-container>
</template>

<script lang="ts" setup>
import PopupContainer from '@/components/Popups/PopupContainer.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app.store';
import { EnteredValues } from '@/types/Keyboard';
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";
import moment from 'moment';

const emits = defineEmits(['close']);

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const appStore = useAppStore();
const { enteredValues, currentStep } = storeToRefs(appStore);
const router = useRouter();

const delayOff = computed((): number => info.value.settings.timer.delayOff ?? 90);

const currentValue = ref(delayOff.value);
const intervalId = ref(0);

const onButtonCLick = () => {
  clearInterval(intervalId.value);
  emits('close');
};

onMounted(() => {
  intervalId.value = setInterval(() => {
    if (currentValue.value === 0) {
      clearInterval(intervalId.value);
      emits('close');
      enteredValues.value = {} as EnteredValues;
      currentStep.value = 0;
      router.push('/');
    } else {
      currentValue.value--;
    }
  }, 1000);
});

onUnmounted(() => clearInterval(intervalId.value));
</script>

<style lang="scss" scoped>
.popup {
  &__message {
    align-self: flex-start;
    margin-bottom: rems(60);
  }

  &__timer {
    @extend .col-container;
    position: relative;
    justify-content: center;
    width: rems(300);
    height: rems(300);
    background-color: white;
    border: 1px solid #DEDDDD;
    border-radius: 50%;
    box-shadow: 0 4px 4px 0 #00000040;
    z-index: 20;

    &-text {
      @include font(rems(48), 120%, black);
    }
  }
}

.base-button {
  justify-content: center;
  width: rems(381);
  margin: $--40px auto $--16px;
}

$size: 300px;
$offset: 20px;
$stroke-width: 12px;

svg {
  position: absolute;
  top: calc(#{$offset} / -2);
  right: calc(#{$offset} / -2);
  width: calc(#{$size} + #{$offset});
  height: calc(#{$size} + #{$offset});
  transform: rotateY(180deg) rotateZ(-90deg) scaleY(-1);
  z-index: 100;
}

svg circle {
  stroke-dasharray: calc(#{$size} * 3.25);
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: #{$stroke-width};
  stroke: $equick-primary;
  fill: none;
  animation: countdown linear infinite forwards;

  &.inactive {
    animation: none;
    stroke-dashoffset: calc(#{$size} * 3.25);
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: calc(#{$size} * 3.25);
  }
}
</style>
