import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "queue__datetime" }
const _hoisted_2 = { class: "queue__datetime-title" }
const _hoisted_3 = { class: "queue__datetime-panel" }

import { ref, watchEffect } from 'vue';
import { useDateTime } from '@/composable/useDatetime';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import GotovoQueueDates from '@/components/Queue/Gotovo/GotovoQueueDates.vue';
import GotovoQueueTimes from '@/components/Queue/Gotovo/GotovoQueueTimes.vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoQueueDateTime',
  emits: ['print'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const terminalStore = useTerminalStore();
const { datePeriods, results } = storeToRefs(terminalStore);
const { getDateMonth } = useDateTime();

const selectedDate = ref('');
const selectedTime = ref('');
const isLoading = ref(false);
const error = ref('');

const setSelectedDate = async (date: string) => {
  const prevSelected = selectedDate.value;
  selectedDate.value = date;
  selectedTime.value = '';
  isLoading.value = true;
  await terminalStore.setTQueueDatePeriod(date);
  if (results.value?.datePeriod) {
    await terminalStore.getTQueueTimePeriods();
  } else {
    selectedDate.value = prevSelected;
    error.value = `Не вдалось завантажити вільний час для дати ${ getDateMonth(date) }`;
  }
  isLoading.value = false;
};

const setSelectedTime = async (time: string) => {
  selectedTime.value = time;
  await terminalStore.setTQueueTimePeriod(time);
};

const onPrintClick = () => emits('print');

watchEffect(() => selectedDate.value = datePeriods.value[0]?.date);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("choose_time")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(GotovoQueueDates, {
          selected: selectedDate.value,
          onSetDate: setSelectedDate
        }, null, 8, ["selected"]),
        _createVNode(GotovoQueueTimes, {
          selected: selectedTime.value,
          "is-loading": isLoading.value,
          onSetTime: setSelectedTime
        }, null, 8, ["selected", "is-loading"])
      ])
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(["queue__datetime-button", {disabled: !selectedDate.value || !selectedTime.value}]),
      onClick: onPrintClick
    }, _toDisplayString(_ctx.$t("button.print")), 3),
    (error.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 0,
          message: error.value,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = ''))
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})