<template>
  <div class="menu-container">
    <menu-item v-for="item in items" :key="item.id" :item-data="item" :has-icon="hasIcons"
               @click="onClick(item)"/>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { PropType } from "vue";
import MenuItem from "@/components/Menu/MenuItem.vue";
import { IncludeItem } from "@/types/Info";

defineProps({
  items: Array as PropType<IncludeItem[]>,
  hasIcons: {
    type: Boolean,
    default: true,
  }
});

const router = useRouter();

const onClick = (item: IncludeItem) => {
  if (item.type === 'queue') {
    router.push('/queue-tree');
  } else {
    router.push(`/tree`);
  }
};

</script>

<style lang="scss" scoped>
@import "src/assets/styles/utils/sizes";

.menu-container {
  @extend .flex-row;
  justify-self: flex-end;
  width: 100vw;
  height: $menu-items;
}
</style>
