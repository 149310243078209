<template>
  <div class="header__rest">
    <p class="header__rest-title">{{ $t('account') }}:</p>
    <p class="header__rest-value">{{ rest.toFixed(2) }} <span>{{ $t('uah') }}</span></p>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const rest = ref(0);
</script>

<style lang="scss" scoped>
.header__rest {
  @extend .flex-col;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 0.5rem;
  padding: 0 0.625rem;

  &-title, &-value {
    @extend .bold, .text-center;
    font-size: 16px;
  }

  &-title {
    color: $dark-gray;
  }

  &-value {
    @extend .bolder, .blue;

    & > span {
      @extend .font-size-xs;
    }
  }
}

@media (min-width: 2000px) {
  .header__rest {
    &-title, &-value {
      font-size: 1.25vh;
    }

    &-value > span {
      font-size: 1vh;
    }
  }
}
</style>
