import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import PopupContainer from "@/components/Popups/PopupContainer.vue";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MessagePopup',
  props: {
  message: String,
  additional: String,
  teleport: {
    type: String,
    default: null,
  },
  hasButton: {
    type: Boolean,
    default: true
  },
  buttonText: {
    type: String,
    default: null
  }
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {


const emits = __emit;

const onButtonClick = () => emits('close');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PopupContainer, {
    class: "message",
    message: __props.message,
    additional: __props.additional,
    "has-close-button": __props.hasButton,
    teleport: __props.teleport,
    onClose: onButtonClick
  }, {
    default: _withCtx(() => [
      (__props.hasButton)
        ? (_openBlock(), _createBlock(DefaultButton, {
            key: 0,
            text: __props.buttonText ?? _ctx.$t('button.ok'),
            onClick: onButtonClick
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["message", "additional", "has-close-button", "teleport"]))
}
}

})