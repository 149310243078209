<template>
  <inner-loader :is-loading="isLoading"/>
  <div :class="$attrs.class">
    <div class="text-container">
      <title-text :text="$t('welcome_title')"/>
      <subtitle-text :text="$t('welcome_subtitle')"/>
    </div>
    <menu-items v-show="!isLoading" :items="items"/>
    <languages-component />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import TitleText from "@/components/Text/TitleText.vue";
import SubtitleText from "@/components/Text/SubtitleText.vue";
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import MenuItems from "@/components/Menu/MenuItems.vue";
import { IncludeItem } from "@/types/Info";
import LanguagesComponent from "@/components/Languages/LanguagesComponent.vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const emits = defineEmits(['set-footer-buttons']);

const appStore = useAppStore();
const { path } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

const isLoading = ref(false);

const items = computed((): IncludeItem[] => info.value?.include?.filter(i => i.active));

onMounted(() => {
  path.value = [];
  emits('set-footer-buttons', []);
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/utils/sizes";

.main {
  position: relative;
  justify-content: space-between;
}

.text-container {
  @extend .flex-col;
  height: calc(100vh - #{$header} - #{$menu-items});
  justify-content: center;

  & > .title {
    margin-bottom: 1.25rem;
  }
}
</style>
