<template>
  <div v-if="isShown" class="loader" :class="[loaderClass, state]" :style="height">
    <div v-if="!isGotovo">
      <div class="loader__items">
        <div v-for="n in 8" class="loader__item" :class="`loader__item--${n}`" :key="n"></div>
      </div>
      <p class="loader__text">{{ loaderText }}</p>
    </div>
    <img v-else src="images/preloader.png" alt="loader" />
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useMain } from "@/composable/useMain";

const props = defineProps({
  text: {
    type: String,
    default: null
  },
  isLoading: Boolean,
});

const i18n = useI18n();
const { isGotovo } = useMain();

const isShown = ref(false);
const stateTimeout = ref(0);
const shownTimeout = ref(0);
const state = ref('');
const loaderClass = ref('');
const height = ref('');

const loaderText = props.text ?? i18n.t('loading');

const setHeight = () => {
  let total = 0;
  ['help-line', 'header', 'footer'].map(id => {
    const el = document.getElementsByClassName(id)?.[0];
    if (el) {
      total += el.clientHeight;
    }
  });
  height.value = total > 0 ? `height: ${ document.documentElement.clientHeight - total }px;` : '';
}

watchEffect(() => {
  clearTimeout(stateTimeout.value);
  clearTimeout(shownTimeout.value);

  if (props.isLoading) {
    setHeight();
    const isFooterExists = !!document.getElementsByClassName('footer')?.length;
    loaderClass.value = `loader--${ isFooterExists ? 's' : 'm' }`;
    stateTimeout.value = setTimeout(() => {
      state.value = 'active';
      isShown.value = true;
    }, 100);
  } else {
    if (isShown.value) {
      state.value = 'hiding';
      shownTimeout.value = setTimeout(() => {
        isShown.value = false;
      }, 100);
    }
  }
})

</script>

<style lang="scss" scoped>
@import '../../assets/styles/utils/animations';
@import '../../assets/styles/utils/sizes';

.loader {
  @extend .flex-col, .full-page-container;
  position: fixed;
  top: $header;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  z-index: 1000;

  &.active {
    animation: showByFading .3s 1 both;
  }

  &.hiding {
    animation: hideByFading .25s 1 both;
  }

  &--s {
    min-height: calc(100vh - #{$header} - #{$footer});
  }

  &--m {
    min-height: calc(100vh - #{$header});
  }

  &__items {
    @extend .row-container;
    position: relative;
    width: 10rem;
    height: 10rem;
    margin: auto;
  }

  &__item {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 1.25rem / 2);
    left: calc(50% - 1.25rem / 2);
    background-color: $blue;
  }

  &__text {
    @extend .bold, .font-size-default;
    width: 100%;
    color: $blue;
    text-align: center;
  }
}

@for $i from 1 through 8 {
  .loader__item--#{$i} {
    $deg: #{45 * ($i - 1)}deg;
    -webkit-transform: rotate($deg) translate(0, -2.35rem);
    -moz-transform: rotate($deg) translate(0, -2.35rem);
    transform: rotate($deg) translate(0, -2.35rem);
    opacity: 0;
    animation: rotate 1.1s linear #{calc(1.1 / 8) * $i}s infinite reverse;
  }
}

@keyframes rotate {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

.gotovo .loader {
  top: 10rem;
  background-color: #ccc3;
  pointer-events: none;

  &--s {
    min-height: calc(100vh - 10rem - #{$footer});
    max-height: calc(100vh - 10rem - #{$footer});
  }

  &--m {
    min-height: calc(100vh - 10rem);
    max-height: calc(100vh - 10rem);
  }
}
</style>
