<template>
  <div v-if="!isLoading && !isError" class="page" :class="[{'no-cursor': !info.settings?.isCursor}, template]"
       :style="screenHeight" @contextmenu.stop.prevent @click="renewTimer">
    <template v-if="!initError">
      <gotovo-header v-if="isGotovo" />
      <equick-header v-else-if="isEquick" />
      <default-header v-else />
      <router-view v-slot="{ Component }">
        <component v-if="!isEquick" class="main" :class="{'main--m': !footerButtons.length}" :is="Component"
                   @setFooterButtons="setFooterButtons" />
        <component v-else :is="Component" :key="screenKey" />
      </router-view>
      <footer-component v-if="footerButtons.length" :buttons="footerButtons" :key="footerKey" />

      <template v-if="isTimerShown">
        <equick-popup-timer v-if="isEquick" @close="renewTimer" />
        <popup-timer v-else @close="renewTimer" />
      </template>
    </template>
    <component v-else :is="messagePopupComponent" teleport="body" :message="$t('error')" :additional="initError"
               :has-button="false" />
  </div>
  <inner-loader v-if="isLoading && isGotovo" class="gotovo-loader" :is-loading="isLoading" />
  <component v-if="isError" :is="messagePopupComponent" :message="$t('error')"
             :additional="$t('message.server_error')" :button-text="$t('button.reload')" @close="onReloadClick" />
  <component v-if="!isError && isStatusError" :is="messagePopupComponent" :message="$t('error')"
             :additional="$t('message.server_error')" :button-text="$t('button.reload')" @close="onReloadClick" />
</template>

<script lang="ts" setup>
import DefaultHeader from '@/components/Header/DefaultHeader.vue';
import FooterComponent from '@/components/Footer/FooterComponent.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { FooterButton } from '@/types/Footer';
import PopupTimer from '@/components/Popups/PopupTimer.vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import GotovoHeader from '@/components/Header/GotovoHeader.vue';
import { useMain } from '@/composable/useMain';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import { InitResult } from '@/types/Init';
import { EventSourcePolyfill, EventSource } from 'event-source-polyfill';
import { useAppStore } from '@/store/app.store';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';
import { useApi } from '@/composable/useApi';
import EquickHeader from "@/components/Equick/EquickHeader.vue";
import EquickPopupTimer from "@/components/Equick/Popup/EquickPopupTimer.vue";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import { Info } from "@/types/Info";

const route = useRoute();
const router = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const {
  enteredValues, isSseError, initError, initResult, token, serverTime, defaultLanguage,
  screenOrientation
} = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, error } = storeToRefs(terminalStore);
const { template, isGotovo, isEquick } = useMain();
const { config, apiUrl } = useApi();

const footerButtons = ref<FooterButton[]>([]);
const isLoading = ref(true);
const footerKey = ref(0);
const timer = ref(0);
const statusTimer = ref(0);
const isTimerShown = ref(false);
const isError = ref(false);
const isStatusError = ref(false);
const sse = ref<EventSource | undefined>(undefined);
const eventSource = ref<EventSource | undefined>(undefined);
const screenKey = ref(0);
const screenHeight = ref('');

const messagePopupComponent = computed(() => isEquick.value ? EquickMessagePopup : MessagePopup);

const setFooterButtons = (buttons: FooterButton[]) => {
  footerButtons.value = buttons;
  footerKey.value++;
};

const renewTimer = () => {
  if (info.value?.settings?.isTimer && !isSseError.value) {
    isTimerShown.value = false;
    clearTimeout(timer.value);

    timer.value = setTimeout(() => {
      if (
          (!isGotovo.value && route.path !== '/') ||
          (isGotovo.value && route.path !== '/queue-tree') ||
          Object.keys(enteredValues.value)?.length
      ) {
        isTimerShown.value = true;
      } else {
        renewTimer();
      }
    }, 1000 * (info.value?.settings?.timer?.delayOn ?? 60));
  }
};

const onReloadClick = () => {
  appStore.clearUserData();
  if (route.path === '/' || route.path === '/queue-tree') {
    window.location.reload();
  } else {
    router.push('/');
  }
};

const getInitialLanguage = (initial: string): string => {
  const languages = ['ua', 'en', 'ru'];
  if (languages.includes(initial)) {
    return initial;
  } else {
    const storedValue = localStorage.getItem('language');
    if (storedValue && languages.includes(storedValue)) {
      return storedValue;
    } else {
      return 'ua';
    }
  }
};

const setInitializeListener = () => {
  if (!sse.value || sse.value?.readyState === 2) {
    const sseOptions = {
      withCredentials: true,
      heartbeatTimeout: 60000
    };
    //sse.value = new EventSourcePolyfill(`http://localhost:3000/sse/init`, sseOptions);
    sse.value = new EventSourcePolyfill(`/sse/init`, sseOptions);

    sse.value?.addEventListener('message', async ({ data }) => {
      const parsedData = JSON.parse(data) as InitResult;
      console.log('sse/init', parsedData);

      if (parsedData?.data?.token) {
        token.value = parsedData.data.token;
        sse.value?.close();
        await setInitialData('setInitializeListener');
      }

      initResult.value = parsedData;

      if (parsedData?.error_message || parsedData?.error_name) {
        initError.value = (parsedData?.error_message ? parsedData.error_message : parsedData.error_name) ?? '';
        isLoading.value = false;
      }
    });

    sse.value?.addEventListener('error', event => {
      setTimeout(async () => {
        console.log('error', event);
        sse.value?.close();
        //setInitializeListener();
      }, 2000);
    });
  }
};

const setStatusListener = (emitter: string) => {
  if (!eventSource.value || eventSource.value?.readyState === 2) {
    console.log('setStatusListener from', emitter);
    let path = '';
    if (process.env.VUE_APP_SERVER_TYPE === 'local') {
      //path = 'http://localhost:3000/sse/status';
      path = '/sse/status';
    } else {
      path = `${ apiUrl.value }/terminal-queue-token/sse/status`;
    }

    //const path = `${ apiUrl.value }/terminal-queue-token/sse/status`
    eventSource.value = new EventSourcePolyfill(path, config.value as EventSourceInit);

    eventSource.value.onopen = async () => {
      if (isStatusError.value) {
        isStatusError.value = false;
      }
    };

    eventSource.value.onmessage = async (event: any) => {
      const parsedData: {
        timePeriods?: boolean;
        event?: {
          type?: string;
        };
        ping?: Date;
      } = JSON.parse(event.data);
      console.log('sse/status: ', parsedData);
      if (parsedData?.timePeriods) {
        await terminalStore.getTQueueTimePeriods();
      }

      if (parsedData?.event?.type === 'reload') {
        token.value = '';
        if (route.path === '/' || route.path === 'queue-tree') {
          window.location.reload();
        } else {
          window.location.href = isGotovo.value ? '/queue-tree' : '/';
        }
      }

      if (parsedData?.ping) {
        serverTime.value = new Date(parsedData.ping);
      }
    };

    eventSource.value.onerror = () => {
      clearTimeout(statusTimer.value);

      isStatusError.value = true;
      statusTimer.value = setTimeout(setStatusListener, 1000);
    };
  }
};

const setInitialData = async (emitter: string) => {
  await terminalStore.getTQueueInfo();
  if (!info.value?.settings || error.value) {
    isError.value = true;
  } else {
    await terminalStore.getTQueueOrganization();
    await terminalStore.getTerminalArticles();
    appStore.setLanguage(getInitialLanguage(info.value?.settings?.language) ?? defaultLanguage.value ?? 'ua', true);
    renewTimer();
    setStatusListener(emitter);
  }

  await redirect();

  isLoading.value = false;
};

const setTitle = () => {
  const title = document.querySelector('title');
  if (title) {
    title.innerHTML = i18n.t('title') ? i18n.t('title') : 'Terminal';
  }
};

const redirect = async () => {
  if (isGotovo.value && route.path === '/') {
    await router.push('queue-tree');
  } else if (isEquick.value && (route.path === '/' || !route.path.includes('equick'))) {
    await router.push('equick/queue-tree');
  }
};

const onScreenSizeChange = () => {
  if (window.matchMedia("(orientation: portrait)").matches) {
    screenOrientation.value = 'portrait';
  }

  if (window.matchMedia("(orientation: landscape)").matches) {
    screenOrientation.value = 'landscape';
  }

  screenHeight.value = `height: ${document.documentElement.clientHeight}px;`;
  screenKey.value++;
};

onMounted(async () => {
  console.log(123)
  isLoading.value = true;
  appStore.setLanguageData();
  onScreenSizeChange();
  setTitle();

  if (process.env.VUE_APP_SERVER_TYPE === 'local') {
    setInitializeListener();
  } else {
    initError.value = '';
    await setInitialData('mounted');
  }

  window.addEventListener('resize', onScreenSizeChange);
});

watch(i18n.locale, async () => {
  setTitle();
  await terminalStore.getTQueueInfo();
});

watch(() => route.path, redirect);
</script>

<style lang="scss">
@import "assets/styles/base/core";
@import "assets/styles/utils/animations";
@import "assets/styles/utils/sizes";

.page {
  @extend .flex-col;
  width: 100vw;
  height: 100vh;

  &.no-cursor {
    cursor: none;
  }
}

.main {
  animation: showByFading 0.25s 1 both;
}

@media (min-height: 1000px) and (min-width: 1400px) {
  .header:not(.hidden) + .main--m {
    $offset: calc(#{$gotovo-header} + 1vh);
    height: calc(100vh - #{$offset}) !important;
    max-height: calc(100vh - #{$offset}) !important;
  }
}
</style>
