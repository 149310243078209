import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "breadcrumbs" }
const _hoisted_2 = ["onClick"]

import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickBreadcrumbs',
  setup(__props) {

const { push } = useRouter();
const appStore = useAppStore();
const { path } = storeToRefs(appStore);

const onItemClick = (item: string) => {
  appStore.setLastPathItem(item);
  push(`/equick/${ item }`);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(path), (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item,
        class: "breadcrumbs__item",
        onClick: ($event: any) => (onItemClick(item))
      }, _toDisplayString(_ctx.$t(`equick.${ item }`)), 9, _hoisted_2))
    }), 128))
  ]))
}
}

})