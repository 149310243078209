<template>
  <popup-container class="message" teleport=".page" :message="$t('attention')" @close="onButtonClick">
    <div class="content">
      <p>{{ $t('queue.confirm_title') }}</p>
      <p class="text--phone">{{ getPhone(clientPhone) }}</p>
      <p>{{ $t('queue.confirm_phones') }}</p>
      <p v-for="phone in phoneCallCenterList" :key="phone" class="text--phone">{{ getPhone(phone) }}</p>
      <p>{{ $t('queue.confirm_time') }}</p>
      <p class="text--time">{{ waitTime }}</p>
    </div>
    <equick-base-button :text="$t('button.cancel')" @click="onButtonClick" />
  </popup-container>
</template>

<script lang="ts" setup>
import PopupContainer from '@/components/Popups/PopupContainer.vue';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";

const emits = defineEmits(['close', 'confirm']);

const terminalStore = useTerminalStore();
const { addResult, checkResult } = storeToRefs(terminalStore);

const waitTime = ref('--:--');
const checkTimer = ref(0);
const waitingTimer = ref(0);
const waitingTextInterval = ref(0);

const delay = computed((): number => addResult.value?.confirmData?.delay ?? 0);
const clientPhone = computed((): string => addResult.value?.confirmData?.phoneClient ?? '');
const phoneCallCenterList = computed((): string[] => addResult.value?.confirmData?.phoneCallCenterList ?? []);

const getPhone = (phone: string) => {
  const phoneParts = [
    `${ phone.slice(0, 4) } (${ phone.slice(4, 6) })`,
    phone.slice(6, 9),
    phone.slice(9, 11),
    phone.slice(11)
  ];
  return phoneParts.join(' ');
};

const getTimeText = (time: number) => {
  const parts = [`0${ Math.floor(time / 60) }`.slice(-2), `0${ time % 60 }`.slice(-2)];
  return parts.join(':');
};

const checkQueue = () => {
  checkTimer.value = setTimeout(async () => {
    await terminalStore.checkQueue();
    if (checkResult.value?.success) {
      emits('confirm', true);
      onButtonClick();
    } else {
      checkQueue();
    }
  }, 5000);
};

const setTextTimer = () => {
  let remainTime = delay.value;
  waitTime.value = getTimeText(remainTime);

  waitingTextInterval.value = setInterval(() => {
    remainTime--;
    waitTime.value = getTimeText(remainTime);

    if (remainTime <= 0) {
      clearInterval(waitingTextInterval.value);
    }
  }, 1000);
};

const setWaitTimer = () => {
  setTimeout(() => {
    emits('confirm', false);
    onButtonClick();
  }, delay.value * 1000);
};

const onButtonClick = () => {
  clearTimeout(waitingTimer.value);
  clearTimeout(checkTimer.value);
  clearInterval(waitingTextInterval.value);
  emits('close');
};

onMounted(() => {
  clearTimeout(checkTimer.value);
  checkQueue();
  setTextTimer();
  setWaitTimer();
});
</script>

<style lang="scss" scoped>
.content {
  @extend .col-container;
  width: max-content;

  p {
    max-width: 52vw;
    text-wrap: balance;
    @include font(24px, 120%, black, 400, center);

    &:not(.text--phone) + .text--phone {
      margin-top: 1.5rem;
    }

    &.text--phone + .text--phone {
      margin-top: 1rem;
    }

    &.text--phone + p {
      margin-top: 1.5rem;
    }

    &.text--time {
      margin: 1.5rem 0 2.25rem;
      @include font(48px, 120%, black);
    }

    &.text--phone {
      @include font(28px, 120%, black, 500);
    }
  }
}
</style>

<style lang="scss">
.message {

}
</style>
