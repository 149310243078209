import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "main__content" }
const _hoisted_2 = { class: "main__list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "main__list-item__text"
}
const _hoisted_5 = {
  key: 1,
  class: "main__list-item__text"
}
const _hoisted_6 = {
  key: 0,
  class: "main__list-empty"
}
const _hoisted_7 = { class: "footer__button-text" }
const _hoisted_8 = {
  key: 1,
  class: "main__pagination"
}
const _hoisted_9 = { class: "main__pagination-value" }

import { computed } from "vue";
import { TreeGroup } from "@/types/Tree";
import { Service } from "@/types/Service";
import { ServicePackage } from "@/types/ServicePackage";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickServiceList',
  props: {
  searchValue: {
    type: String,
    default: ''
  }
},
  emits: ['item-click', 'clear-search'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit

const appStore = useAppStore();
const { currentGroup, pageIndent } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { services, servicePackages } = storeToRefs(terminalStore);

const items = computed((): (Service | ServicePackage)[] => {
  return [...services.value, ...servicePackages.value];
});

const groups = computed((): TreeGroup[] => {
  const groups: TreeGroup[] = [];
  [...items.value]
      .map(item => {
        if (item?.group?.length > 0) {
          for (let i = 0; i < item.group.length; i++) {
            const index = groups.findIndex(group => group.name === item.group[i]);
            if (index >= 0) {
              groups[index].groups?.push(item.group[i]);
            } else {
              groups.push({
                type: 'group',
                name: item.group[i],
                parent: i === 0 ? '' : item.group[i - 1],
                services: [],
                groups: []
              });
            }

            if (i === item.group.length - 1) {
              groups[index >= 0 ? index : groups.length - 1].services?.push(item);
            }
          }
        } else {
          groups.push({ type: 'service', service: item });
        }
      });
  return groups;
});

const maxItems = computed((): number => 6);
const maxIndent = computed((): number => Math.ceil(filteredItems.value.length / maxItems.value));

const filteredItems = computed((): TreeGroup[] => {
  const filteredItems: TreeGroup[] = [];

  groups.value.map(group => {
    if (group.type === 'group' && (group.name === currentGroup.value || !currentGroup.value || group.parent === currentGroup.value)) {
      if (group.name !== currentGroup.value) {
        if (!group.parent || group.parent === currentGroup.value) {
          filteredItems.push(group);
        }
      } else {
        group.services?.map(service => filteredItems.push({ type: 'service', service }));
      }
    } else if (group.type === 'service' && !currentGroup.value) {
      filteredItems.push(group);
    }
  });

  return filteredItems.filter(item => {
    if (item.type === 'service') {
      return item?.service?.description?.name?.toLowerCase()?.includes(props.searchValue.toLowerCase());
    } else {
      return item?.name?.toLowerCase()?.includes(props.searchValue.toLowerCase());
    }
  });
});

const treeItems = computed((): TreeGroup[] => {
  return filteredItems.value.slice(pageIndent.value * maxItems.value, pageIndent.value * maxItems.value + maxItems.value);
});

const onItemClick = (item: TreeGroup) => {
  if (item.type === 'group') {
    currentGroup.value = item.name as string;
    emits('clear-search');
  } else {
    emits('item-click', item.service);
  }
};

const onNextClick = async () => {
  if (pageIndent.value < maxIndent.value - 1) {
    pageIndent.value = pageIndent.value + 1;
  } else {
    pageIndent.value = 0;
  }
};

const onPreviousClick = async () => {
  if (maxIndent.value >= 1) {
    if (pageIndent.value > 0) {
      pageIndent.value = pageIndent.value - 1;
    } else {
      pageIndent.value = maxIndent.value - 1;
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(treeItems.value, (item, itemKey) => {
        return (_openBlock(), _createElementBlock("div", {
          key: itemKey,
          class: "main__list-item",
          onClick: ($event: any) => (onItemClick(item))
        }, [
          (item.type === 'group')
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(item.name), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(item.service.description.name), 1))
        ], 8, _hoisted_3))
      }), 128)),
      (!treeItems.value.length && __props.searchValue.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('equick.not_found')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("footer", null, [
      (_unref(currentGroup))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "footer__button footer__button--back",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (currentGroup.value = ''))
          }, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('footer.button_back')), 1)
          ]))
        : _createCommentVNode("", true),
      (maxIndent.value > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", {
              class: _normalizeClass(["main__pagination-prev", {'disabled': _unref(pageIndent) === 0}]),
              onClick: onPreviousClick
            }, null, 2),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(pageIndent) + 1), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["main__pagination-next", {'disabled': _unref(pageIndent) === maxIndent.value - 1}]),
              onClick: onNextClick
            }, null, 2)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})