<template>
  <popup-container class="message" :message="message" :additional="additional" :has-close-button="hasButton"
                   :teleport="teleport" @close="onButtonClick">
    <default-button v-if="hasButton" :text="buttonText ?? $t('button.ok')" @click="onButtonClick" />
  </popup-container>
</template>

<script lang="ts" setup>
import PopupContainer from "@/components/Popups/PopupContainer.vue";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";

defineProps({
  message: String,
  additional: String,
  teleport: {
    type: String,
    default: null,
  },
  hasButton: {
    type: Boolean,
    default: true
  },
  buttonText: {
    type: String,
    default: null
  }
});
const emits = defineEmits(['close']);

const onButtonClick = () => emits('close');
</script>

<style lang="scss" scoped>
.message {
  :deep(.popup-area) {
    max-width: 50vw;
    min-width: 40vw;
    padding-top: 4rem;
  }
}

.gotovo .message {
  .default-button {
    min-width: 4rem;
    background-color: $yellow;

    :deep(.default-button__text) {
      color: #333 !important;
    }
  }
}
</style>
