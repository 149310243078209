<template>
  <main>
    <section class="main__top">
      <div class="main__top-left">
        <equick-logo place="main" class="main__top-left__logo" />
        <p class="main__top-left__text">{{ $t('equick.main_title') }}</p>
      </div>
      <div class="main__top-right">
        <equick-image />
      </div>
    </section>

    <template v-if="!isLoading">
      <equick-search-input :key="searchKey" @search="v => searchValue = v" />
      <equick-service-list v-show="!isKeyboardShown" :search-value="searchValue" @item-click="onItemClick"
                           @clear-search="searchValue = ''; searchKey++" />
    </template>

    <equick-loader v-if="isLoading" />
    <equick-message-popup v-if="serviceError" :message="serviceError" @close="serviceError = ''" />
  </main>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import { Service } from "@/types/Service";
import { ServicePackage } from "@/types/ServicePackage";
import EquickLogo from "@/components/Equick/EquickLogo.vue";
import EquickImage from "@/components/Equick/EquickImage.vue";
import EquickSearchInput from "@/components/Equick/Input/EquickSearchInput.vue";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import EquickServiceList from "@/components/Equick/EquickServiceList.vue";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";

const { push } = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { path, currentGroup, pageIndent, isKeyboardShown } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { results, error, info } = storeToRefs(terminalStore);

const isLoading = ref(true);
const serviceError = ref('');
const loadError = ref('');
const searchValue = ref('');
const searchKey = ref(0);

const onItemClick = async (item: Service | ServicePackage) => {
  isLoading.value = true;

  if (item.type === 'service') {
    await terminalStore.setTQueueService(item.id);
  } else {
    await terminalStore.setTQueueServicePackage(item.id);
  }

  isLoading.value = false;

  if (results.value[item.type === 'service' ? 'serviceId' : 'servicePackageId']) {
    await push('/equick/queue-period');
  } else {
    serviceError.value = i18n.t('message.queue_service_error', { name: item.description.name });
  }
};

onMounted(async () => {
  pageIndent.value = 0;
  currentGroup.value = '';
  path.value = ['queue-tree'];

  if (info.value?.settings?.isResource) {
    await terminalStore.getResources();
  } else {
    await terminalStore.getTQueueServices();
    await terminalStore.getTQueueServicePackages();
  }

  if (error.value) {
    loadError.value = error.value === 'timeout' ? i18n.t('message.load_error') : error.value;
  }

  isLoading.value = false;
});
</script>

<style scoped lang="scss">
main {
  @extend .flex-col;
  width: 100%;
  height: 100%;

  .main__top {
    @extend .flex-row;
    gap: $--16px;
    justify-content: space-between;

    &-left {
      @extend .flex-col;

      &__text {
        max-width: 24rem;
        margin-bottom: $--20px;
        @include font(28px, 120%, black, 500);
      }

      &__logo {
        margin: 20.5% 0 13%;
      }
    }

    &-right {
      width: 43.4vw;
    }
  }
}

@media (orientation: landscape) {
  main {
    margin-top: $--24px;

    .main__top-left__logo, .main__top-right {
      display: none;
    }
  }
}

@media (orientation: portrait) {
  $bg: "data:image/svg+xml,%3Csvg width='725' height='191' viewBox='0 0 725 191' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_1346_4805)'%3E%3Cpath d='M20 40.9101C20 29.5153 29.5059 20.4231 40.8894 20.9298L565.563 44.2845C568.163 44.4002 570.715 45.0221 573.076 46.1153L693.134 101.696C712.107 110.48 706.581 138.813 685.698 139.823L40.9656 170.987C29.554 171.538 20 162.435 20 151.01V40.9101Z' fill='url(%23paint0_linear_1346_4805)' fill-opacity='0.4'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_1346_4805' x='0' y='0.910156' width='724.752' height='190.1' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='10' result='effect1_foregroundBlur_1346_4805'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_1346_4805' x1='20' y1='96.2912' x2='677' y2='96.2912' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='%2318C9E4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A";

  main {
    height: 100%;
    margin-top: rems(10);

    .main__top {
      @include svgBg($bg, 90% auto);
      background-position-y: 24%;
    }
  }
}
</style>
