import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "languages"
}

import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Language } from "@/types/Info";
import { useRoute } from "vue-router";
import LanguageItem from "@/components/Languages/LanguageItem.vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguagesComponent',
  setup(__props) {

const i18n = useI18n();
const route = useRoute();
const appStore = useAppStore();
const { language, acceptLanguages } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

const isShown = ref(true);

const selected = computed((): string => language.value || i18n.locale.value);
const languages = computed((): Language[] => {
  return info.value.languages.filter(l => acceptLanguages.value.includes(l.language));
});

const onClick = (language: string) => {
  i18n.locale.value = language;
  appStore.setLanguage(language);
};

const setState = () => isShown.value = route.path === '' || route.path === '/';

onMounted(() => {
  setState();
  //i18n.locale.value = selected.value ?? 'ua';
});

watch(() => route.path, setState);


return (_ctx: any,_cache: any) => {
  return (isShown.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(languages.value, (language) => {
          return (_openBlock(), _createBlock(LanguageItem, {
            key: language,
            text: language.language,
            "is-current": language.language === selected.value,
            onClick: ($event: any) => (onClick(language.language))
          }, null, 8, ["text", "is-current", "onClick"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})