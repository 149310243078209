import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "queue__times" }

import QueueTime from "@/components/Queue/QueueTime.vue";
import { ref } from "vue";
import { useDateTime } from "@/composable/useDatetime";
import { useRouter } from "vue-router";
import MessagePopup from "@/components/Popups/MessagePopup.vue";
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueTimes',
  props: { items: Array },
  setup(__props) {



const router = useRouter();
const terminalStore = useTerminalStore();
const { timePeriods, results } = storeToRefs(terminalStore);
const { getHourMinute } = useDateTime();

const error = ref('');

const onClick = async (time: string) => {
  await terminalStore.setTQueueTimePeriod(time);
  if (results.value?.timePeriod) {
    await router.push('queue-form');
  } else {
    error.value = `Сталася помилка при виборі часу ${ getHourMinute(time) }`;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(timePeriods), (item, key) => {
        return (_openBlock(), _createBlock(QueueTime, {
          key: key,
          text: _unref(getHourMinute)(item.a),
          count: item.count,
          onClick: ($event: any) => (onClick(item.a))
        }, null, 8, ["text", "count", "onClick"]))
      }), 128))
    ]),
    (error.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 0,
          message: error.value,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = ''))
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})