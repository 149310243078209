import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MenuView from '../views/MenuView.vue';
import TreeView from '../views/TreeView.vue';
import ResultsView from '../views/ResultsView.vue';
import PageNotFoundView from '../views/PageNotFoundView.vue';
import { queueRoutes } from "@/router/queue.router";
import { equickRoutes } from "@/router/equick.router";

const mainRoutes: RouteRecordRaw[] = [{
    path: '/',
    name: 'MenuView',
    component: MenuView
}, {
    path: '/tree',
    name: 'TreeView',
    component: TreeView
}, {
    path: '/results',
    name: 'ResultsView',
    component: ResultsView
}, {
    path: '/:pathMatch(.*)*',
    redirect: '/'
    /*name: 'PageNotFoundView',
    component: PageNotFoundView*/
}];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [...mainRoutes, ...queueRoutes, ...equickRoutes]
});

export default router
