import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "loader__items" }
const _hoisted_3 = { class: "loader__text" }
const _hoisted_4 = {
  key: 1,
  src: "images/preloader.png",
  alt: "loader"
}

import { ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useMain } from "@/composable/useMain";


export default /*@__PURE__*/_defineComponent({
  __name: 'InnerLoader',
  props: {
  text: {
    type: String,
    default: null
  },
  isLoading: Boolean,
},
  setup(__props) {

const props = __props;

const i18n = useI18n();
const { isGotovo } = useMain();

const isShown = ref(false);
const stateTimeout = ref(0);
const shownTimeout = ref(0);
const state = ref('');
const loaderClass = ref('');
const height = ref('');

const loaderText = props.text ?? i18n.t('loading');

const setHeight = () => {
  let total = 0;
  ['help-line', 'header', 'footer'].map(id => {
    const el = document.getElementsByClassName(id)?.[0];
    if (el) {
      total += el.clientHeight;
    }
  });
  height.value = total > 0 ? `height: ${ document.documentElement.clientHeight - total }px;` : '';
}

watchEffect(() => {
  clearTimeout(stateTimeout.value);
  clearTimeout(shownTimeout.value);

  if (props.isLoading) {
    setHeight();
    const isFooterExists = !!document.getElementsByClassName('footer')?.length;
    loaderClass.value = `loader--${ isFooterExists ? 's' : 'm' }`;
    stateTimeout.value = setTimeout(() => {
      state.value = 'active';
      isShown.value = true;
    }, 100);
  } else {
    if (isShown.value) {
      state.value = 'hiding';
      shownTimeout.value = setTimeout(() => {
        isShown.value = false;
      }, 100);
    }
  }
})


return (_ctx: any,_cache: any) => {
  return (isShown.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["loader", [loaderClass.value, state.value]]),
        style: _normalizeStyle(height.value)
      }, [
        (!_unref(isGotovo))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (n) => {
                  return _createElementVNode("div", {
                    class: _normalizeClass(["loader__item", `loader__item--${n}`]),
                    key: n
                  }, null, 2)
                }), 64))
              ]),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(loaderText)), 1)
            ]))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
      ], 6))
    : _createCommentVNode("", true)
}
}

})