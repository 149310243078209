import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "input__arrow",
  width: "14",
  height: "25",
  viewBox: "0 0 14 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputArrow',
  props: { isEnabled: Boolean },
  setup(__props) {

const props = __props;

const color = computed((): string => props.isEnabled ? '#FFFFFF' : '#999999')

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M13.7072 11.814L1.70684 0.270033C1.30956 -0.0990598 0.676499 -0.0884628 0.292816 0.293707C-0.0814916 0.66652 -0.0814916 1.25753 0.292816 1.63029L11.5861 12.4942L0.292816 23.3581C-0.0976053 23.7337 -0.0976053 24.3427 0.292816 24.7183C0.683354 25.0939 1.31636 25.0939 1.70684 24.7183L13.7072 13.1743C14.0976 12.7986 14.0976 12.1897 13.7072 11.814Z",
      fill: color.value
    }, null, 8, _hoisted_2)
  ]))
}
}

})