import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "main__input" }
const _hoisted_2 = {
  key: 0,
  class: "main__search-input__placeholder"
}
const _hoisted_3 = { class: "main__search-value" }
const _hoisted_4 = ["onClick"]

import { computed, watch } from "vue";
import EquickKeyboard from "@/components/Equick/Keyboard/EquickKeyboard.vue";
import { useInput } from "@/composable/useInput";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickSearchInput',
  props: {
  modelValue: Boolean,
},
  emits: ['update:modelValue', 'search'],
  setup(__props, { emit: __emit }) {


const emits = __emit;

const {
  hintSet, currentValue, startMaskValue, hints, isValid, isKeyboardShown, lastKey, currentPosition,
  currentKeyboardType, prevType, hintsKey, currentField, handlers,

  onKeyClick, changeType, changeValue, deleteSymbol, clearValue, goForward, goBackward, getSplittedValue, setPosition,
  setHintValue, setFormattedValue, setCurrentPosition, setStartMaskValue, validate, onFieldClick,
} = useInput({
  initialValue: [],
  isKeyboardShownByDefault: false,
  keyboardDefaultType: 'ru',
  maxLength: 100,
  mask: ''
});

const textValue = computed((): string => currentValue.value.map(s => s.value).join(''));

const onSearchClick = () => {
  isKeyboardShown.value = false;
  emits('search', textValue.value);
};

watch(() => currentValue.value, () => emits('search', textValue.value), { deep: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["main__search", {'main__search--active': _unref(isKeyboardShown)}])
    }, [
      _createElementVNode("div", {
        class: "main__search-input",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isKeyboardShown.value = true))
      }, [
        (!_unref(currentValue).length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('equick.search_placeholder')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentValue), (item, key) => {
            return (_openBlock(), _createElementBlock("p", {
              key: key,
              class: _normalizeClass(["main__search-value__item", {'current': key === _unref(currentPosition)}]),
              onClick: ($event: any) => (currentPosition.value = key)
            }, _toDisplayString(item.value), 11, _hoisted_4))
          }), 128))
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["main__search-button clear", {'inactive': !_unref(currentValue).length}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(clearValue)(false)))
      }, null, 2),
      _createElementVNode("div", {
        class: "main__search-button search",
        onClick: onSearchClick
      }),
      _createElementVNode("div", {
        class: "main__search-button keyboard",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (isKeyboardShown.value = !_unref(isKeyboardShown)))
      })
    ], 2),
    _withDirectives((_openBlock(), _createBlock(EquickKeyboard, {
      key: _unref(currentKeyboardType),
      "keyboard-type": _unref(currentKeyboardType),
      onKeyClick: _unref(onKeyClick)
    }, null, 8, ["keyboard-type", "onKeyClick"])), [
      [_vShow, _unref(isKeyboardShown)]
    ])
  ]))
}
}

})