<template>
  <div class="queue__dates-item" :class="{'queue__dates-item--selected': isSelected}">
    <p class="queue__dates-item__text">{{ text }}, </p>
    <p v-if="day" class="queue__dates-item__day">{{ day }}</p>
    <p v-if="info?.settings?.isCountDate" class="badge">{{ count }}</p>
  </div>
</template>

<script lang="ts" setup>
import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";

defineProps({
  isSelected: Boolean,
  text: String,
  day: String,
  count: Number,
});

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
</script>

<style lang="scss" scoped>
.queue__dates-item {
  @extend .flex-row;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
  width: max-content;
  padding-right: 8px;
  padding-left: 4px;
  margin-bottom: 1rem;
  background-color: $background-gray;
  border-radius: 20px;
  //overflow: hidden;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &--selected {
    background-color: $dark-gray;

    & > .queue__dates-item__text {
      color: white;
    }

    & > .queue__dates-item__weekday {
      color: white;
    }

    & > .queue__dates-item__day {
      color: white;
    }
  }

  &__day {
    @extend .text-center;
    position: relative;
    left: 0;
    height: 100%;
    min-width: max-content;
    margin-left: 4px;
    line-height: 3.1rem;
    color: $dark-gray;
    text-transform: uppercase;
  }

  &__text {
    @extend .font-size-l, .text-center;
    line-height: 3.125rem;
    font-weight: 600;
  }
}

.badge {
  top: -6px;
  right: -0.75rem;
}
</style>
