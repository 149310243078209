<template>
  <main>
    <equick-breadcrumbs />
    <p class="results__title">{{ $t('equick.results_title') }}</p>
    <template v-if="!isLoading">
      <div class="results__table">
        <div class="results__table-list">
          <div v-for="item in resultItems" :key="item.key" class="results__table-item">
            <p class="results__table-item__label">{{ item.label }}</p>
            <p class="results__table-item__value">{{ item.value }}</p>
            <div v-if="item.handler" class="results__table-item__edit" @click="item.handler()"></div>
          </div>
        </div>
      </div>
      <equick-base-button :text="$t('button.add_queue')" @click="addQueue" />
    </template>
  </main>

  <footer v-if="!isLoading">
    <div class="footer__button footer__button--back" @click="onBackClick">
      <p class="footer__button-text">{{ $t('footer.button_back') }}</p>
    </div>
    <div class="footer__button footer__button--home" @click="onMainClick">
      <p class="footer__button-text">{{ $t('footer.button_main') }}</p>
    </div>
  </footer>

  <equick-loader v-if="isLoading" />
  <equick-message-popup v-if="result || isConfirmError || errorText" :message="message" :additional="additional"
                        @close="closePopup" />
  <equick-queue-confirm-popup v-if="isConfirmWaiting" @close="isConfirmWaiting = false" @confirm="confirmQueue" />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { EnteredValues, InputValue } from '@/types/Keyboard';
import { SelectedItem } from '@/types/Results';
import { useDateTime } from '@/composable/useDatetime';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickBreadcrumbs from "@/components/Equick/EquickBreadcrumbs.vue";
import EquickMessagePopup from "@/components/Equick/Popup/EquickMessagePopup.vue";
import EquickLoader from "@/components/Equick/EquickLoader.vue";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";
import EquickQueueConfirmPopup from "@/components/Equick/Popup/EquickQueueConfirmPopup.vue";

const { push } = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { enteredValues, isEditMode, currentStep, path } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, queue, error, fields, selected, addResult } = storeToRefs(terminalStore);
const { getDateMonth, getHourMinute } = useDateTime();

const isLoading = ref(true);
const isConfirmWaiting = ref(false);
const isConfirmError = ref(false);
const result = ref('');
const errorText = ref('');

const message = computed((): string => {
  return result.value === 'success' ? i18n.t('message.success') : i18n.t('message.fail');
});

const additional = computed((): string => {
  if (errorText.value) {
    return errorText.value;
  }
  if (isConfirmError.value) {
    return i18n.t('errors.time_exceeded');
  } else if (result.value === 'success' && addResult.value.ticket.num) {
    return i18n.t('result_ticket_num', { num: addResult.value.ticket.num });
  } else if (error.value && result.value === 'fail') {
    return `${ i18n.t('error') }: ${ i18n.t(error.value) }`;
  }
  return '';
});

const resultItems = computed(() => {
  const items: { label: string; key: string; value: string | number; handler?: () => void | null }[] = [];

  for (const [key, value] of Object.entries((queue.value?.timePeriod ? queue.value : selected.value) ?? {})) {
    let valueStr = value;

    if (['availableCurrent'].includes(key)) {
      continue;
    } else if (key === 'region' && items.find(item => item.key === 'regionId')) {
      continue;
    } else if (key === 'organization' && items.find(item => item.key === 'organizationId')) {
      continue;
    } else if (key === 'service' && items.find(item => item.key === 'serviceId')) {
      continue;
    }


    if (key === 'datePeriod') {
      valueStr = getDateMonth(value as string);
    } else if (key === 'timePeriod') {
      valueStr = getHourMinute(value as string);
    } else if (['regionId', 'organizationId', 'serviceId', 'servicePackageId'].includes(key)) {
      const selectedValue = (selected.value?.[key.slice(0, -2)] as SelectedItem)?.name;
      if (selectedValue) {
        valueStr = selectedValue;
      }
    } else if (key === 'current') {
      valueStr = i18n.t('queue.current');
    }

    let handler;
    if (key === 'datePeriod' || key === 'timePeriod') {
      handler = () => {
        push('queue-period');
      };
    }

    if (key === 'serviceId' || key === 'servicePackageId') {
      handler = () => {
        push('queue-tree');
      };
    }

    if (key === 'region' && (valueStr as { name?: string; })?.name) {
      valueStr = (valueStr as { name: string; }).name;
    }

    items.push({ label: i18n.t(`result.${ key }`), value: valueStr as string, key, handler });
  }

  if (fields.value?.length) {
    fields.value.map((f, i) => {
      const entered = enteredValues.value?.[f.dataFieldId];
      if (entered) {
        items.push({
          label: f.format,
          key: f.dataFieldId.toString(),
          value: entered.value.map(item => item.value).join(''),
          handler: () => {
            isEditMode.value = true;
            currentStep.value = i;
            push('queue-form');
          }
        });
      }
    });
  }

  return items;
});

const getValue = (type: string, value: InputValue[]): string => {
  if (type === 'ph') {
    const countryCode = info.value?.settings?.countryCode ?? '';
    const codeSymbols = `+ ${ countryCode } `;
    return countryCode + value.slice(codeSymbols.length).filter(item => /[0-9]/.test(item.value)).map(item => item.value).join('');
  } else {
    return value.map(item => item.value).join('');
  }
};

const addQueue = async () => {
  isLoading.value = true;

  const items: { dataFieldId: number; value: string; }[] = [];
  for (const [key, value] of Object.entries(enteredValues.value)) {
    if (value?.value) {
      items.push({ dataFieldId: +key, value: getValue(value.type, value.value) });
    }
  }

  await terminalStore.addQueue(items);
  if (addResult.value) {
    errorText.value = '';
    const { success, confirm } = addResult.value;
    if (success && !confirm) {
      result.value = addResult.value ? 'success' : 'fail';
    } else if (confirm) {
      isConfirmWaiting.value = true;
    } else {
      result.value = 'fail';
    }
  } else {
    errorText.value = error.value;
  }

  isLoading.value = false;
};

const closePopup = () => {
  if (result.value === 'success' || isConfirmError.value) {
    enteredValues.value = {} as EnteredValues;
    currentStep.value = 0;
    push('/');
  } else {
    error.value = '';
  }

  result.value = '';
  errorText.value = '';
};

const onBackClick = () => {
  const previousPath = path.value[path.value.length - 2];
  appStore.removeLastPathItem();
  push(previousPath);
};

const onMainClick = () => {
  currentStep.value = 0;
  path.value = [];
  push('/equick/queue-tree');
};

const confirmQueue = (isConfirmed: boolean) => {
  isConfirmWaiting.value = false;

  if (isConfirmed) {
    result.value = 'success';
  } else {
    isConfirmError.value = true;
  }
};

onMounted(async () => {
  path.value = ['queue-tree', 'queue-period', 'queue-form', 'queue-results'];

  await terminalStore.getTQueue();

  if (!fields.value?.length && Object.values(enteredValues.value)?.length) {
    await terminalStore.getTQueueForm();
  }

  isLoading.value = false;
});

</script>

<style lang="scss" scoped>
.results {
  &__title {
    margin: $--12px 0 $--32px;
    @include font(rems(28), 120%, black, 500);
  }

  &__table {
    @extend .flex-col, .shadow;
    width: 100%;
    max-height: 100%;
    padding: $--16px $--20px $--16px $--24px;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;

    &-list {
      @extend .flex-col;
      height: 100%;
      padding-right: 8px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: initial;
        width: 8px;
        background-color: #E3EFF1;
        border-radius: 50px;
      }
    }

    &-item {
      @extend .row-container;
      position: relative;
      min-height: rems(82);
      height: rems(82);

      &:not(:last-child) {
        border-bottom: 1.5px solid #C3E7EC;
      }

      &__label, &__value {
        padding-left: $--24px;
      }

      &__label {
        @extend .row-container;
        flex: 1;
        height: 100%;
        @include font(rems(18), 120%, #6E6E6E, 500);
        text-transform: uppercase;
        border-right: 1.5px solid #C3E7EC;
      }

      &__value {
        flex: 2;
        padding-right: rems(60);
        @include font($--24px, 120%, black);
      }

      &__edit {
        position: absolute;
        right: 0;
        width: rems(36);
        min-width: rems(36);
        height: rems(36);
        @include svgBg($equick-edit);
      }
    }
  }
}

main {
  @extend .flex-col;
  height: 100%;
}

.base-button {
  margin-top: $--24px;
}

@media (orientation: landscape) {
  main {
    max-height: calc(100% - 6.25rem - #{rems(76)});
  }
}

@media (orientation: portrait) {

}
</style>
