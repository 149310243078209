<template>
  <div>
    <inner-loader :is-loading="isLoading"/>
    <breadcrumbs-component/>
    <result-items :items="resultItems"/>
  </div>
</template>

<script lang="ts" setup>
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import ResultItems from "@/components/Results/ResultItems.vue";
import { onMounted, ref } from "vue";
import BreadcrumbsComponent from "@/components/Text/BreadcrumbsComponent.vue";

const emits = defineEmits(['set-footer-buttons']);

const resultItems = ref([]);
const isLoading = ref(true);

onMounted(() => {
  emits('set-footer-buttons', [{ type: 'back' }, { type: 'main' }, { type: 'next' }]);
  //this.addPathItem('Підтвердження даних');
  //this.breadcrumbs = this.path;
  /*if (this.question.forTest?.length) {
    this.resultItems = Object.keys(this.question.forTest[0].parameters).map((key, index) => {
      return {
        id: index,
        name: key,
        value: this.question.forTest[0].parameters[key]
      };
    })
  }*/
  isLoading.value = false;
});
</script>

<style lang="scss" scoped>
.main {
  justify-content: flex-start;
}
</style>