<template>
  <div class="reload-btn" @click="onClick"></div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useAppStore } from '@/store/app.store';

const router = useRouter();
const appStore = useAppStore();

const onClick = () => {
  appStore.clearUserData();
  window.location.reload();
  //router.push('/');
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/utils/sizes";

.reload-btn {
  @extend .pointer;
  width: 3.125rem;
  height: $header;
  @include svgBg($reload, 1.875rem);
}

@media (min-width: 2000px) {
  .reload-btn {
    width: 2.5vw;
    background-size: 2vh;
  }
}

.gotovo .reload-btn {
  height: 100%;
  width: 5rem;
  margin-left: 1rem;
  @include svgBg($arrow-reload, calc(min(3rem, 5vh)));
}
</style>
