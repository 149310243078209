import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "field__hints" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "field__hints-item__text" }

import { computed, PropType } from "vue";
import { InputValue } from "@/types/Keyboard";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickFieldHints',
  props: {
  items: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  value: {
    type: Array as PropType<InputValue[]>,
    default: () => []
  }
},
  emits: ['set-value'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const currentItems = computed((): string[] => {
  const str = props.value?.map(item => item.value)?.join('') ?? '';
  const startsWith = props.items.filter(item => item.startsWith(str));
  const includes = props.items.filter(item => item.includes(str));

  return [...new Set([...startsWith, ...includes])];
});

const onHintClick = (item: string) => emits('set-value', item);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentItems.value, (item, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: "field__hints-item",
        onClick: ($event: any) => (onHintClick(item))
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(item), 1)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}
}

})