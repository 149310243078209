import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "queue__live-block" }
const _hoisted_2 = {
  key: 0,
  class: "queue__live-title"
}
const _hoisted_3 = { class: "queue__live-block" }
const _hoisted_4 = { class: "queue__live-value" }
const _hoisted_5 = { class: "queue__live-value" }
const _hoisted_6 = { class: "queue__live-block" }
const _hoisted_7 = { class: "queue__live-message" }
const _hoisted_8 = { class: "queue__live-message" }
const _hoisted_9 = { class: "queue__live-message" }
const _hoisted_10 = {
  key: 0,
  class: "queue__live-block queue__live-block--no-near"
}
const _hoisted_11 = { class: "queue__live-message" }
const _hoisted_12 = { class: "queue__live-message" }
const _hoisted_13 = { class: "queue__live-container" }
const _hoisted_14 = { class: "queue__live-message" }
const _hoisted_15 = {
  key: 2,
  class: "queue__live-buttons"
}

import { computed, PropType, ref, watch } from 'vue';
import { NamedAvailableCurrent } from '@/types/AvailableCurrent';
import { useI18n } from 'vue-i18n';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import GotovoResultPopup from '@/components/Popups/GotovoResultPopup.vue';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'GotovoQueueLive',
  props: {
  selectedData: {
    type: Object as PropType<NamedAvailableCurrent>,
    default: () => ({})
  }
},
  emits: ['set-submit-state', 'show-fixed-queue'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const i18n = useI18n();
const terminalStore = useTerminalStore();
const { addResult, results } = storeToRefs(terminalStore);

const isSubmitScreen = ref(false);
const isErrorPopup = ref(false);
const isSuccessPopup = ref(false);
const isLoading = ref(false);

const isLiveAvailable = computed((): boolean => results.value?.availableCurrent?.quantity !== undefined && results.value.availableCurrent.quantity > 0);

const approximateTime = computed((): string => {
  const toWaitMs = props.selectedData?.approximateWaitingMs;
  if (toWaitMs !== undefined && toWaitMs >= 0) {
    const date = new Date(Date.now() + toWaitMs);
    return [
      date.toLocaleDateString('uk-UA', { day: '2-digit', month: 'long' }),
      date.getFullYear(),
      i18n.t('approximate_at'),
      date.toLocaleTimeString('uk-UA', { hour: 'numeric', minute: 'numeric' })
    ].join(' ');
  }

  return '';
});

const onAddClick = async () => {
  isLoading.value = true;
  await terminalStore.addLiveQueue();
  isSubmitScreen.value = true;
  isLoading.value = false;
};

const onSubmitClick = async () => {
  if (!addResult.value) {
    await terminalStore.addLiveQueue();
  }

  await terminalStore.addQueue({});

  if (addResult.value.success) {
    //await store.dispatch('getTQueueForm');
    isSuccessPopup.value = true;
  } else {
    isErrorPopup.value = true;
  }
};

const onFixedClick = () => emits('show-fixed-queue');

watch(() => isSubmitScreen.value, () => emits('set-submit-state', isSubmitScreen.value));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["queue__live", {'queue__live--submit': isSubmitScreen.value}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (isSubmitScreen.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t("confirmation")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(approximateTime.value), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(__props.selectedData.name), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("attention")) + " " + _toDisplayString(!isSubmitScreen.value ? _ctx.$t("queue.live_gotovo_1") : ""), 1),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("queue.live_gotovo_2")), 1),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("queue.live_gotovo_3")), 1)
      ]),
      (!isLiveAvailable.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("queue.no_near_1")), 1),
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("queue.no_near_2")), 1)
          ]))
        : _createCommentVNode("", true),
      (!isSubmitScreen.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", {
                class: _normalizeClass(["queue__live-button", {disabled: !isLiveAvailable.value}]),
                onClick: onAddClick
              }, _toDisplayString(_ctx.$t("button.submit_queue_live")), 3),
              _createElementVNode("p", {
                class: "queue__live-button queue__live-button--fixed",
                onClick: onFixedClick
              }, _toDisplayString(_ctx.$t("button.other_time")), 1)
            ]),
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("queue.live_gotovo_4")), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("p", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isSubmitScreen.value = false))
            }, _toDisplayString(_ctx.$t("button.cancel")), 1),
            _createElementVNode("p", { onClick: onSubmitClick }, _toDisplayString(_ctx.$t("button.submit_time")), 1)
          ]))
    ], 2),
    (isLoading.value)
      ? (_openBlock(), _createBlock(InnerLoader, {
          key: 0,
          "is-loading": isLoading.value
        }, null, 8, ["is-loading"]))
      : _createCommentVNode("", true),
    (isErrorPopup.value)
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 1,
          message: _ctx.$t('message.fail'),
          additional: _ctx.$t('message.queue_service_error', {name: __props.selectedData.name}),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (isErrorPopup.value = false))
        }, null, 8, ["message", "additional"]))
      : _createCommentVNode("", true),
    (isSuccessPopup.value)
      ? (_openBlock(), _createBlock(GotovoResultPopup, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})