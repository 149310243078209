<template>
  <div class="input__field" :class="`input__field--${isValid ? 'correct' : 'error'}`">
    <p v-if="!symbols?.length && currentField?.inputPlaceholder" class="input__field-placeholder">
      {{ currentField.inputPlaceholder }}
    </p>
    <div v-for="(item, key) in symbols" class="input__field-text" :key="key"
         :class="{'current': key === position}" @click="handlerClick(key)">
      <p class="input__field-text__symbol" :class="{'space': item.value === ' ', 'dashed': !item.isMaskSymbol}">
        {{ item.value }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, watchEffect } from "vue";
import { DataField } from "@/types/Form";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const props = defineProps({
  currentValue: Array,
  isValid: Boolean,
  currentPosition: {
    type: Number,
    default: 0
  },
});
const emits = defineEmits(['click']);

const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);

const currentField = computed((): DataField => fields.value[currentStep.value]);

const symbols = ref([] as any);
const position = ref(0);

const handlerClick = (index: number) => emits('click', index);

const setValues = () => {
  symbols.value = props.currentValue;
  position.value = props.currentPosition;
};

setValues();

watch([() => props.currentPosition, () => props.currentValue], setValues);

watchEffect(() => {
  const nextBtn = document.getElementById('next');
  if (nextBtn) {
    if (props.isValid) {
      nextBtn.classList.remove('disabled');
    } else {
      nextBtn.classList.add('disabled');
    }
  }
});
</script>

<style lang="scss" scoped>
.input__field {
  @extend .row-container;
  flex-wrap: wrap;
  height: 3.25rem;
  padding: 0 3.75rem 0 1rem;
  border-radius: 4px;

  &, &:focus, &:active {
    border: 1.5px solid $blue;
  }

  &-placeholder {
    @extend .upper;
    color: #CCC;
  }

  &-text {
    @extend .row-container;

    &.current {
      position: relative;

      & > .input__field-text__symbol.dashed {
        border-bottom: 2px solid $blue;
      }

      &:after {
        position: absolute;
        right: -1px;
        content: '';
        height: 1.875rem;
        margin-left: 2px;
        border-right: 1px solid $dark-gray;
        border-bottom: none;
        animation: cursorAnimation .4s infinite alternate;
      }
    }

    &__symbol {
      @extend .text-center, .font-size-xxl, .upper;
      min-width: 0.75rem;
      margin: 0 0.2rem;
      color: $dark-gray;
      border-bottom: 2px solid $border-gray;

      &:not(.dashed) {
        border-color: transparent;
      }

      &.space:after {
        content: 'A';
        max-width: 0.5rem;
        width: 0.5rem;
        color: transparent;
      }
    }
  }

  &--correct {
    border-color: $green;
  }

  &--error {
    border-color: $red;
  }
}

@keyframes cursorAnimation {
  0% {
    border-right: 1px solid $dark-gray;
  }
  50% {
    border-right: 1px solid $dark-gray;
  }
  100% {
    border-right: 1px solid transparent;
  }
}
</style>
