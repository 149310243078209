import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main__field" }
const _hoisted_2 = { class: "main__field-input" }
const _hoisted_3 = {
  key: 0,
  class: "main__field-input__placeholder"
}
const _hoisted_4 = { class: "main__field-value" }
const _hoisted_5 = ["onClick"]

import { PropType, watch } from "vue";
import EquickKeyboard from "@/components/Equick/Keyboard/EquickKeyboard.vue";
import { useInput } from "@/composable/useInput";
import { DataField } from "@/types/Form";
import { InputValue } from "@/types/Keyboard";
import EquickFieldHints from "@/components/Equick/Input/EquickFieldHints.vue";
import EquickFieldPermission from "@/components/Equick/Input/EquickFieldPermission.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickFieldInput',
  props: {
  modelValue: Boolean,
  field: {
    type: Object as PropType<DataField>,
    default: () => ({})
  },
  initial: {
    type: Array as PropType<InputValue[]>,
    default: () => []
  },
  mask: String,
  initialKeyboardType: String,
},
  emits: ['back', 'next', 'update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const {
  currentValue, hints, isValid, currentPosition, currentKeyboardType,
  onKeyClick, clearValue, setHintValue,
} = useInput({
  initialValue: props.initial,
  isKeyboardShownByDefault: true,
  keyboardDefaultType: props.initialKeyboardType ?? 'ru',
  maxLength: 1000,
  mask: props.mask ?? '',
});

watch(() => currentValue.value, () => emits('update:modelValue', currentValue.value), { deep: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["main__input", {'main__input--error': !_unref(isValid)}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_unref(currentValue).length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(__props.field.inputPlaceholder ? __props.field.inputPlaceholder : _ctx.$t('equick.input_placeholder')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentValue), (item, key) => {
            return (_openBlock(), _createElementBlock("p", {
              key: key,
              class: _normalizeClass(["main__field-value__item", {'current': key === _unref(currentPosition)}]),
              onClick: ($event: any) => (currentPosition.value = key)
            }, _toDisplayString(item.value), 11, _hoisted_5))
          }), 128))
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["main__field-button main__field-button--clear", {'inactive': !_unref(currentValue).length}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(clearValue)(false)))
      }, null, 2)
    ]),
    (__props.field?.type === 't')
      ? (_openBlock(), _createBlock(EquickFieldHints, {
          key: 0,
          class: "main__hints",
          items: _unref(hints),
          value: _unref(currentValue),
          onSetValue: _unref(setHintValue)
        }, null, 8, ["items", "value", "onSetValue"]))
      : _createCommentVNode("", true),
    (__props.field?.hint === 'firstName')
      ? (_openBlock(), _createBlock(EquickFieldPermission, { key: 1 }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(EquickKeyboard, {
      key: _unref(currentKeyboardType),
      "keyboard-type": _unref(currentKeyboardType),
      onKeyClick: _unref(onKeyClick)
    }, null, 8, ["keyboard-type", "onKeyClick"]))
  ], 2))
}
}

})