import { computed } from "vue";
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app.store';

export function useDateTime() {
  const terminalStore = useTerminalStore();
  const { info } = storeToRefs(terminalStore);
  const appStore = useAppStore();
  const { language } = storeToRefs(appStore);

  const locale = computed((): string => {
    const lang = info.value.languages.find(item => item.language === language.value);
    return lang ? lang.code : 'uk-UA';
  });
  const timeZone = computed((): string => info.value.settings.timeZone);

  const getTimeZoneDate = (date: string | Date, options = {}): string => {
    return new Date(date).toLocaleString(locale.value, {
      ...options,
      timeZone: timeZone.value
    });
  };

  const getDateStr = (dateStr: string | Date): string => {
    const date = new Date(dateStr);
    return getTimeZoneDate(date, { day: 'numeric', month: 'numeric', year: 'numeric' });
  };

  const getTimeStr = (dateStr: string | Date): string => {
    const date = new Date(dateStr);
    return getTimeZoneDate(date, { hour: 'numeric', minute: 'numeric' });
  };

  const getDateTimeStr = (dateStr: string | Date): string => {
    return `${ getDateStr(dateStr) } ${ getTimeStr(dateStr) }`;
  };

  const getFormattedDate = (value: number | string): string => `0${ value }`.slice(-2);

  const getDateMonth = (value: string) => getTimeZoneDate(value, { day: 'numeric', month: 'numeric' });

  const getHourMinute = (value: string) => getTimeZoneDate(value, { hour: 'numeric', minute: 'numeric' });

  const getWeekday = (value: string) => getTimeZoneDate(value, { weekday: 'short' });

  return { getDateStr, getTimeStr, getDateTimeStr, getFormattedDate, getDateMonth, getHourMinute, getWeekday };
}
