<template>
  <inner-loader :isLoading="isLoading" />
  <div v-show="!isLoading && !loadError" :class="$attrs.class">
    <breadcrumbs-component v-if="!isGotovo" />
    <div class="content">
      <title-text :text="$t('choose_service')" />
      <gotovo-search-input v-if="isGotovo && !currentGroup" @set-value="v => searchName = v"
                           @set-state="v => isSearchActive = v" />
      <div class="content__items" :class="{'content__items--search': isSearchActive}">
        <div v-if="!isGotovo && currentItems.length > maxItems" class="content__button previous"
             @click="onPreviousClick"></div>
        <div class="tree-items" :class="{'tree-items--search': isSearchActive}">
          <template v-for="group in treeItems" :key="group.name">
            <queue-tree-group v-if="group.type === 'group'" :name="group.name" :size="itemSize"
                              @click="onGroupClick(group.name as string)" />
            <queue-tree-item v-else :key="group.service?.id" :item-data="group.service" :size="itemSize"
                             @click="onItemClick(group.service as Service | ServicePackage)" />
          </template>
        </div>
        <div v-if="!isGotovo && currentItems.length > maxItems" class="content__button next" @click="onNextClick"></div>
      </div>
      <gotovo-paginator v-if="isGotovo && pages > 1" :pages="pages" @next="onNextClick" @previous="onPreviousClick" />
    </div>
  </div>
  <message-popup v-if="serviceError" :message="serviceError" @close="serviceError = ''" />
  <message-popup v-if="loadError" :message="loadError" @close="loadError = ''; push('/')" />
  <message-popup v-if="isAgreeError" :message="$t('attention')" :additional="$t('errors.disagree')"
                 @close="isAgreeError = false" />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import InnerLoader from '@/components/Loader/InnerLoader.vue';
import BreadcrumbsComponent from '@/components/Text/BreadcrumbsComponent.vue';
import TitleText from '@/components/Text/TitleText.vue';
import QueueTreeItem from '@/components/Tree/QueueTreeItem.vue';
import { Service } from '@/types/Service';
import { ServicePackage } from '@/types/ServicePackage';
import { useRouter } from 'vue-router';
import MessagePopup from '@/components/Popups/MessagePopup.vue';
import { useI18n } from 'vue-i18n';
import { useMain } from '@/composable/useMain';
import { TreeGroup } from '@/types/Tree';
import QueueTreeGroup from '@/components/Tree/QueueTreeGroup.vue';
import GotovoSearchInput from '@/components/Input/Gotovo/GotovoSearchInput.vue';
import GotovoPaginator from '@/components/Paginator/GotovoPaginator.vue';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import { info } from "sass";

const emits = defineEmits(['set-footer-buttons']);

const { push } = useRouter();
const i18n = useI18n();
const appStore = useAppStore();
const { currentGroup, isAgree, pageIndent } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { services, servicePackages, results, error, info } = storeToRefs(terminalStore);
const { isGotovo } = useMain();

const isLoading = ref(true);
const serviceError = ref('');
const loadError = ref('');
const isAgreeError = ref(false);
const searchName = ref('');
const isSearchActive = ref(false);

const items = computed((): (Service | ServicePackage)[] => {
  return [...services.value, ...servicePackages.value];
  //return [...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.services, ...store.getters.servicePackages];
});

const groups = computed((): TreeGroup[] => {
  const groups: TreeGroup[] = [];
  [...items.value]
      //.sort((a, b) => b.group?.length - a.group?.length)
      .map(item => {
        if (item?.group?.length > 0) {
          for (let i = 0; i < item.group.length; i++) {
            const index = groups.findIndex(group => group.name === item.group[i]);
            if (index >= 0) {
              groups[index].groups?.push(item.group[i]);
            } else {
              groups.push({
                type: 'group',
                name: item.group[i],
                parent: i === 0 ? '' : item.group[i - 1],
                services: [],
                groups: []
              });
            }

            if (i === item.group.length - 1) {
              groups[index >= 0 ? index : groups.length - 1].services?.push(item);
            }
          }
        } else {
          groups.push({ type: 'service', service: item });
        }
      });
  return groups;
});

const maxItems = computed((): number => {
  if (isGotovo.value) {
    return isSearchActive.value ? 2 : 6;
  } else {
    return 9;
  }
});
const maxIndent = computed((): number => Math.ceil(currentItems.value.length / maxItems.value));
const pages = computed((): number => Math.ceil(currentItems.value.length / maxItems.value));

const currentItems = computed((): TreeGroup[] => {
  const currentItems: TreeGroup[] = [];
  if (!isSearchActive.value) {
    groups.value.map(group => {
      if (group.type === 'group' && (group.name === currentGroup.value || !currentGroup.value || group.parent === currentGroup.value)) {
        if (group.name !== currentGroup.value) {
          if (!group.parent || group.parent === currentGroup.value) {
            currentItems.push(group);
          }
        } else {
          group.services?.map(service => currentItems.push({ type: 'service', service }));
        }
      } else if (group.type === 'service' && !currentGroup.value) {
        currentItems.push(group);
      }
    });

  } else {
    items.value
        .filter(item => item.description?.name?.toLowerCase()?.includes(searchName.value.toLowerCase()))
        .map(item => currentItems.push({ type: 'service', service: item }));
  }

  //currentItems.sort(item => item.type === 'group' ? -1 : 1);
  return currentItems;
});

const treeItems = computed((): TreeGroup[] => {
  return currentItems.value.slice(pageIndent.value * maxItems.value, pageIndent.value * maxItems.value + maxItems.value);
});

const itemSize = computed((): string => treeItems.value.length > 2 ? 's' : 'm');

const onGroupClick = (groupName: string) => currentGroup.value = groupName;

const onItemClick = async (item: Service | ServicePackage) => {
  if (isGotovo.value && !isAgree.value) {
    isAgreeError.value = true;
    return;
  }

  isLoading.value = true;

  if (item.type === 'service') {
    await terminalStore.setTQueueService(item.id);
  } else {
    await terminalStore.setTQueueServicePackage(item.id);
  }

  isLoading.value = false;

  if (results.value[item.type === 'service' ? 'serviceId' : 'servicePackageId']) {
    await push('/queue-period');
  } else {
    serviceError.value = i18n.t('message.queue_service_error', { name: item.description.name });
  }
};

const onNextClick = async () => {
  if (pageIndent.value < maxIndent.value - 1) {
    pageIndent.value = pageIndent.value + 1;
  } else {
    pageIndent.value = 0;
  }
};

const onPreviousClick = async () => {
  if (maxIndent.value >= 1) {
    if (pageIndent.value > 0) {
      pageIndent.value = pageIndent.value - 1;
    } else {
      pageIndent.value = maxIndent.value - 1;
    }
  }
};

onMounted(async () => {
  if (!isGotovo.value) {
    emits('set-footer-buttons', [
      { type: 'back', backLink: '/' },
      { type: 'main' }
    ]);
  } else {
    emits('set-footer-buttons', []);
  }

  pageIndent.value = 0;
  currentGroup.value = '';
  appStore.addPathItem('queue-tree');

  if (info.value?.settings?.isResource) {
    await terminalStore.getResources();
  } else {
    await terminalStore.getTQueueServices();
    await terminalStore.getTQueueServicePackages();
  }

  if (error.value) {
    loadError.value = error.value === 'timeout' ? i18n.t('message.load_error') : error.value;
  }

  const header = document.getElementById('gotovo-header');
  header && header.classList.remove('hidden');
  isLoading.value = false;
});

watch(() => currentGroup.value, () => {
  if (isGotovo.value) {
    if (currentGroup.value) {
      emits('set-footer-buttons', [
        {
          type: 'back', handler: async () => {
            pageIndent.value = 0;
            currentGroup.value = '';
          }
        },
        { type: 'main' }
      ]);
    } else {
      emits('set-footer-buttons', []);
    }
  } else {
    if (currentGroup.value) {
      emits('set-footer-buttons', [
        {
          type: 'back', handler: async () => {
            pageIndent.value = 0;
            currentGroup.value = '';
          }
        },
        { type: 'main' }
      ]);
    } else {
      emits('set-footer-buttons', [
        { type: 'back', backLink: '/' },
        { type: 'main' }
      ]);
    }
  }
});

watch(() => isSearchActive.value, async () => {
  const header = document.getElementById('gotovo-header');

  if (!isSearchActive.value) {
    searchName.value = '';
    pageIndent.value = 0;
    currentGroup.value = '';
    header && header.classList.remove('hidden');
  } else {
    header && header.classList.add('hidden');
  }
});
</script>

<style lang="scss" scoped>
.main > .content {
  justify-content: flex-start;
  align-items: center;
}

.content {
  &__items {
    @extend .flex-row;
    justify-content: center;
    height: 100%;
    width: calc(100vw - 4.5rem);
  }

  &__button {
    @extend .flex-row, .pointer;
    align-items: center;
    justify-content: center;
    min-width: 4rem;
    max-width: 4rem;
    height: calc(100% - 1rem);
    margin-block: auto;
    background-color: #ccc4 !important;
    border-radius: 4px;

    &.previous {
      margin-left: -1rem;
      margin-right: 1rem;
      @include svgBg($inputPrevious, 1.5rem);
    }

    &.next {
      margin-right: -1rem;
      margin-left: 1rem;
      @include svgBg($inputNext, 1.5rem);
    }

    &-previous, &-next {
      min-width: 3rem;
      height: 3rem;
    }

    &-previous {
      @include svgBg($inputPrevious);
    }

    &-next {
      @include svgBg($inputNext);
    }

    & + .tree-items {
      max-width: calc(100vw - 4rem * 2);
      margin: 0;

      :deep(.tree-item--s .tree-item__text) {
        font-size: min(115%, 1.375rem);
      }
    }
  }
}

.tree-items {
  @extend .flex-row;
  flex: 1;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: max-content;
  max-width: 100vw;
  margin: 0 1rem;
}

.gotovo {
  .header.hidden + .main--m > .content {
    padding-top: 3rem;

    .content__items + .content__pages {
      margin-top: auto;
    }
  }

  .tree-items {
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    max-width: calc(100vw);

    &--search {
      //height: calc(max(100% - 5rem, 10vh) + 2rem);
    }
  }

  .content {
    .title {
      margin-bottom: 1rem;
      font-size: 3.5vh;
    }

    &__items {
      margin-bottom: 5rem;

      & + .content__pages {
        margin-top: -5rem;
      }
    }
  }
}

@media (min-height: 1000px) and (min-width: 1400px) {
  .content {
    .title {
      font-size: 2rem;
    }

    &__items--search {
      height: max-content;
    }
  }
}
</style>
