import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useRouter } from "vue-router";
import { useAppStore } from '@/store/app.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReloadButton',
  setup(__props) {

const router = useRouter();
const appStore = useAppStore();

const onClick = () => {
  appStore.clearUserData();
  window.location.reload();
  //router.push('/');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "reload-btn",
    onClick: onClick
  }))
}
}

})