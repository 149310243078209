import { computed } from "vue";
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';

export const useMain = () => {
  const terminalStore = useTerminalStore();
  const { info } = storeToRefs(terminalStore);

  const template = computed((): string => info.value?.settings?.template ?? process.env.VUE_APP_TEMPLATE ?? '');
  const isGotovo = computed((): boolean => template.value === 'gotovo');
  const isEquick = computed((): boolean => template.value === 'equick');

  const isQueueOnly = computed((): boolean => {
    const items = info.value?.include?.filter(i => i.active) ?? [];
    return items.length === 1 && items?.[0]?.type === 'queue';
  });

  return {
    template, isGotovo, isEquick, isQueueOnly
  }
};
