import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { Articles, Info } from '@/types/Info';
import { Organization } from '@/types/Organization';
import { Service } from '@/types/Service';
import { ServicePackage } from '@/types/ServicePackage';
import { DatePeriod, TimePeriod } from '@/types/Period';
import { DataField } from '@/types/Form';
import { QueueAddResult, QueueCheckResult, Results, Selected } from '@/types/Results';
import { AvailableCurrent } from '@/types/AvailableCurrent';
import { JSONValue, useApi } from '@/composable/useApi';
import { PrintData } from '@/types/Print';

export const useTerminalStore = defineStore('terminalStore', () => {
  const { api, getErrorMessage } = useApi();

  const queue = ref<any>();
  const info = ref<Info>({} as Info);
  const organization = ref<Organization>({} as Organization);
  const serviceList = ref<Service[]>([]);
  const servicePackageList = ref<ServicePackage[]>([]);
  const datePeriods = ref<DatePeriod[]>([]);
  const timePeriods = ref<TimePeriod[]>([]);
  const fields = ref<DataField[]>([]);
  const addResult = ref<QueueAddResult>({} as QueueAddResult);
  const checkResult = ref<QueueCheckResult>({} as QueueCheckResult);
  const results = ref<Results>({} as Results);
  const selected = ref<Selected>({} as Selected);
  const availableCurrent = ref<AvailableCurrent>({} as AvailableCurrent);
  const articles = ref<Articles>({});
  const error = ref('');

  const services = computed((): Service[] => {
    return serviceList.value.filter(item => item.type = 'service');
  });

  const servicePackages = computed((): ServicePackage[] => {
    return servicePackageList.value.filter(item => item.type = 'servicePackage');
  });

  const getTQueue = async () => {
    try {
      queue.value = await api.get('');
      error.value = '';
    } catch (err) {
      queue.value = {};
      error.value = getErrorMessage(err);
    }
  };

  const getTQueueInfo = async () => {
    try {
      info.value = await api.get('/info');
      error.value = '';
    } catch (err) {
      info.value = {} as Info;
      error.value = getErrorMessage(err);
    }
  };

  const getTQueueOrganization = async () => {
    try {
      organization.value = await api.get('/organization');
      error.value = '';
    } catch (err) {
      organization.value = {} as Organization;
      error.value = getErrorMessage(err);
    }
  };

  const getResources = async () => {
    try {
      const res = await api.get('/resource');
      serviceList.value = res.service ?? [];
      servicePackageList.value = res.servicePackage ?? [];
      error.value = '';
    } catch (err) {
      serviceList.value = [];
      servicePackageList.value = [];
      error.value = getErrorMessage(err);
    }
  };

  const getTQueueServices = async () => {
    try {
      serviceList.value = await api.get('/service');
      error.value = '';
    } catch (err) {
      serviceList.value = [];
      error.value = getErrorMessage(err);
    }
  };

  const setTQueueService = async (serviceId: number) => {
    try {
      results.value = await api.get(`/service/${ serviceId }`);
      error.value = '';
    } catch (err) {
      results.value = {} as Results;
      error.value = getErrorMessage(err);
    }
  };

  const getTQueueServicePackages = async () => {
    try {
      servicePackageList.value = await api.get('/service-package');
      error.value = '';
    } catch (err) {
      servicePackageList.value = [];
      error.value = getErrorMessage(err);
    }
  };

  const setTQueueServicePackage = async (packageId: number) => {
    try {
      results.value = await api.get(`/service-package/${ packageId }`);
      error.value = '';
    } catch (err) {
      results.value = {} as Results;
      error.value = getErrorMessage(err);
    }
  };

  const getTQueueDatePeriods = async () => {
    try {
      const response = await api.get('/date-periods');
      datePeriods.value = response.datePeriods;
      error.value = '';
      if (response.availableCurrent) {
        availableCurrent.value = response.availableCurrent;
      }
    } catch (err) {
      datePeriods.value = [];
      error.value = getErrorMessage(err);
    }
  };

  const setTQueueDatePeriod = async (dateId: string) => {
    try {
      results.value = await api.get(`/date-period/${ dateId }`);
      error.value = '';
    } catch (err) {
      results.value = {} as Results;
      error.value = getErrorMessage(err);
    }
  };

  const getTQueueTimePeriods = async () => {
    try {
      const response = await api.get('/time-periods');
      timePeriods.value = response.timePeriods;
      error.value = '';
      if (response.availableCurrent) {
        availableCurrent.value = response.availableCurrent;
      }
    } catch (err) {
      timePeriods.value = [];
      error.value = getErrorMessage(err);
    }
  };

  const setTQueueTimePeriod = async (timeId: string) => {
    try {
      results.value = await api.get(`/time-period/${ timeId }`);
      error.value = '';
    } catch (err) {
      results.value = {} as Results;
      error.value = getErrorMessage(err);
    }
  };

  const getTQueueForm = async () => {
    try {
      const response = await api.get('/form');
      fields.value = response.customerDataFields;
      selected.value = response.selected;
      error.value = '';
    } catch (err) {
      fields.value = [];
      selected.value = {} as Selected;
      error.value = getErrorMessage(err);
    }
  };

  const addQueue = async (data: any) => {
    try {
      const response = await api.post('/add-queue', { data });
      addResult.value = response;
      error.value = response?.error ?? '';
    } catch (err) {
      addResult.value = {} as QueueAddResult;
      error.value = getErrorMessage(err);
    }
  };

  const addLiveQueue = async () => {
    try {
      addResult.value = await api.get('/current-queue');
      error.value = '';
    } catch (err) {
      addResult.value = {} as QueueAddResult;
      error.value = getErrorMessage(err);
    }
  };

  const checkQueue = async () => {
    try {
      const response = await api.get('/check-queue');
      checkResult.value = response;
      error.value = response?.error ?? '';
    } catch (err) {
      checkResult.value = {} as QueueCheckResult;
      error.value = getErrorMessage(err);
    }
  };

  const printDocument = async (data: PrintData) => {
    try {
      await api.post('/print', { data: data as JSONValue, isGlobalApi: false });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getTerminalArticles = async () => {
    try {
      articles.value = {};
      if (info.value.article?.offer) {
        articles.value[info.value.article.offer] = await api.get(`/article/${ info.value.article?.offer }`);
      }

      if (info.value.article?.tariff) {
        articles.value[info.value.article.tariff] = await api.get(`/article/${ info.value.article?.tariff }`);
      }
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  }

  return {
    queue, info, organization, services, servicePackages, datePeriods, timePeriods, fields, addResult, checkResult,
    results, selected, availableCurrent, error, articles,

    getTQueue, getTQueueInfo, getTQueueOrganization, getTQueueServices, setTQueueService, getTQueueServicePackages,
    setTQueueServicePackage, getTQueueDatePeriods, setTQueueDatePeriod, getTQueueTimePeriods, setTQueueTimePeriod,
    getTQueueForm, addQueue, addLiveQueue, checkQueue, printDocument, getTerminalArticles, getResources
  };
});
