<template>
  <div class="loader">
    <div class="loader__items">
      <div v-for="n in 8" class="loader__item" :class="`loader__item--${n}`" :key="n"></div>
    </div>
    <p class="loader__text">{{ loaderText }}</p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

const props = defineProps({
  text: {
    type: String,
    default: null
  }
});

const i18n = useI18n();
const loaderText = props.text ?? i18n.t('loading');
</script>

<style lang="scss" scoped>
@import '../../assets/styles/utils/animations';
@import '../../assets/styles/utils/sizes';

.loader {
  @extend .full-page-container, .flex-col;
  top: $help-line;
  justify-content: center;
  align-items: center;
  background-color: white;
  animation: showByFading .25s 1 both;

  &__items {
    @extend .row-container;
    position: relative;
    width: 10rem;
    height: 10rem;
  }

  &__item {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 1.25rem / 2);
    left: calc(50% - 1.25rem / 2);
    background-color: $blue;
  }

  &__text {
    @extend .bold, .font-size-default;
    width: 100%;
    color: $blue;
    text-align: center;
  }
}

@for $i from 1 through 8 {
  .loader__item--#{$i} {
    $deg: #{45 * ($i - 1)}deg;
    -webkit-transform: rotate($deg) translate(0, -2.35rem);
    -moz-transform: rotate($deg) translate(0, -2.35rem);
    transform: rotate($deg) translate(0, -2.35rem);
    opacity: 0;
    animation: rotate 1.1s linear #{calc(1.1 / 8) * $i}s infinite reverse;
  }
}

@keyframes rotate {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
</style>
