import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hints" }

import HintItem from "@/components/Hints/HintItem.vue";
import { onMounted, onUpdated, PropType, ref, watch } from "vue";
import { InputValue } from "@/types/Keyboard";


export default /*@__PURE__*/_defineComponent({
  __name: 'HintsComponent',
  props: {
  items: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  value: {
    type: Array as PropType<InputValue[]>,
    default: () => []
  }
},
  emits: ['set-value'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const currentItems = ref<string[]>(props.items);

const onClick = (item: string) => emits('set-value', item);

const setCurrentItems = () => {
  const str = props.value?.map(item => item.value)?.join('') ?? '';
  const startsWith = props.items.filter(item => item.startsWith(str));
  const includes = props.items.filter(item => item.includes(str));

  console.log(123)
  currentItems.value = [...new Set([...startsWith, ...includes])];
};

onMounted(() => setCurrentItems());

onUpdated(() => console.log('hints'));

watch(() => props.value, setCurrentItems, { deep: true })


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentItems.value, (item, key) => {
      return (_openBlock(), _createBlock(HintItem, {
        key: key,
        text: item,
        onClick: ($event: any) => (onClick(item))
      }, null, 8, ["text", "onClick"]))
    }), 128))
  ]))
}
}

})