import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "queue__times-item" }
const _hoisted_2 = { class: "queue__times-item__text" }
const _hoisted_3 = {
  key: 0,
  class: "badge"
}

import { useTerminalStore } from "@/store/terminal.store";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueTime',
  props: {
  text: String,
  count: Number
},
  setup(__props) {



const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.text), 1),
    (_unref(info)?.settings?.isCountTime)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(__props.count), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})