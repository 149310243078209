<template>
  <div class="help-line">
    <div class="help-line__icon"></div>
    <div class="help-line__operators">
      <div v-for="operator in operators" :key="operator.code" class="help-line__operator">
        <div class="help-line__operator-icon" :class="operator.icon"></div>
        <p class="help-line__operator-code">({{ operator.code }})</p>
      </div>
    </div>
    <div class="help-line__right">
      <p class="help-line__phone">{{ getFormattedPhone(phones[0]) }}</p>
      <p class="help-line__text">{{ $t('helpline') }}{{ phones?.length ? $t('helpline_2') : '' }}</p>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { InfoSetting } from '@/types/Info';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';

interface Operator {
  code: string;
  icon: string;
}

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);

const settings = computed((): InfoSetting => info.value?.settings);
const phones = computed((): string[] => settings.value?.telephone ?? []);

const operators = computed((): Operator[] => {
  const operators: Operator[] = [];
  phones.value.map(phone => {
    const code = phone.slice(0, 2);
    const operator = { code: `0${ code }`, icon: '' };
    if (['39', '67', '68', '96', '97', '98'].includes(code)) {
      operator.icon = 'kyivstar';
    } else if (['63', '73', '93'].includes(code)) {
      operator.icon = 'life';
    } else {
      operator.icon = 'vodafone';
    }
    operators.push(operator);
  });
  return operators;
});

const getFormattedPhone = (phone: string): string => {
  if (phone?.length > 8) {
    phone = phone.slice(2);
    return [
      phone.slice(0, 3),
      phone.slice(3, 5),
      phone.slice(5)
    ].join(' - ');
  } else {
    return '';
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/utils/sizes";

.help-line {
  @extend .flex-row;
  justify-content: center;
  align-items: center;
  height: $header;
  margin-left: 0.5rem;

  &__right {
    @extend .flex-col;
    width: max-content;
    max-width: max-content;
    margin: 0 4px;
  }

  &__icon {
    position: relative;
    top: 2px;
    height: 100%;
    width: 3.5rem;
    margin-right: 1rem;
    @include svgBg($support);
    background-position-y: 100%;
  }

  &__text {
    @extend .text-center;
    font-size: 14px;
    font-weight: 500;
    color: #EC2727;

    & span {
      margin: 0 1.25rem;
    }
  }

  &__operators {
    @extend .flex-col;
    margin-right: 0.5rem;
  }

  &__operator {
    @extend .flex-row;
    align-items: center;

    &:not(&:last-child) {
      margin-bottom: 4px;
    }

    &-icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.25rem;

      &.kyivstar {
        @include svgBg($kyivstar);
      }

      &.life {
        @include svgBg($life);
      }

      &.vodafone {
        @include svgBg($vodafone);
      }
    }

    &-code {
      color: $blue;
      font-weight: 700;
      font-size: 18px;
    }
  }

  &__phone {
    @extend .bold;
    color: $blue;
    font-size: 42px;
  }
}

@media (min-width: 2000px) {
  .help-line {
    &__icon {
      width: 2.5vw;
    }

    &__text {
      position: relative;
      top: -4px;
      font-size: 1vh;
    }

    &__operator-icon {
      width: 1vw;
      height: 1.25vh;
    }

    &__operator-code {
      font-size: 1.25vh;
    }

    &__phone {
      font-size: 3vh;
    }
  }
}
</style>
