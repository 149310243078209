<template>
  <div class="stepper">
    <div v-for="n in fields.length" :key="n" class="stepper__item"
         :class="{'stepper__item--active': n - 1 === currentStep, 'stepper__item--passed': n - 1 < currentStep}"></div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const appStore = useAppStore();
const { currentStep } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);
</script>

<style scoped lang="scss">
.stepper {
  @extend .flex-row;
  gap: $--16px;

  &__item {
    @extend .pointer;
    width: rems(30);
    height: rems(30);
    border-radius: 50%;

    &--passed {
      background-color: $equick-primary !important;
    }

    &:not(.stepper__item--passed):not(.stepper__item--active) {
      pointer-events: none;
    }
  }
}

@media (orientation: landscape) {
  .stepper {
    position: absolute;
    top: 0;
    right: 0;

    &__item {
      background-color: white;

      &--active {
        background: linear-gradient(90deg, #00C7E0 5%, #FFFFFF 76.67%);
      }
    }
  }
}

@media (orientation: portrait) {
  .stepper {
    &__item {
      background-color: #D9D9D9;

      &--active {
        background: linear-gradient(90deg, #00C7E0 5%, #D9D9D9 76.67%);
      }
    }
  }
}
</style>
