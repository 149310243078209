import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "text--phone" }
const _hoisted_3 = { class: "text--time" }

import PopupContainer from '@/components/Popups/PopupContainer.vue';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickQueueConfirmPopup',
  emits: ['close', 'confirm'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const terminalStore = useTerminalStore();
const { addResult, checkResult } = storeToRefs(terminalStore);

const waitTime = ref('--:--');
const checkTimer = ref(0);
const waitingTimer = ref(0);
const waitingTextInterval = ref(0);

const delay = computed((): number => addResult.value?.confirmData?.delay ?? 0);
const clientPhone = computed((): string => addResult.value?.confirmData?.phoneClient ?? '');
const phoneCallCenterList = computed((): string[] => addResult.value?.confirmData?.phoneCallCenterList ?? []);

const getPhone = (phone: string) => {
  const phoneParts = [
    `${ phone.slice(0, 4) } (${ phone.slice(4, 6) })`,
    phone.slice(6, 9),
    phone.slice(9, 11),
    phone.slice(11)
  ];
  return phoneParts.join(' ');
};

const getTimeText = (time: number) => {
  const parts = [`0${ Math.floor(time / 60) }`.slice(-2), `0${ time % 60 }`.slice(-2)];
  return parts.join(':');
};

const checkQueue = () => {
  checkTimer.value = setTimeout(async () => {
    await terminalStore.checkQueue();
    if (checkResult.value?.success) {
      emits('confirm', true);
      onButtonClick();
    } else {
      checkQueue();
    }
  }, 5000);
};

const setTextTimer = () => {
  let remainTime = delay.value;
  waitTime.value = getTimeText(remainTime);

  waitingTextInterval.value = setInterval(() => {
    remainTime--;
    waitTime.value = getTimeText(remainTime);

    if (remainTime <= 0) {
      clearInterval(waitingTextInterval.value);
    }
  }, 1000);
};

const setWaitTimer = () => {
  setTimeout(() => {
    emits('confirm', false);
    onButtonClick();
  }, delay.value * 1000);
};

const onButtonClick = () => {
  clearTimeout(waitingTimer.value);
  clearTimeout(checkTimer.value);
  clearInterval(waitingTextInterval.value);
  emits('close');
};

onMounted(() => {
  clearTimeout(checkTimer.value);
  checkQueue();
  setTextTimer();
  setWaitTimer();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PopupContainer, {
    class: "message",
    teleport: ".page",
    message: _ctx.$t('attention'),
    onClose: onButtonClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('queue.confirm_title')), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(getPhone(clientPhone.value)), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('queue.confirm_phones')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phoneCallCenterList.value, (phone) => {
          return (_openBlock(), _createElementBlock("p", {
            key: phone,
            class: "text--phone"
          }, _toDisplayString(getPhone(phone)), 1))
        }), 128)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('queue.confirm_time')), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(waitTime.value), 1)
      ]),
      _createVNode(EquickBaseButton, {
        text: _ctx.$t('button.cancel'),
        onClick: onButtonClick
      }, null, 8, ["text"])
    ]),
    _: 1
  }, 8, ["message"]))
}
}

})