export const en = {
  title: 'Terminal',
  account: 'Account',
  uah: '.-',
  helpline: 'We\'re always here',
  helpline_2: ', just call us',
  search: 'Search',
  loading: 'Loading data',
  timer_question: 'Are you still here?',
  duration: 'Duration',
  welcome_title: 'Welcome to self-service terminal!',
  welcome_subtitle: 'Please select the service you require',
  choose_service: 'Choose service',
  error: 'Error',
  result_ticket_num: 'Your ticket number is {num}',
  ticket_num: 'Ticket number',
  terminal: 'Terminal',
  address: 'Address',
  help_line_phone: 'Help line phone',
  approximate_at: 'approximate at',
  confirmation: 'Confirmation',
  attention: 'Attention!',
  choose_time: 'Choose time',
  free_time: 'Free time',
  days: ['Mon', 'Tue', ' Wed', 'Thu', 'Fri', ' Sat', 'Sun'],
  header: {
    info_button: 'Consent to the processing of personal data',
    rates_button: 'Info and rates',
    qr_text_1: 'Stability, efficiency, and transparency in every process!',
    qr_text_2: 'Learn more about our electronic queue service'
  },
  footer: {
    button_back: 'Back',
    button_main: 'Main',
    button_next: 'Next',
    button_continue: 'Continue',
  },
  permission: {
    part_1: 'I agree the collection, processing and using personal data provided by me.',
    part_2: 'I\'m familiar with the rights of the personal data subject.',
  },
  button: {
    ok: 'Ok',
    nearest: 'Nearest time',
    edit: 'Edit',
    add_queue: 'Submit',
    yes: 'Yes',
    reload: 'Reload',
    to_main: 'To main page',
    permission: 'Consent to the processing of personal data',
    submit_queue_live: 'Submit nearest time',
    cancel: 'Cancel',
    submit_time: 'Submit time',
    other_time: 'Choose other time',
    close: 'Close',
    print: 'Print ticket',
  },
  queue: {
    available_time: 'Available recording times on',
    no_available_time: 'No available recording times on',
    fixed: 'Fixed time',
    live_1: 'Live queue',
    live_2: '(today)',
    no_near_1: 'Unfortunately, there is no available place for today',
    no_near_2: 'Please choose from available',
    choose_period: 'Please select a queuing option',
    no_dates: 'No available dates',
    approximate_time: 'Time to wait',
    appearance_time: 'Tickets are expected in',
    in_queue: 'People in queue for selected service',
    current: 'Live',
    more_than: 'More than',
    live_gotovo_1: 'The time indicated is indicative!',
    live_gotovo_2: 'Do not leave the unit, you may be called earlier.',
    live_gotovo_3: 'Watch the queue on the board.',
    live_gotovo_4: 'If you are not satisfied with the proposed time, you can choose another time and date',
    ticket_amount: 'Today\'s tickets amount',
    free: 'Free',
    confirm_title: 'To complete queue registration, please call from the number',
    confirm_phones: 'to one of the numbers in the list:',
    confirm_time: 'Time for confirmation',
  },
  message: {
    success: 'Success',
    fail: 'Fail',
    queue_service_error: 'It is not possible to choose service \'{name}\'',
    queue_time_error: 'Couldn\'t register on a time {time}',
    load_error: 'Could not load data',
    server_error: 'Can not connect to server',
    info_error: 'Something went wrong... Try again later'
  },
  breadcrumb: {
    'queue-period': 'Period',
    'queue-form': 'Data input',
    'queue-results': 'Results',
    'queue-tree': 'Services',
  },
  result: {
    datePeriod: 'Date',
    organizationId: 'Organization',
    regionId: 'Region',
    region: 'Region',
    serviceId: 'Service',
    servicePackageId: 'Service package',
    timePeriod: 'Time',
    current: 'Queue type'
  },
  errors: {
    ERROR_LOGIN: 'Login error',
    ERROR_NO_SSE: 'No sse',
    ERROR_REGION_SELECT: 'Region is not selected',
    ERROR_NO_REGION: 'No regions',
    ERROR_ORGANIZATION_SELECT: 'Organization is not selected',
    ERROR_NO_ORGANIZATION: 'No organizations',
    ERROR_NO_SERVICE_PACKAGE: 'No service packages',
    ERROR_NO_SERVICE_PACKAGE_ITEM: 'No service package items',
    ERROR_SERVICE_PACKAGE_NOT_AVAILABLE: 'Service package is not available',
    ERROR_SERVICE_SELECT: 'Service is not selected',
    ERROR_NO_SERVICE: 'No services',
    ERROR_DATE_SELECT: 'Date is not selected',
    ERROR_TIME_RESERVED: 'Time is reserved',
    ERROR_TIME_SELECT: 'Time is not selected',
    ERROR_NO_CLIENT: 'No clients',
    ERROR_CLIENT_SELECT: 'Client is not selected',
    ERROR_NO_OPERATOR: 'No operators',
    ERROR_OPERATOR_SELECT: 'Operator is not selected',
    ERROR_NO_SCHEDULE: 'No schedules',
    ERROR_SCHEDULE_SELECT: 'Schedule is not selected',
    ERROR_NO_SIGNATURE: 'No signature',
    ERROR_SIGNATURE_SELECT: 'Signature is not selected',
    ERROR_REQUIRED: 'Please, fill all required values',
    ERROR_SHORT_TEXT: 'Error: SHORT_TEXT',
    ERROR_PHONE: 'Incorrect phone',
    ERROR_EMAIL: 'Incorrect email',
    ERROR_CHECK_LIMIT: 'Exceeded limit. Number of attempts exhausted',
    ERROR_CHECK_LIMIT_FIO:
      'The data you entered to receive the ticket in the electronic queue on this date is already used for another record. We recommend you to attempt to write to the electronic queue on another date.',
    ERROR_CHECK_LIMIT_FIO_ONLY: 'Unfortunately, the system cannot add such data to the electronic queue.' +
      '<br/>' +
      '<span class="smaller">We recommend that you check the surname specified during registration, because it must be entered in Ukrainian with a capital letter without any additional characters.</span>' +
      '<br/>' +
      '<span class="smaller">Please note that this surname, cannot be used to write to the electronic queue more than three times a week too.</span>',
    //ERROR_CHECK_LIMIT_FIO_ONLY: 'Please enter your last name only.',
    ERROR_ADD_RESERVATION: 'This time is reserved',
    disagree: 'You did not confirmed the agreement to collect, process and use personal data',
    datetime_choose: 'You did not choose a date or time',
    time_exceeded: 'Waiting time exceeded',
  },

  units: {
    hours: 'h',
    minutes: 'min',
    seconds: 'sec',
  },

  equick: {
    'queue-tree': 'Service Selection',
    'queue-period': 'Date and Time',
    'queue-form': 'Data',
    'queue-results': 'Confirmation',

    main_title: 'Select a service or use the search.',
    form_title: 'Fill in your details:',
    results_title: 'Data confirmation',
    input_placeholder: 'Enter data',
    search_placeholder: 'Enter service name',
    submit_button: 'I agree',
    not_found: 'Nothing found',
    queue_current_title: 'You can join the current queue,<br />or select a convenient date and time',
    queue_current_people: 'Currently in queue:',
    queue_current_time: 'Estimated waiting time:',
    queue_current_wait: 'Tickets are expected to appear in:',
    queue_current_register: 'Join the current queue',
    queue_current_choose_date: 'Go to date and time selection',
    queue_fixed_choose_date: 'Choose date:',
    queue_fixed_choose_time: 'Choose time:',
    queue_fixed_date_error: 'Failed to load available time for the selected date',
  }
};
