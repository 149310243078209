import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup__message" }
const _hoisted_2 = { class: "popup__timer-text" }

import PopupContainer from '@/components/Popups/PopupContainer.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useTerminalStore } from '@/store/terminal.store';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app.store';
import { EnteredValues } from '@/types/Keyboard';
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickPopupTimer',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const terminalStore = useTerminalStore();
const { info } = storeToRefs(terminalStore);
const appStore = useAppStore();
const { enteredValues, currentStep } = storeToRefs(appStore);
const router = useRouter();

const delayOff = computed((): number => info.value.settings.timer.delayOff ?? 90);

const currentValue = ref(delayOff.value);
const intervalId = ref(0);

const onButtonCLick = () => {
  clearInterval(intervalId.value);
  emits('close');
};

onMounted(() => {
  intervalId.value = setInterval(() => {
    if (currentValue.value === 0) {
      clearInterval(intervalId.value);
      emits('close');
      enteredValues.value = {} as EnteredValues;
      currentStep.value = 0;
      router.push('/');
    } else {
      currentValue.value--;
    }
  }, 1000);
});

onUnmounted(() => clearInterval(intervalId.value));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PopupContainer, {
    "has-close-button": false,
    teleport: ".page"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('timer_question')), 1),
      _createElementVNode("div", {
        class: "popup__timer",
        style: _normalizeStyle(`--outer_percent_deg: ${(currentValue.value) * 100 / delayOff.value}%`)
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(moment).utc(currentValue.value * 1000).format('mm:ss')), 1),
        (_openBlock(), _createElementBlock("svg", null, [
          _createElementVNode("circle", {
            class: _normalizeClass({'inactive': currentValue.value < 1}),
            r: "150",
            cx: "160",
            cy: "160",
            style: _normalizeStyle(`animation-duration: ${delayOff.value}s;`)
          }, null, 6)
        ]))
      ], 4),
      _createVNode(EquickBaseButton, {
        text: _ctx.$t('footer.button_continue'),
        onClick: onButtonCLick
      }, null, 8, ["text"])
    ]),
    _: 1
  }))
}
}

})